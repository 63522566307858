import React, { useEffect, useRef } from "react";

import "./local-filter-field.styles.scss"

const LocalFilterField = ({
    label,
    type,
    name,
    value,
    handleChange,

}) => (
    <input
        className="local-filter-field"
        name={name}
        type={type}
        placeholder={label}
        onChange={handleChange}
        value={value}
    />
);

export default LocalFilterField;
