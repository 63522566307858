import { modalActionTypes } from "./modal.types"

const INITIAL_STATE = {
    showAddSupplier: false,
    showEditSupplier: false,
    showAddSupplierGroup: false,
    showEditSupplierGroup: false,
    showAddItemGroup: false,
    showEditItemGroup: false,
    showAddItemTax: false,
    showEditItemTax: false,
    showAddUnitOfMeasure: false,
    showEditUnitOfMeasure: false,
    showAddItem: false,
    showEditItem: false,
    showAddCustomerGroup: false,
    showEditCustomerGroup: false,
    showAddLocation: false,
    showEditLocation: false,
    showAddCustomer: false,
    showEditCustomer: false,
    showStockEntry: false,
    modalType: null,
    modalExpandSection: {},
    formTemplate: {},
    formValues: {},
    isLoading: false,
    isSaved: false,
    isFail: false,
    errorMessage: null,
    formValidationErrors: null,
    windowHeight: null,
    editUUID: null,
    modalEdit: false,
    editData: null,
    isFetching: false,
    showModal: null,
    modalFieldValues: null,
    modalFieldValuesFilterd: null,
    modalTriggerName: null
}

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case modalActionTypes.SHOW_ADD_SUPPLIER_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddSupplier ? "ADD_SUPPLIER" : null,
                editUUID: null,
                showAddSupplier: !state.showAddSupplier
            }
        case modalActionTypes.SHOW_EDIT_SUPPLIER_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditSupplier ? "EDIT_SUPPLIER" : null,
                showEditSupplier: !state.showEditSupplier
            }
        case modalActionTypes.SHOW_ADD_SUPPLIER_GROUP_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddSupplierGroup ? "ADD_SUPPLIER_GROUP" : null,
                editUUID: null,
                showAddSupplierGroup: !state.showAddSupplierGroup
            }
        case modalActionTypes.SHOW_EDIT_SUPPLIER_GROUP_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditSupplierGroup ? "EDIT_SUPPLIER_GROUP" : null,
                showEditSupplierGroup: !state.showEditSupplierGroup
            }
        case modalActionTypes.SHOW_ADD_ITEM_GROUP_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddItemGroup ? "ADD_ITEM_GROUP" : null,
                editUUID: null,
                showAddItemGroup: !state.showAddItemGroup
            }
        case modalActionTypes.SHOW_EDIT_ITEM_GROUP_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditItemGroup ? "EDIT_ITEM_GROUP" : null,
                showEditItemGroup: !state.showEditItemGroup
            }
        case modalActionTypes.SHOW_ADD_ITEM_TAX_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddItemTax ? "ADD_ITEM_TAX" : null,
                editUUID: null,
                showAddItemTax: !state.showAddItemTax
            }
        case modalActionTypes.SHOW_EDIT_ITEM_TAX_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditItemTax ? "EDIT_ITEM_TAX" : null,
                showEditItemTax: !state.showEditItemTax
            }
        case modalActionTypes.SHOW_ADD_UNIT_OF_MEASURE_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddUnitOfMeasure ? "ADD_UNIT_OF_MEASURE" : null,
                editUUID: null,
                showAddUnitOfMeasure: !state.showAddUnitOfMeasure
            }
        case modalActionTypes.SHOW_EDIT_UNIT_OF_MEASURE_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditUnitOfMeasure ? "EDIT_UNIT_OF_MEASURE" : null,
                showEditUnitOfMeasure: !state.showEditUnitOfMeasure
            }
        case modalActionTypes.SHOW_ADD_ITEM_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddItem ? "ADD_ITEM" : null,
                editUUID: null,
                showAddItem: !state.showAddItem
            }
        case modalActionTypes.SHOW_EDIT_ITEM_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditItem ? "EDIT_ITEM" : null,
                showEditItem: !state.showEditItem
            }
        case modalActionTypes.SHOW_ADD_CUSTOMER_GROUP_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddCustomerGroup ? "ADD_CUSTOMER_GROUP" : null,
                editUUID: null,
                showAddCustomerGroup: !state.showAddCustomerGroup
            }
        case modalActionTypes.SHOW_EDIT_CUSTOMER_GROUP_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditCustomerGroup ? "EDIT_CUSTOMER_GROUP" : null,
                showEditCustomerGroup: !state.showEditCustomerGroup
            }
        case modalActionTypes.SHOW_ADD_LOCATION_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddLocation ? "ADD_LOCATION" : null,
                editUUID: null,
                showAddLocation: !state.showAddLocation
            }
        case modalActionTypes.SHOW_EDIT_LOCATION_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditLocation ? "EDIT_LOCATION" : null,
                showEditLocation: !state.showEditLocation
            }
        case modalActionTypes.SHOW_ADD_CUSTOMER_MODAL:
            return {
                ...state,
                formValues: {},
                modalExpandSection: {},
                modalType: !state.showAddCustomer ? "ADD_CUSTOMER" : null,
                editUUID: null,
                showAddCustomer: !state.showAddCustomer
            }
        case modalActionTypes.SHOW_EDIT_CUSTOMER_MODAL:
            return {
                ...state,
                modalEdit: !state.modalEdit,
                modalExpandSection: {},
                modalType: !state.showEditCustomer ? "EDIT_CUSTOMER" : null,
                showEditCustomer: !state.showEditCustomer
            }
        case modalActionTypes.SET_EDIT_UUID:
            return {
                ...state,
                editUUID: action.payload
            }
        case modalActionTypes.SET_EDIT_DATA:
            return {
                ...state,
                editData: action.payload
            }
        case modalActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            }
        case modalActionTypes.SET_MODAL_EXPAND_SECTION:
            const sectionTypeId = action.sectionTypeId;
            return {
                ...state,
                modalExpandSection: {
                    ...state.modalExpandSection,
                    [sectionTypeId]: !state.modalExpandSection[sectionTypeId]
                }
            }
        case modalActionTypes.SET_MODAL_TYPE:
            return {
                ...state,
                modalType: action.payload
            }
        case modalActionTypes.SET_MODAL_FORM_TEMPLATE:
            return {
                ...state,
                formTemplate: action.payload
            }
        case modalActionTypes.ADD_MORE_PART:
            return {
                ...state,
                formTemplate: {
                    ...state.formTemplate,
                    bodySections: {
                        ...state.formTemplate.bodySections,
                        [action.sectionTypeId]: {
                            ...state.formTemplate.bodySections[action.sectionTypeId],
                            fields: [
                                ...state.formTemplate.bodySections[action.sectionTypeId].fields,
                                action.payload
                            ]
                        }
                    }
                },
            }
        case modalActionTypes.REMOVE_PART:
            const fields = state.formTemplate.bodySections[action.sectionTypeId].fields;
            fields.pop();
            const something = state.formValues[action.sectionTypeId] ? delete state.formValues[action.sectionTypeId][action.part] : {};
            return {
                ...state,
                formTemplate: {
                    ...state.formTemplate,
                    bodySections: {
                        ...state.formTemplate.bodySections,
                        [action.sectionTypeId]: {
                            ...state.formTemplate.bodySections[action.sectionTypeId],
                            fields: [
                                ...fields
                            ]
                        }
                    }
                },
                formValues: {
                    ...state.formValues,
                    [action.sectionTypeId]: {
                        ...state.formValues[action.sectionTypeId],
                        [action.part]: {
                            ...(state.formValues[action.sectionTypeId] ? state.formValues[action.sectionTypeId][action.part] : {})
                        }
                    }
                }
            }
        case modalActionTypes.SUBMIT_MODAL_FORM_START:
            return {
                ...state,
                isLoading: true
            }
        case modalActionTypes.SUBMIT_MODAL_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            }
        case modalActionTypes.SUBMIT_MODAL_FORM_FAILURE:
            return {
                ...state,
                isFail: true,
                isLoading: false,
                errorMessage: action.payload
            }
        case modalActionTypes.SET_IS_FAIL:
            return {
                ...state,
                isFail: false,
                errorMessage: action.payload
            }
        case modalActionTypes.SET_MODAL_FORM_VALIDATION_ERROR:
            return {
                ...state,
                formValidationErrors: action.payload
            }
        case modalActionTypes.SET_WINDOW_HEIGHT:
            return {
                ...state,
                windowHeight: action.payload
            }
        case modalActionTypes.SET_MODAL_SHOW:
            return {
                ...state,
                showModal: action.payload
            }
        case modalActionTypes.SET_MODAL_FIELD_VALUES:
            return {
                ...state,
                modalFieldValues: action.payload
            }
        case modalActionTypes.SET_MODAL_FIELD_VALUES_FILTERD:
            return {
                ...state,
                modalFieldValuesFilterd: action.payload
            }
        case modalActionTypes.SET_MODAL_TRIGGER_NAME:
            return {
                ...state,
                modalTriggerName: action.payload
            }
        default:
            return state;
    }
}

export default modalReducer;