import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const CAPACITY_ESTIMATION_REPORT_BY_ORDER_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/capacityestimation",
  listPage: "/inventory-reports",
  pagePath:"items",
  hasChart: false,
  hasDateRange: true,
  tableHasGrandTotal: true,
  hasSearch: false
}

export const CAPACITY_ESTIMATION_REPORT_BY_ORDER_COLUMN_TEMPLATE = [
  {
    Header: "Raw Material",
    accessor: "rawMaterial"
  },
  {
    Header: "UoM",
    accessor: "unitOfMeasure",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Qty On Hand",
    accessor: "qty",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "January",
    accessor: "January",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "February",
    accessor: "February",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "March",
    accessor: "March",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "April",
    accessor: "April",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "May",
    accessor: "May",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "June",
    accessor: "June",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "July",
    accessor: "July",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "August",
    accessor: "August",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "September",
    accessor: "September",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "October",
    accessor: "October",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "November",
    accessor: "November",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
  {
    Header: "December",
    accessor: "December",
    Cell: ({ value }) => {
      const isObject = typeof value === 'object' && value !== null;

      if(!isObject){
        return displayCellValue(value);
      } else {
        return (
          <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
            <TableCellStatus
              value={value}
            />
          </div>
        )
      }
    }
  },
]
