import react from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { isLoggedInSelector } from '../../../redux/user/user.reselect';
import AuthService  from '../../../services/auth.services';

const PublicRoute  = ({component: WrappedComponent, isLoggedIn, ...otherProps}) => {
    const renderdRoute =  props => {
        return (<Route { ...otherProps } render = { routeProps => (
            isLoggedIn ? (
                <Redirect to={{
                    pathname: '/home'
                }} />
            ) : (
                <WrappedComponent {...routeProps} />
            )
        )} />)
    }
    return renderdRoute();
};

const mapStateToProps = createStructuredSelector({
    isLoggedIn : isLoggedInSelector
})

export default connect(mapStateToProps)(PublicRoute);