import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectShowAddCustomerModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_CUSTOMER_FORM_TEMPLATE from './add-customer.template.js';
import { setModalTemplate, setModalType, setShowAddCustomerModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddCustomer = ({
    showAddCustomer,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddCustomerModal
}) => {
    useEffect(() => {
        setModalType("ADD_CUSTOMER")
        setModalTemplate(ADD_CUSTOMER_FORM_TEMPLATE);
    }, []);
    
    return (
        <div className="add_customer">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddCustomer}
                        setShowModal={setShowAddCustomerModal}
                        size={"LARGE"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddCustomer: selectShowAddCustomerModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddCustomerModal: () =>
        disptach(setShowAddCustomerModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);