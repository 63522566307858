import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { setShowModal } from '../../redux/modal/modal.actions';
import { setBurgerHidden } from '../../redux/navigation/navigation.actions';

import './nav-dropdown.styles.scss';

const NavDropdown = ({
    dropDownItems,
    setShowModal,
    setBurgerHidden,
    sameOnMobileView,
    hasIcons,
    direction,
    gap
}) => {

    const history = useHistory()
    const handleDropdownClick = (event) => {
        const dropDownItemModal = event.target.dataset.modal;
        const dropDownItemPage = event.target.dataset.page;

        if (dropDownItemModal) {
            return setShowModal(dropDownItemModal);
        }

        history.push(dropDownItemPage)
        setBurgerHidden();
    }

    return (
        <ul className={`nav-dropdown 
        ${direction === "RIGHT" ? "nav-dropdown--right" : "nav-dropdown--left"}
        ${gap === "SHORT" ? "nav-dropdown--short" : null}
        
        `}>
            {
                dropDownItems.map(dropDownItem => {
                    const Icon = dropDownItem.icon && dropDownItem.icon;
                    return (
                        <li className={`nav-dropdown__item ${dropDownItem.breakpoint ? "nav-dropdown__item--breakpoint" : null}`} key={dropDownItem.id}>
                            <button className="nav-dropdown__item__btn"
                                onClick={handleDropdownClick}

                                data-modal={dropDownItem.modal ? dropDownItem.modalType : null}
                                data-page={dropDownItem.modal ? null : dropDownItem.page}
                            >
                                {dropDownItem.icon ?
                                    <Icon className="nav-dropdown__item__icon" /> :
                                    hasIcons ? <span className="nav-dropdown__item__icon--placeholder"> </span> : null
                                }

                                <span className="nav-dropdown__item__text">{dropDownItem.text}</span>
                            </button>
                        </li>
                    )
                })
            }
        </ul>
    )
};

const mapDispatchToProps = (dispatch) => ({
    setShowModal: (modalType) =>
        dispatch(setShowModal(modalType)),
    setBurgerHidden: () =>
        dispatch(setBurgerHidden()),
})

export default connect(null, mapDispatchToProps)(NavDropdown);