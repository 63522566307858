import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectExportData } from "../../redux/export/export.reselect";
import { printData, printDetail } from "../../utils/export-print-data-to-pdf.util";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';


import jsPDF from "jspdf";
import { useRef } from "react";
import { withRouter } from "react-router-dom";
import html2canvas from "html2canvas";
import { useReactToPrint } from 'react-to-print';
import { selectDetailData } from "../../redux/detail/detail.reselect";
import CustomButton from "../custom-button/custom-button.componenet";

const DetailPrintView = ({
    DropDownButton,
    dataPrint,
    contentRef,
    history
}) => {
    const cloneRef = useRef(true);
    const testRef = useRef(null)
    const margin = { top: 30, right: 30, bottom: 30, left: 30 }

    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });

    return (
        <>
            <CustomButton
                handleClick={handlePrint}
            >
                Print
            </CustomButton>
        </>
    )
}


const mapStateToProps = createStructuredSelector({
    dataPrint: selectDetailData,
});

export default withRouter(connect(mapStateToProps)(DetailPrintView))