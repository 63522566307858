import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectShowAddItemModal, selectShowAddItemTaxModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_ITEM_FORM_TEMPLAT from './add-item.template';
import { setModalTemplate, setModalType, setShowAddItemModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddItem = ({
    showAddItem,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddItemModal
}) => {
    useEffect(() => {
        setModalType("ADD_ITEM")
        setModalTemplate(ADD_ITEM_FORM_TEMPLAT);
    }, []);
    
    return (
        <div className="add_item">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddItem}
                        setShowModal={setShowAddItemModal}
                        size={"LARGE"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddItem: selectShowAddItemModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddItemModal: () =>
        disptach(setShowAddItemModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddItem);