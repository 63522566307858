import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import './modal.styles.scss';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSSTransition } from 'react-transition-group';
import { setIsFail, setModalFieldValues, setModalFromValidationError, setModalShow, setShowSupplierModal, submitModalForm } from '../../redux/modal/modal.actions';
import { selectEditUUID, selectIsSaved, selectModalEdit, selectModalFormTemplate, selectModalFormValue, selectModalShow, selectShowSupplierModal, selectWindowHeight } from '../../redux/modal/modal.reselect';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { useForm, FormProvider } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


import ModalBody from '../modal-body/modal-body.componenet';
import ModalHeader from '../modal-header/modal-header.componenet';
import ModalFooter from '../modal-footer/modal-footer.componenet';
import { useState } from 'react';
import useFetchEdit from '../../effects/use-fetch-edit.effect';
import { setRefresh } from '../../redux/refresh/refresh.actions';
import { fetchItem, fetchItems } from '../../redux/list/list.actions';
import { useRef } from 'react';


const Modal = ({
    showModal,
    setModalShow,
    submitModalForm,
    formTemplate,
    setModalFromValidationError,
    isSaved,
    setIsFail,
    history,
    windowHeight,
    endpointUrl,
    modalEdit,
    authToken,
    isLoading,
    setRefresh,
    fetchItem,
    editUUID,
    size,
    setModalFieldValues,
    modalShow
}) => {

    const { modalTitle, path, bodySections } = formTemplate;
    const methods = useForm({
        shouldUnregister: true
    });

    const saveData = async (formValues) => {
        try {
            setModalFromValidationError(null);
            const response = await submitModalForm(
                formValues,
                authToken,
                endpointUrl ? endpointUrl : path,
                modalEdit
            );

            if (response.status == 200 || response.status == 201) {
                console.log("Show form values:", formValues)
                const sectionName = Object.keys(formValues)[0];
                setModalFieldValues({ ...formValues[sectionName][0], uuid: response.data.uuid })
                setModalFromValidationError(null);
                setIsFail(null);
            }
        } catch (error) {
            console.log(error);
            throw new Error();
        }
    }

    const handleSubmitModalFormSave = async (formValues) => {
        try {
            await saveData(formValues);
            methods.reset({});
            setModalShow(null)
        } catch (error) {
            console.log("netowrk error has occured:")
            console.log(error);
        }
    }

    const handleSubmitModalFormError = async (errors) => {
        setModalFromValidationError(errors);
    }

    const handleCloseModal = (event) => {
        event.preventDefault();
        methods.reset({});
        setModalFromValidationError(null);
        setIsFail(null);
        setModalShow(null)
    }

    const [localShowModal, setLocalShowModal] = useState(false)

    let focusFieldName = null;
    if (bodySections.basic) {
        focusFieldName = bodySections.basic.fieldName + '.' + 0 + '.' + bodySections.basic.fieldItems[0].name;
    }

    useEffect(() => {
        setLocalShowModal(showModal)
    }, [showModal])

    useEffect(() => {
        const modalDialog = document.querySelector(".general-modal__dialog");

        if (modalDialog && windowHeight < 992) {
            modalDialog.style.maxHeight = windowHeight + 'px';
        }
    });

    return (
        ReactDOM.createPortal(
            <>
                <CSSTransition
                    in={true}
                    timeout={300}
                    classNames="general-modal"
                    unmountOnExit
                >
                    <div className="general-modal">
                        <div className={`general-modal__dialog 
                            ${size === "SMALL" ?
                                "general-modal__dialog--small" : size === "MEDIUM" ?
                                    "general-modal__dialog--medium" : "general-modal__dialog--large"}`
                        }>
                            <FormProvider {...methods}>
                                <ModalHeader
                                    modalTitle={modalTitle}
                                    handleCloseModal={handleCloseModal}
                                />
                                <form >
                                    <ModalBody
                                        focusFieldName={focusFieldName}
                                        bodySections={bodySections}
                                        isLoading={isLoading}
                                    />
                                    {
                                        modalEdit ?
                                            <ModalFooter
                                                handleCloseModal={handleCloseModal}
                                                handleSave={methods.handleSubmit(handleSubmitModalFormSave, handleSubmitModalFormError)}
                                                handleSaveandNew={false}
                                            />
                                            :
                                            <ModalFooter
                                                handleCloseModal={handleCloseModal}
                                                handleSave={methods.handleSubmit(handleSubmitModalFormSave, handleSubmitModalFormError)}
                                                handleSaveandNew={false}
                                            />
                                    }

                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </CSSTransition>
                <ToastContainer
                    hideProgressBar={true}
                />
            </>
            , document.getElementById('app-modal')
        )
    )
}

const mapStateToProps = createStructuredSelector({
    formTemplate: selectModalFormTemplate,
    isSaved: selectIsSaved,
    windowHeight: selectWindowHeight,
    authToken: authTokenSelector,
    modalEdit: selectModalEdit,
    editUUID: selectEditUUID,
    modalShow: selectModalShow
});

const mapDispatchToProps = dispatch => ({
    // setShowSupplierModal: () =>
    //     dispatch(setShowSupplierModal()),
    submitModalForm: (formValues, authToken, path, modalEdit) =>
        dispatch(submitModalForm(formValues, authToken, path, modalEdit)),
    setModalFromValidationError: (errors) =>
        dispatch(setModalFromValidationError(errors)),
    setIsFail: (error) =>
        dispatch(setIsFail(error)),
    setRefresh: (data) =>
        dispatch(setRefresh(data)),
    fetchItem: (authToken, url) =>
        dispatch(fetchItem(authToken, url)),
    setModalShow: (data) =>
        dispatch(setModalShow(data)),
    setModalFieldValues: (data) =>
        dispatch(setModalFieldValues(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal));