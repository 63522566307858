import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import lodash from 'lodash';
import { selectFormProgress, selectFormTableLatestRows, selectFormTotalValues } from "../../redux/form/form.reselect";

import "./form-table-total.styles.scss"

const FormTableTotal = ({
    formTotalValues,
    isCurrency,
    isInverted
}) => {
    return (
        <div className={`form-table-total ${isInverted && "form-table-total--inverted"}`}>
            {
                formTotalValues && Object.keys(formTotalValues).length && Object.keys(formTotalValues).map((key) => (
                    <div className="form-table-total__single">
                        <div className="form-table-total__single__title">
                            {lodash.startCase(key)} 
                        </div>
                        <div className="form-table-total__single__content">
                            {formTotalValues[key]} {isCurrency && " ETB"}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTotalValues: selectFormTotalValues,
});

export default connect(mapStateToProps)(FormTableTotal);