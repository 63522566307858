import React, { useState } from "react";
import ReactDOMServer from 'react-dom/server';

import "./detail-body-print.styles.scss";

import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';

import { authTokenSelector } from "../../redux/user/user.reselect";
import useFetchDetail from "../../effects/use-fetch-detail";
import { selectUUID } from "../../redux/detail/detail.reselect";

import DetailBodySection from "../detail-body-section/detail-body-section.componenet";
import Spinner from "../spinner/spinner.component";
import ErrorBox from "../error-box/error-box.component";
import { selectIsRefresh } from "../../redux/refresh/refresh.reselect";
import { useEffect } from "react";
import { setRefresh } from "../../redux/refresh/refresh.actions";


import { Provider } from 'react-redux';

import { store } from '../../redux/store';
import { useRef } from "react";
import { useLayoutEffect } from "react";

const DetailBodyPrint = ({
    authTokenFromState,
    endpoint,
    uuid,
    setRefresh,
    isRefresh,
    contentRef,
    title
}) => {
    const [a4Height, setA4Height] = useState(null);
    const endpointUrl = endpoint + '/' + uuid;
    const { data, error, isLoading } = useFetchDetail(
        endpointUrl,
        authTokenFromState,
        uuid,
        isRefresh
    );

    const getPageMargins = () => {
        return `@page { margin: ${'40px'} ${'10px'} ${'40px'} ${'10px'} !important; }`;
    };

    useEffect(() => {
        if (isRefresh) {
            setRefresh(false);
        }
       
    }, [isRefresh])

    useEffect(() => {
        const width = contentRef.current.clientWidth;
        const newHeight = ((width * 842)/595); 
        contentRef.current.style.height = newHeight + "px";
    },[])

    let contentData = null 
    return (
        <div className="detail-body-print container" >
            <div className="detail-body-print__a4" ref={contentRef}>
                {isLoading ?
                    <div className="detail-body-print__spinner">
                        <Spinner />
                    </div>
                    :
                    error ?
                        <div className="detail-body-print__error">
                            <ErrorBox
                                error={error}
                                icon={error.status ?
                                    error.status === 404 ? false :
                                        true :
                                    true
                                }
                            />
                        </div>
                        :
                        <>
                            <div className="detail-body-print__print-header">
                                <div className="detail-body-print__print-header__title">
                                    {title}
                                </div>
                            </div>
                            <style>{getPageMargins()}</style>
                            {contentData =
                                data.map((sectionData, index) => {
                                    return (
                                        <DetailBodySection
                                            key={index}
                                            sectionData={sectionData}
                                            forPrint={true}
                                            a4Height={a4Height}
                                        />
                                    )
                                })}
                        </>
                }
            </div>

        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    uuid: selectUUID,
    isRefresh: selectIsRefresh,
});

const mapDispatchToProps = (dispatch) => ({
    setRefresh: (data) =>
        dispatch(setRefresh(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailBodyPrint)