import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectFormProgress, selectFormTableLatestRows } from "../../redux/form/form.reselect";
import { roundNumberTwoDecimal } from "../../utils/format-number.util";
import "./form-progress.styles.scss"

const FormProgress = ({
    formProgress
}) => {
    return (
        <div className="form-progress">
            Progress: <span className="form-progress__value">{formProgress}%</span>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formProgress: selectFormProgress
});

export default connect(mapStateToProps)(FormProgress);