import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const PURCHASE_REPORT_OPERATING_EXPENSE_INFO = {
  reportTitle: "Purchasing Reports",
  path: "/purchasereports",
  subPath: "/operatingexpense",
  listPage: "/purchase-reports",
  pagePath:"operating-expenses",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasExpenseTypeFilter: true,
  hasSearch: true
}

export const PURCHASE_REPORT_OPERATING_EXPENSE_COLUMN_TEMPLATE = [
  {
    Header: "Expense Number",
    accessor: "expenseNumber",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Expense Type",
    accessor: "expenseType",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Date",
    accessor: "expenseDate",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Total Expense (ETB)",
    accessor: "totalExpense",
    Cell: ({ value }) => {
        const isMobileView = useMediaQuery({ maxWidth: 991 });
        const isRightAligned = true;
        
        const newValue = { value: 1, label: isMobileView ? `${value ? 
                displayCellValueNumberToTwoDecimal(value) : 0} ETB spent` :  `${value ? displayCellValueNumberToTwoDecimal(value) : 0}`} 
        

        return (
          <div className={isMobileView && isRightAligned ?
            'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
            <TableCellStatus
              value={newValue}
              noDot={true}
            />
          </div>
        )
    },
    Footer: 'totalExpense'
}
]
