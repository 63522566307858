import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const SALES_REPORT_BY_MARKETING_DATA_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/marketingdata",
  listPage: "/sales-reports",
  pagePath:"marketing-data",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasLocationFilter: false,
  hasProductOwnerFilter: true,
  hasSearch: true
}

export const SALES_REPORT_BY_MARKETING_DATA_COLUMN_TEMPLATE = [
  {
    Header: "Customer",
    accessor: "customer",
    Cell: ({ value }) => {
      return displayCellValue(value);
   },
   Footer: 'Average'
  },
  {
    Header: "MD Code",
    accessor: "mdCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
   },
  },
  {
    Header: "Date",
    accessor: "collectDate",
    Cell: ({ value }) => {
        const isMobileView = useMediaQuery({ maxWidth: 991 });

        if (isMobileView) {
            return null;
        }

        return displayCellValue(value);
    }
 },
 {
  Header: "Last Visit",
  accessor: "lastVisit",
  Cell: ({ value }) => {
    const isMobileView = useMediaQuery({ maxWidth: 991 });

    if (isMobileView) {
        return null;
    }

    return displayCellValue(value);
  }
 },
 {
  Header: "Visit Status",
  accessor: "visitStatus",
  Cell: ({ value }) => {
    const isMobileView = useMediaQuery({ maxWidth: 991 });

    if (isMobileView) {
        return null;
    }

    return displayCellValue(value);
  }
 },
 {
  Header: "Activity",
  accessor: "activity",
  Cell: ({ value }) => {
    const isMobileView = useMediaQuery({ maxWidth: 991 });

    if (isMobileView) {
        return null;
    }

    return displayCellValue(value);
  }
 },
 {
    Header: "Provider",
    accessor: "provider",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    }
  },
  {
    Header: "Product",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Product Owner",
    accessor: "productOwner",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Territory",
    accessor: "territory",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Territory: ${displayCellValue(value)}`;
      }

      return displayCellValue(value);
    }
  },
  {
    Header: "Route",
    accessor: "route",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    }
  },
  {
    Header: "Buying Price (ETB)",
    accessor: "buyingPrice",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    },
     Footer: 'buyingPrice'
  },
  {
    Header: "Selling Price (ETB)",
    accessor: "sellingPrice",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    },
    Footer: 'sellingPrice'
  },
  {
    Header: "Availability",
    accessor: "availability",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
          return displayCellValue(value);
      }

      value.label = value && value.label && isNaN(value.label) ?
          value.label :
          !isMobileView ? formatNumberToTwoDecimal(value.label) : `${formatNumberToTwoDecimal (value.label)} Quantity`

      return (
          <div className={isMobileView && isRightAligned ?
              'list-table__body__row__cell--right-aligned-mobile-cell' : ''}>
              <TableCellStatus
                  value={value}
                  noDot={true}
              />
          </div>
      );
    },
    Footer: 'availability'
  }
]
