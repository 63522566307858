import { deactivateActionTypes } from "./deactivate.types"

const INITIAL_STATE = {
    isLoading: false,
    isDactivated: false,
    isFail: false,
    errorMessage: null,
    deactivated: null
}
const deactivateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case deactivateActionTypes.DEACTIVATE_START:
            return {
                ...state,
                isLoading: true
            }
        case deactivateActionTypes.DEACTIVATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDactivated: true
            }
        case deactivateActionTypes.DEACTIVATE_FAILURE:
            return {
                ...state,
                isFail: true,
                isLoading: false,
                errorMessage: action.payload
            }
        case deactivateActionTypes.SET_DEACTIVATED:
            return {
                ...state,
                deactivated: action.payload
            }
        case deactivateActionTypes.REMOVE_DEACTIVATED:
            return {
                ...state,
                deactivated: null
            }            
        default:
            return state;
    }
}

export default deactivateReducer;