import './form-table-inline-button.styles.scss';

const FormTableInlineButton = ({ 
    handleClick,
    icon,
    text 
}) => (
    <button
        className='form-table-inline-button'
        onClick={handleClick}
    >
        <img
            src={icon}
            className='form-table-inline-button__icon'
        />
        <span className='form-table-inline-button__text'>{ text }</span>
        
    </button>
)


export default FormTableInlineButton;