import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const EXPIRATION_REPORT_BY_ORDER_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/productexpiration",
  listPage: "/product-expiration-reports",
  pagePath:"items",
  hasChart: false,
  tableHasGrandTotal: false,
  hasSearch: true
}

export const EXPIRATION_REPORT_BY_ORDER_COLUMN_TEMPLATE = [
  {
    Header: "Batch Number",
    accessor: "itemCode",
  },
  {
    Header: "Product Name",
    accessor: "itemName",
  },
  {
    Header: "Qty on Hand",
    accessor: "totalQtyOnHand",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Quantity: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "Expiration Date",
    accessor: "expirationDate",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Expiration: ${value ? displayCellValue(value) : ""}`;
      }

      return displayCellValue(value);
    }
  },
  {
    Header: "Status",
    accessor: "expirationStatus",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
        return displayCellValue(value);
      }

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={value}
          />
        </div>
      )
    }
  },
  
]
