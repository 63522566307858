import React from 'react';
import ReactDOM from 'react-dom';

import "./logo.styles.scss";

const Logo = ({ width }) => {
    return (
        <svg width={width} height={width/3.79} viewBox="0 0 246.32 65">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_6" data-name="Rectangle 6" width="65" height="65" transform="translate(49 353.813)" fill="#666" stroke="#707070" stroke-width="1" />
                </clipPath>
            </defs>
            <g id="Group_42" data-name="Group 42" transform="translate(-114 -356)">
                <text id="Kaliber" transform="translate(275.32 406)" fill="#000000" font-size="49" font-family="PoppinsRegular-logo" font-weight="600"><tspan x="-84.1" y="0">Kaliber</tspan></text>
                <g id="Group_2" data-name="Group 2" transform="translate(65 2.188)" clip-path="url(#clip-path)">
                    <g id="Group_30" data-name="Group 30" transform="translate(48.956 348.53)">
                        <path id="Subtraction_5" data-name="Subtraction 5" d="M21.482,64.655H15a15,15,0,0,1-15-15V43.274H.044a10.746,10.746,0,0,0,10.624-9.3H30.547V54.068a10.592,10.592,0,0,0-9.067,10.585Zm28.171,0H42.088a10.668,10.668,0,0,0-8.244-10.446V33.976H53.724a10.746,10.746,0,0,0,10.625,9.3c.113,0,.213,0,.305,0v6.383a15,15,0,0,1-15,15Zm4.142-33.975H33.844v-20.5A10.511,10.511,0,0,0,42.914,0h6.739a15,15,0,0,1,15,15v6.845c-.1,0-.2,0-.305,0A10.7,10.7,0,0,0,53.8,30.677Zm-23.248,0H10.6A10.7,10.7,0,0,0,.044,21.839H0V15A15,15,0,0,1,15,0h6.479a10.511,10.511,0,0,0,9.067,10.175v20.5Z" transform="translate(0 5.574)" fill="#155FF6" />
                        <path id="Path_3" data-name="Path 3" d="M54.377.176" transform="translate(49.252 -0.176)" fill="none" stroke="#fff" stroke-width="4" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Logo;


