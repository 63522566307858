import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { setUUID } from '../../redux/detail/detail.actions';
import { selectDetailPagePath } from '../../redux/detail/detail.reselect';
import './list-table-main-cell.styles.scss';

const ListTableMainCell = ({
    value,
    uuid,
    setUUID,
    detailPagePath,
    history,
    match
}) => {
    return (
        <span className="list-table-main-cell">
            {value}
        </span>
    )
}

const mapStateToProps = createStructuredSelector({
    detailPagePath: selectDetailPagePath
});

const mapDispatchToProps = (dispatch) => ({
    setUUID: (uuid) =>
        dispatch(setUUID(uuid)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListTableMainCell));
