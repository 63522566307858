import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { displayCellValue } from '../../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const BILL_OF_MATERIAL_INFO = {
  formTitle: "Product",
  path: "/items",
  subPath: "/billofmaterials",
  listPage: "/items",
  formId:"bill_of_materials",
}

export const BILL_OF_MATERIAL_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "item",
      fieldItems: [
        {
          label: "Product Code",
          type: "text",
          name: "itemCode",
          initialFocus: true,
          validationProps: {
            required: "Item code is required"
          },
          disabled: true
        },
        {
          label: "Product Name",
          type: "text",
          name: "itemName",
          initialFocus: true,
          validationProps: {
            required: "Item name is required"
          },
          disabled: true
        }
      ]
    },
    tableForm: {
      sectionType: "Bill of Materials Details",
      sectionTypeId: "tableForm",
      expandable: true,
      isExpand: true,
      fieldName: "items",
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Component Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "componentItemCodeName",
          width: 240,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Item Code is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"componentItemCodeName"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              isMobileView={isMobileView}
              noAvailability= {true}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "UnitOfMeasure",
          width: 90,
          Cell: ({ row }) => {
            const validationProps = {
              required: "UoM is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              valueType={"text"}
              validationProps={validationProps}
              align="left"
              disabled={true}
              headerName={"UoM"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Quantity"}
                required={false}
              />
            )
          },
          accessor: "qty",
          width: 160,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
              headerName={"Quantity"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
  },
  watchFields: []
}
