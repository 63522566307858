import { useState, useEffect, useMemo, useRef } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'
import { isObjectsEqual } from "../utils/object-values.util";
import { removeEditedItem } from "../redux/list/list.actions";
import { useDispatch } from "react-redux";
import { removeDeletedItems } from "../redux/delete/delete.actions";

const useMoreFetch = (
    endpoint,
    authTokenFromState,
    setSkip,
    skip,
    limit,
    filterInput,
    localFilterFieldNamesAndValues,
    isRefresh,
    deletedItems,
    setLoadedData,
    loadedData,
    loadedSkip,
    loadedTotalNumItems,
    editedItem,
    deactivateCheck
) => {
    const [data, setData] = useState([]);
    const [dataExport, setDataExport] = useState([]);
    const [numItems, setNumItems] = useState(0);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadedData, setIsLoadedData] = useState(false);

    const localFilterInputIsSame = useRef(false)
    const prevFilterInputRef = useRef('');
    const prevLocalFilterFieldNamesAndValuesRef = useRef({});
    const prevIsRefreshRef = useRef(false);
    const prevSkiphRef = useRef(0);

    const dispatch = useDispatch();

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            const preFilterInput = prevFilterInputRef.current;
            const preLocalFilterFieldNamesAndValues = prevLocalFilterFieldNamesAndValuesRef.current;
            const prevIsRfresh = prevIsRefreshRef.current;

            const skipRest = preFilterInput != filterInput ||
                !(isObjectsEqual(preLocalFilterFieldNamesAndValues, localFilterFieldNamesAndValues)) ?
                setSkip(0) :
                null

            if (isRefresh) {
                setSkip(0);
                prevIsRefreshRef.current = isRefresh;
                return null
            }

            const response = await Axios.get(endpoint,{
                headers: tokenHeader,
                params: {
                    skip:
                        preFilterInput != filterInput || !(isObjectsEqual(preLocalFilterFieldNamesAndValues, localFilterFieldNamesAndValues)) ?
                            0 :
                            skip,
                    limit,
                    filterInput: preFilterInput != filterInput ?
                        filterInput :
                        skip !== 0 ?
                            filterInput :
                            prevIsRfresh ?
                                filterInput :
                                null,
                    localFilterFieldNamesAndValues: !(isObjectsEqual(preLocalFilterFieldNamesAndValues, localFilterFieldNamesAndValues)) ?
                        localFilterFieldNamesAndValues :
                        skip !== 0 ?
                            localFilterFieldNamesAndValues :
                            prevIsRfresh ?
                                localFilterFieldNamesAndValues :
                                null,
                    deactivate: deactivateCheck
                }
            })

            if ((preFilterInput && preFilterInput !== filterInput) ||
                (skip === 0) ||
                (isRefresh === true)
            ) {
                setData(response.data.dataSlice);
            } else if ((preLocalFilterFieldNamesAndValues && !(isObjectsEqual(preLocalFilterFieldNamesAndValues, localFilterFieldNamesAndValues))) ||
                (preLocalFilterFieldNamesAndValues === null && skip === 0)
            ) {
                setData(response.data.dataSlice);
            } else {
                setData(prevData => [...prevData, ...response.data.dataSlice]);
            }

            setDataExport(response.data.dataExport);
            setNumItems(response.data.numItems);
            setError(null);
            setIsLoading(false);
            setIsLoadedData(false)

            prevFilterInputRef.current = filterInput;
            prevLocalFilterFieldNamesAndValuesRef.current = localFilterFieldNamesAndValues;
            prevSkiphRef.current = skip
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result you are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result you are looking for, contact your administrator."
                })
            }
            else {
                setError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setData([]);
            setIsLoading(false);
            setIsLoadedData(false);
            prevFilterInputRef.current = filterInput;
            prevLocalFilterFieldNamesAndValuesRef.current = localFilterFieldNamesAndValues;
        }
    }

    useEffect(() => {
        setIsLoading(true)
    }, [filterInput, localFilterFieldNamesAndValues, isRefresh, deactivateCheck])

    useEffect(() => {
        if ((loadedData ? loadedData.length !== 0 : false)) {
            setData(loadedData)
            setLoadedData([])
            setIsLoading(false);
            setDataExport(loadedData);
            setNumItems(loadedTotalNumItems);
            setError(null);
            setIsLoadedData(true);
            prevFilterInputRef.current = filterInput;
            prevLocalFilterFieldNamesAndValuesRef.current = localFilterFieldNamesAndValues
        }
        else {
            fetchData();
        }
    }, [skip, filterInput, localFilterFieldNamesAndValues, isRefresh, deactivateCheck]);

    useEffect(() => {
        if (deletedItems) {
            const updatedData = data.filter(row => {
                return (
                    deletedItems ? !deletedItems.includes(row.uuid) : true
                )
            })
            setData(updatedData)
            dispatch(removeDeletedItems())
        }
    }, [deletedItems])

    useEffect(() => {
        const currentData = loadedData ? loadedData : data;
        if (editedItem) {
            const updatedData = currentData.map(row => {
                return (
                    editedItem.uuid === row.uuid ? editedItem : row
                )
            })
            setData(updatedData)
            dispatch(removeEditedItem())
        }

    }, [editedItem])

    return {
        data,
        dataExport,
        numItems,
        error,
        isLoading
    };
}

export default useMoreFetch;