import { createSelector } from "reselect";

const modal = (state) => state.modal;

export const selectShowAddSupplierModal = createSelector(
    [modal],
    modal => modal.showAddSupplier
);

export const selectShowEditSupplierModal = createSelector(
    [modal],
    modal => modal.showEditSupplier
);

export const selectShowAddSupplierGroupModal = createSelector(
    [modal],
    modal => modal.showAddSupplierGroup
);

export const selectShowEditSupplierGroupModal = createSelector(
    [modal],
    modal => modal.showEditSupplierGroup
);

export const selectShowAddItemGroupModal = createSelector(
    [modal],
    modal => modal.showAddItemGroup
);

export const selectShowEditItemGroupModal = createSelector(
    [modal],
    modal => modal.showEditItemGroup
);

export const selectShowAddItemTaxModal = createSelector(
    [modal],
    modal => modal.showAddItemTax
);

export const selectShowEditItemTaxModal = createSelector(
    [modal],
    modal => modal.showEditItemTax
);

export const selectShowAddUnitOfMeasureModal = createSelector(
    [modal],
    modal => modal.showAddUnitOfMeasure
);

export const selectShowEditUnitofMeasureModal = createSelector(
    [modal],
    modal => modal.showEditUnitOfMeasure
);

export const selectShowAddItemModal = createSelector(
    [modal],
    modal => modal.showAddItem
);

export const selectShowEditItemModal = createSelector(
    [modal],
    modal => modal.showEditItem
);

export const selectShowAddCustomerGroupModal = createSelector(
    [modal],
    modal => modal.showAddCustomerGroup
);

export const selectShowEditCustomerGroupModal = createSelector(
    [modal],
    modal => modal.showEditCustomerGroup
);

export const selectShowAddLocationModal = createSelector(
    [modal],
    modal => modal.showAddLocation
)

export const selectShowEditLocationModal = createSelector(
    [modal],
    modal => modal.showEditLocation
);

export const selectShowAddCustomerModal = createSelector(
    [modal],
    modal => modal.showAddCustomer
);

export const selectShowEditCustomerModal = createSelector(
    [modal],
    modal => modal.showEditCustomer
);

export const selectEditUUID = createSelector(
    [modal],
    modal => modal.editUUID
);

export const selectModalEdit = createSelector(
    [modal],
    modal => modal.modalEdit
);

export const selectEditData = createSelector(
    [modal],
    modal => modal.editData
);

export const selectModalIsFetching = createSelector(
    [modal],
    modal => modal.isFetching
);

export const selectModalExpandSection = createSelector(
    [modal],
    modal => modal.modalExpandSection
);

export const selectModalType = createSelector(
    [modal],
    modal => modal.modalType
);

export const selectModalFormTemplate = createSelector(
    [modal],
    modal => modal.formTemplate
);

export const selectModalFormValue = createSelector(
    [modal],
    modal => modal.formValues
)

export const selectModalFromErrors = createSelector(
    [modal],
    modal => modal.formValidationErrors
)

export const selectIsLoading = createSelector(
    [modal],
    modal => modal.isLoading
)

export const selectIsSaved = createSelector(
    [modal],
    modal => modal.isSaved
);

export const selectIsFail = createSelector(
    [modal],
    modal => modal.isFail
);

export const selectErrorMessage = createSelector(
    [modal],
    modal => modal.errorMessage
)

export const selectWindowHeight = createSelector(
    [modal],
    modal => modal.windowHeight
)

export const selectModalShow= createSelector(
    [modal],
    modal => modal.showModal
)

export const selectModalFieldValues= createSelector(
    [modal],
    modal => modal.modalFieldValues
)

export const selectModalFieldValuesFilterd= createSelector(
    [modal],
    modal => modal.modalFieldValuesFilterd
)

export const selectModalTriggerName= createSelector(
    [modal],
    modal => modal.modalTriggerName
)

