const ITEM_MODAL_FORM_TEMPLATE = {
  modalTitle: "Add New Customer",
  path: "/customers",
  listPage: "/list-customers",
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "basic",
      expandable: false,
      breakPoint: 0,
      position: "LAST",
      fieldName: "customer",
      fieldItems: [
        {
          label: "Customer name",
          type: "text",
          name: "customerName",
          required: true,
          initialFocus: false,
          validationProps: {
            required: "Customer Name is required"
          }
        },
        {
          label: "Customer Phone number",
          type: "text",
          name: "customerPhone",
          required: false,
          initialFocus: false,
        },
        {
          label: "Customer TIN number",
          type: "text",
          name: "customerTin",
          required: false,
          initialFocus: false,
        },
        {
          label: "Customer Code",
          type: "text",
          name: "customerCode",
          initialFocus: false,
        },
        {
          label: "Customer Type",
          type: "select",
          name: "CustomerType",
          initialFocus: false,
          muliselect: false,
          url: "/customertypes",
          defaultValue: { label: 'Direct Customer', value: 1 },
        },
      ]
    },
  }
}

export default ITEM_MODAL_FORM_TEMPLATE;