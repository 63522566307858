export const SUPPLIER_GROUP_INFO = {
  formTitle: "Supplier Group",
  formTitleForNew: "New Supplier Group",
  path: "/suppliergroups",
  listPage: "/supplier-groups",
}

export const SUPPLIER_GROUP_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "supplierGroup",
      fieldItems: [
        {
          label: "Supplier Group Code",
          type: "text",
          name: "supplierGroupCode",
          initialFocus: false,
          validationProps: {
            required: "Unit of measure code is required"
          }
        },
        {
          label: "Supplier Group Name",
          type: "text",
          name: "supplierGroupName",
          initialFocus: false,
          validationProps: {
            required: "Supplier group name is required"
          }
        }
      ]
    }
  },
  watchFields: [
    "supplierGroup.0.supplierGroup"
  ]
}
