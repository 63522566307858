import React from "react";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import { Controller, useFormContext } from "react-hook-form";

import "./form-file-field.styles.scss";
import { connect } from "react-redux";
import { setValueEntredBlur } from "../../redux/form/form.actions";

const FormFileField = ({
    label,
    type,
    initialFocus,
    required,
    fieldName,
    partNumber,
    fieldItemName,
    validationProps,
    fieldIndex,
    defaultValue,
    sectionTypeId,
    disabled,
    accept, // added accept prop to handle file types
    setValueEntredBlur,
    ...otherProps }) => {
    const { register, formState: { errors }, control } = useFormContext();
    const name = fieldItemName;

    const valueEntered = () => {
        setValueEntredBlur(true)
    }

    return (
        <div className="form-field">
            <label className="form-field__label">
                {label}
                {
                    validationProps?.required ? (
                        <span className="form-field__label__required"> * </span>
                    ) : null
                }
            </label>

            <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                defaultValue={defaultValue}
                render={({ field: { onChange, onBlur, name, ref } }) => {
                    return (
                        <input
                            className={`form-field__input 
                             ${errors[fieldName]?.[partNumber]?.[fieldItemName] && "form-field__input--error"}
                             ${disabled && "form-field__input--disabled"}
                             `}
                            type={type} // Changed to file type
                            accept={accept} // Use accept prop to restrict file types (e.g., "image/*")
                            onChange={(event) => {
                                valueEntered();
                                onChange(event.target.files[0]); // Capture the selected file
                            }}
                            autoFocus={initialFocus}
                            {...otherProps}
                            onBlur={onBlur}
                            disabled={disabled}
                            autoComplete="new-password"
                        />
                    );
                }}
            />

            {errors?.[fieldName]?.[partNumber]?.[fieldItemName] && (
                <FormFieldError
                    errorMessage={errors?.[fieldName]?.[partNumber]?.[fieldItemName]?.message}
                />
            )}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setValueEntredBlur: (data) => dispatch(setValueEntredBlur(data))
});

export default connect(null, mapDispatchToProps)(FormFileField);
