import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const ITEM_GROUP_ITEMS_INFO = {
  formTitle: "Category",
  path: "/itemgroups",
  subPath: "/itemgroupitems",
  listPage: "/item-groups",
  pagePath:"items",
  report: true,
  hasDateRange: false,
  hasLocationFilter: false,
  tableHasGrandTotal: true,
  isNoExportAndPrint: true
}

export const ITEM_GROUP_ITEMS_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Qty On Hand",
    accessor: "qty",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
        return displayCellValue(value);
      }

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={value}
            noDot={true}
          />
        </div>
      )
    },
    Footer: 'qty'
  },
  {
    Header: "Reserve",
    accessor: "reserve",
    Cell: ({ value }) => {
      return displayCellValue(formatNumberToTwoDecimal(value));
    },
    Footer: 'reserve'
  },
  {
    Header: "Available",
    accessor: "available",
    Cell: ({ value }) => {
      return displayCellValue(formatNumberToTwoDecimal(value));
    },
    Footer: 'available'
  },
  {
    Header: "Expiration Date",
    accessor: "expirationDate",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Expiration: ${value ? displayCellValue(value) : ""}`;
      }

      return displayCellValue(value);
    }
  },
  {
    Header: "Status",
    accessor: "expirationStatus",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = false;

      if (!value) {
        return displayCellValue(value);
      }

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={value}
          />
        </div>
      )
    }
  },
]
