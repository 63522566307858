import exportActionType from "./export.types"

const INITIAL_STATE = {
    exportData: null
}
const exportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case exportActionType.SET_EXPORT_DATA:
            return {
                ...state,
                exportData: action.payload
            }
        default:
            return state;
    }
}

export default exportReducer;