import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFieldValues, selectModalFormTemplate, selectModalTitle, selectShowAddSupplierModal, selectShowSupplierModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ITEM_MODAL_FORM_TEMPLATE from './customer-modal.template.js';
import { setModalFieldValuesFilterd, setModalTemplate, setModalType, setShowAddSupplierModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const CustomerModal = ({
    showAddSupplier,
    setModalTemplate,
    setModalType,
    formTemplate,
    modalFieldValues,
    setModalFieldValuesFilterd
}) => {
    useEffect(() => {
        setModalTemplate(ITEM_MODAL_FORM_TEMPLATE);
    }, []);

    useEffect(() => {
        if (modalFieldValues && modalFieldValues.customerName) {
            const fieldValue = modalFieldValues;

            const customerLabel = `${fieldValue.customerName} ${fieldValue.customerTin ? "| "+ fieldValue.customerTin : ""} ${fieldValue.customerPhone ? "| " + fieldValue.customerPhone : "" }`;

            const newFieldValue = {
                Customer: {
                    value: fieldValue.uuid,
                    label: customerLabel
                },
            }

            setModalFieldValuesFilterd(newFieldValue)
        }
    }, [modalFieldValues])

    return (
        <div className="item-modal">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddSupplier}
                        size={"LARGE"}
                    />
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTemplate: selectModalFormTemplate,
    modalFieldValues: selectModalFieldValues
})

const mapDispatchToProps = (disptach) => ({
    setShowAddSupplierModal: () =>
        disptach(setShowAddSupplierModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (data) =>
        disptach(setModalType(data)),
    setModalFieldValuesFilterd: (data) =>
        disptach(setModalFieldValuesFilterd(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModal);