import filterActionType from "./filter.types";

export const setFilterInput = (filerInput) => ({
    type: filterActionType.SET_FILTER_INPUT,
    payload: filerInput
});

export const setLocalFilterFieldNamesAndValues = (inputNameAndValue) => ({
    type: filterActionType.SET_LOCAL_FILTER_FIELD_NAMES_AND_VALUES,
    payload: inputNameAndValue
});

export const setFilterEmpty = () => ({
    type: filterActionType.SET_FILTER_EMPTY
});


