const EDIT_ITEM_FORM_TEMPLATE = {
  modalTitle: "Edit Location",
  path: "/locations",
  listPage: "/list-locations",
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC_DETAILS",
      expandable: false,
      breakPoint: 0,
      fieldName: "location",
      fieldItems: [
        {
          label: "Location Code",
          type: "text",
          name: "locationCode",
          initialFocus: true,
          validationProps: {
            required: "Location code is required"
          }
        },
        {
          label: "Location Name",
          type: "text",
          name: "locationName",
          initialFocus: false,
          validationProps: {
            required: "Location name is required"
          }
        },
        {
          label: "description",
          type: "textarea",
          name: "description",
          required: false,
          initialFocus: false
        }
      ]
    },
    addressDetails: {
      sectionType: "address details",
      sectionTypeId: "ADDRESS_DETAILS",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 5,
      position: "LAST",
      fieldName: "locationAddress",
      fieldItems: [
        {
          label: "street",
          type: "textarea",
          name: "street",
          required: false,
          initialFocus: false
        },
        {
          label: "city",
          type: "text",
          name: "city",
          required: false,
          initialFocus: false
        },
        {
          label: "state",
          type: "text",
          name: "state",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "zip code",
          type: "text",
          name: "zipCode",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "country",
          type: "text",
          name: "country",
          required: false,
          initialFocus: false
        },
        {
          label: "phone no 1",
          type: "text",
          size: "small",
          name: "phoneNumber1",
          required: false,
          initialFocus: false
        },
        {
          label: "phone no 2",
          type: "text",
          size: "small",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on payment address details',
            }
          }
        },
        {
          label: "website",
          type: "text",
          name: "website",
          required: false,
          initialFocus: false
        }
      ]
    },
  }
}

export default EDIT_ITEM_FORM_TEMPLATE;