import { deleteActionTypes } from "./delete.types";
import deleteListOfItems from "../../services/delete-items.service";

const deleteItemsStart = () => ({
    type: deleteActionTypes.DELETE_ITEMS_START
})

const deleteItemsSuccess = () => ({
    type: deleteActionTypes.DELETE_ITEMS_SUCCESS
})

const deleteItemsFailure = (errorMessage) => ({
    type: deleteActionTypes.DELETE_ITEMS_FAILURE,
    payload: errorMessage
})

export const deleteItems = (itmes, authTokenFromState, url) => {
    return async dispatch => {
        try{
            dispatch(deleteItemsStart());
            const response = await deleteListOfItems(itmes, authTokenFromState, url);
            dispatch(deleteItemsSuccess());

            return response;
        } catch(error){
            dispatch(deleteItemsFailure(error.message))
            throw error;
        }
    }
}

export const setIsFail = (error) => ({
    type: deleteActionTypes.SET_IS_FAIL,
    payload: error
})

export const setDeletedItems = (items) => ({
    type: deleteActionTypes.SET_DELETED_ITEMS,
    payload: items
})

export const removeDeletedItems = () => ({
    type: deleteActionTypes.REMOVE_DELETED_ITEMS,
})

