export const objectToArray = (data) => {
    return (Object.keys(data).map(key => {
        if (typeof data[key] === "object" &&
            !Array.isArray(data[key]) &&
            data[key] !== null
        ) {
            return (
                data[key]
            )
        }

        return null
    }).filter(object => {
        if (!object) {
            return false
        }

        return true
    }))
}

export const getObjectsOnlyFromArray= (data) => {
    return Object.keys(data).map(key => {
        return (
            typeof data[key] === "object" ? data[key] : null
        )
    }).filter(object => object);
}