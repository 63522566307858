import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import Spinner from "../../components/spinner/spinner.component";
import useFetchEdit from "../../effects/use-fetch-edit.effect";
import { selectFormIsFetching } from "../../redux/form/form.reselect";
import { authTokenSelector } from "../../redux/user/user.reselect";

const Company = ({
    authToken,
    isFetching,
    history
}) => {
    const endpoint = `/company`

    const { data, error } = useFetchEdit(
        endpoint,
        authToken,
        true,
        true,
        false,
        0
    );

    useEffect(() => {
        if(isFetching){
            if (Object.keys(data).length) {
                history.push(`company/${data.uuid}`)
            } else {
                history.push(`/company/new`)
            }
        }
        

    }, [data])
    return (
        isFetching &&
        <Spinner wide={true} />

    )
};

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    isFetching: selectFormIsFetching,
})


export default withRouter(connect(mapStateToProps)(Company));