export const LOCAL_FILTER_FIELDS = [
    {
        label: "Category Code",
        type: "text",
        name: "itemGroupCode",
    },
    {
        label: "Category Name",
        type: "text",
        name: "itemGroupName",
    }
]