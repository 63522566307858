import "./help-header.styles.scss";

import IconAdd from "../../assets/icons/add-white.svg";

import CustomButton from "../custom-button/custom-button.componenet";
import TitleHeader from "../title-header/title-header.componenet";
import HelpFilter from "../help-filter/help-filter.component";

// import ListGlobalFilter from "../help-global-filter/help-global-filter.component";


const HelpHeader = ({
    buttonAction,
    buttonActionLabel,
    endpoint,
    title,
    isViewOnly,
    helpData,
    handleFilter,
    handleClose
}) => {
    return (
        <div className="help-header container">
            <TitleHeader>{title}</TitleHeader>
            <div className="help-header__tools">
                <div className="help-header__tools__filter-actions">
                    <div className="help-header__tools__filter-actions__filter">
                       <HelpFilter 
                            handleFilter={handleFilter}
                            helpData={helpData}
                       />
                    </div>
                </div>
                <CustomButton
                    inverted={true}
                    handleClick={(event) => { handleClose(event) }}
                >
                    Cancel
                </CustomButton>
            </div>
        </div>
    )
};


export default HelpHeader;