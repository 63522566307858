import react from 'react';
import { useTable } from "react-table";

import './form-data-display-table.styles.scss';
import { selectAutoFillDataForm } from '../../redux/form/form.reselect';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';


const FormDataDisplayTable = ({columns, autoFillDataForm }) => {
    console.log("Columns", columns)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data : Object.keys(autoFillDataForm).length ? autoFillDataForm.data: []
    });

    return (
        <div className="form-data-display-table-container">
            <table className="form-data-display-table" {...getTableProps()}>
                <thead className="form-data-display-table__header">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className="form-data-display-table__header__cell" {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="form-data-display-table__body" {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td className="form-data-display-table__body__cell" {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })
                    ) : (
                        // Render the custom message when data is empty
                        <tr>
                            <td className="form-data-display-table__body__empty-message" colSpan={columns.length} >
                                No Data
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {autoFillDataForm.total &&
                <div className="form-data-display-table__total">
                    Total: {autoFillDataForm.total}
                </div>
            }
           
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    autoFillDataForm: selectAutoFillDataForm,
})

export default connect(mapStateToProps)(FormDataDisplayTable);