import documentActionType from "./document.types"

const INITIAL_STATE = {
    documentDropDownItems: null,
    boxNumber: 1
}
const documentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case documentActionType.SET_DOCUMENT_DROP_DOWN_ITEMS:
            return {
                ...state,
                documentDropDownItems: action.payload
            }
        case documentActionType.SET_BOX_NUMBER:
            return {
                ...state,
                boxNumber: action.payload
            }
        default:
            return state;
    }
}

export default documentReducer;