export const LOCAL_FILTER_FIELDS = [
    {
        label: "Code",
        type: "text",
        name: "supplierCode",
    },
    {
        label: "Name",
        type: "text",
        name: "supplierName",
    },
    {
        label: "Tin no",
        type: "text",
        name: "supplierTinNumber",
    },
    {
        label: "Phone number",
        type: "text",
        name: "phoneNumber",
    },
    {
        label: "Email",
        type: "text",
        name: "email",
    }
]