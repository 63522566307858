export const PURCHASE_ORDER_DOCUMENT_DROP_DOWN_ITEMS = [
    {
        id: "purchase-orders",
        text: "Purchase Order"
    },
    {
        id: "receiving-notes",
        text: "Receiving Note"
    },
    {
        id: "purchase-invoices",
        text: "Purchase Invoice"
    },
    {
        id: "purchase-returns",
        text: "Purchase Return"
    }
]