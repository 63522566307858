import FormTableInlineButton from "../form-table-inline-button/form-table-inline-button.component";
import IconDelete from '../../assets/icons/delete.svg'
import { removeRowFormTable } from "../../redux/form/form.actions";
import { connect } from "react-redux";


const FormTableInlineDelete = ({
    rowNumber,
    removeRowFormTable,
    toggleRowSelected
}) => {
    const handleClick = (event) => {
        event.preventDefault();
        removeRowFormTable([rowNumber])
    } 

    return (
        <div className="form-table-inline-action">
            <FormTableInlineButton 
                icon={IconDelete}
                text="Delete"
                handleClick={(event) => handleClick(event)}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    removeRowFormTable: (rows) =>
        dispatch(removeRowFormTable(rows))
})

export default connect(null, mapDispatchToProps)(FormTableInlineDelete);
