import {createSelector} from 'reselect'

const filterSelector = (state) => state.filter; 

export const selectFilterInput = createSelector(
    [filterSelector],
    filter => filter.filterInput
);

export const selectLocalFilterFieldNamesAndValues = createSelector(
    [filterSelector],
    filter => filter.localFilterFieldNamesAndValues
);