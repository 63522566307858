export const LOCAL_FILTER_FIELDS = [
    {
        label: "Expense Type Name",
        type: "text",
        name: "expenseTypeName",
    },
    {
        label: "Description",
        type: "text",
        name: "description",
    }
]