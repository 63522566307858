import { useRef, useState } from 'react';

import './drop-down-container.styles.scss';

import DropDown from '../drop-down/drop-down.component';
import useOutSideClick from '../../utils/out-side-click.util';

const DropDownContainer = ({ 
    dropDownItems, 
    DropDownButton, 
    icon, 
    children, 
    size,
    handleClick,
    dropDwon,
    iconLeft,
    inverted,
    isNotStopPropagation,
    hasSpinner,
    isLoading
}) => {
    const [isDropDown, setIsDropDown] = useState(false);
    const actionBtn = useRef(null);

    const handleCollabseDropDown = () => {
        setIsDropDown(false);
    }
    
    useOutSideClick(actionBtn, handleCollabseDropDown)

    const handleClickDropDownBtn = (event) => {
        isNotStopPropagation && event.stopPropagation();
        setIsDropDown(!isDropDown);
    }

    return (
        <span className="action-container">
            <DropDownButton
                reference={actionBtn}
                icon={icon}
                iconLeft={iconLeft}
                content={children}
                inverted={inverted}
                hasSpinner={hasSpinner}
                isLoading={isLoading}
                handleClickDropDownBtn={ dropDwon ?  handleClickDropDownBtn : handleClick} />
            {isDropDown && dropDwon ? 
                <DropDown 
                    dropDownItems={dropDownItems}
                    handleClick={(event) => {handleClick(event)}} 
                    size={size}
                /> : null
            }
        </span>
    )
};

export default DropDownContainer;