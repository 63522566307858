import filterActionType from "./filter.types"

const INITIAL_STATE = {
    filterInput: '',
    localFilterFieldNamesAndValues: {},
}
const filterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case filterActionType.SET_FILTER_INPUT:
            return {
                ...state,
                filterInput: action.payload
            }
        case filterActionType.SET_LOCAL_FILTER_FIELD_NAMES_AND_VALUES:
            if (!Object.keys(action.payload).length) {
                console.log("inside filter reduxer names and values empty object")
                return {
                    ...state,
                    localFilterFieldNamesAndValues: { ...action.payload }
                }
            }
            console.log("inside filter reduxer names and values")
            console.log(action.payload)
            return {
                ...state,
                localFilterFieldNamesAndValues: {
                    ...state.localFilterFieldNamesAndValues,
                    ...action.payload
                }
            }
        case filterActionType.SET_FILTER_EMPTY:
            return {
                ...state,
                filterInput: '',
                localFilterFieldNamesAndValues: {}
            }
        default:
            return state;
    }
}

export default filterReducer;