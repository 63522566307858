import React from "react";

import "./detail-body-section-header.styles.scss";

import IconDownArrow from "../../assets/icons/down-arrow.svg";

import CustomeButton from "../custom-button/custom-button.componenet";

const DetailBodySectionHeader = ({
    title,
    handleClick,
    collapseSection,
    forPrint
}) => {
    return (
        <div className="detail-body-section-header">
            <button className="detail-body-section-header__title-collapse" onClick={handleClick}>
                <span className={`detail-body-section-header__title-collapse__title 
                    ${forPrint ? "detail-body-section-header__title-collapse__title--print-font" : null}`
                }>
                    {title}
                </span>
                {
                    !forPrint &&
                    <img className={` detail-body-section-header__title-collapse__icon 
                    ${collapseSection ? '' : 'detail-body-section-header__title-collapse__icon--up'}`}
                        src={IconDownArrow}
                    />
                }

            </button>
        </div>
    )
}

export default DetailBodySectionHeader;