import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { useMediaQuery } from 'react-responsive';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

export const MARKETING_DATA_COLUMNS = [
    {
        Header: "MD Code",
        accessor: "mdCode",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "Territory",
        accessor: "territory",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `From: ${displayCellValue(value)}`;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Sales Rep",
        accessor: "salesRep",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `From: ${displayCellValue(value)}`;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Date",
        accessor: "collectDate",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return (format(new Date(value), 'dd/MM/yyyy'))
        }
    },
    {
        Header: "Route",
        accessor: "route",
        Cell: ({ value }) => {

            return displayCellValue(value);
        }
    },
  
]