import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '../../../pages/home/home.component';
import Logout from '../../logout/logout.component';
import ProtectedHeader from '../protected-header/protected-header.componenet';
import ProtectedFooter from '../protected-footer/protected-footer.componenet';
import LoggedInTest from '../../../pages/log-in/logged-in-test';
import ProtectedRoute from '../protected-route/protected-route.componenet';
import DetailPrintDisplay from '../../../pages/detail-print/detail-print-display.component';
import ListItemTaxes from '../../../pages/item-taxes/list-item-taxes.componenet';
import DetailItemTax from '../../../pages/item-taxes/detail-item-tax/detail-item-tax.component';
import ListItem from '../../../pages/items/list-items.componenet';
import DetailItem from '../../../pages/items/detail-item/detail-item.component';
import Company from '../../../pages/company/company.component';
import CompanyForm from '../../../pages/company/company-form/company-form.component';
import AccountsList from '../../../pages/account/account-list/accounts-list.componenet';
import AccountForm from '../../../pages/account/account-form/account-form.component';
import PurchaseOrdersList from '../../../pages/purchase-order/purchase-order-list/purchase-orders-list.componenet';
import PurchaseOrderForm from '../../../pages/purchase-order/purchase-order-form/purchase-order-form.component';
import PurchaseOrderDocument from '../../../pages/purchase-order/purchase-order-document/purchase-order-document.componenet';
import SalesOrdersList from '../../../pages/sales-order/sales-order-list/sales-orders-list.componenet';
import SalesOrderForm from '../../../pages/sales-order/sales-order-form/sales-order-form.component';
import SalesOrderDocument from '../../../pages/sales-order/sales-order-document/sales-order-document.componenet';
import SalesInvoiceDocument from '../../../pages/sales-invoice/sales-invoice-document/sales-invoice-document.componenet';
import SalesReceiptDocument from '../../../pages/sales-receipt/sales-receipt-document/sales-receipt-document.componenet';
import WorkOrdersList from '../../../pages/work-order/work-order-list/work-orders-list.componenet';
import WorkOrderForm from '../../../pages/work-order/work-order-form/work-order-form.component';
import PickDocument from '../../../pages/pick/pick-document/pick-document.componenet';
import PackingSlipDocument from '../../../pages/pack/packing-slip-document/packing-slip-document.componenet';
import BoxLabelDocument from '../../../pages/pack/box-label-document/box-label-document.componenet';
import CreditNoteDocument from '../../../pages/credit-note/credit-note-document/credit-note-document.componenet';
import PurchaseReceiveDocument from '../../../pages/receive/purchase-receive-document/purchase-receive-document.componenet';
import PurchaseReturnDocument from '../../../pages/purchase-return/purchase-return-document/purchase-return-document.componenet';
import PurchaseInvoiceDocument from '../../../pages/purchase-invoice/purchase-invoice-document.componenet';
import ItemsList from '../../../pages/item/item-list/items-list.componenet';
import ItemForm from '../../../pages/item/item-form/item-form.component';
import StockTransferList from '../../../pages/stock-transfer/stock-transfer-list/stock-transfers-list.componenet';
import StockTransferForm from '../../../pages/stock-transfer/stock-transfer-form/stock-transfer-form.component';
import StockTransferDocument from '../../../pages/stock-transfer/stock-transfer-document/stock-transfer-document.componenet';
import ItemGroupsList from '../../../pages/item-group/item-group-list/item-groups-list.componenet';
import ItemGroupForm from '../../../pages/item-group/item-group-form/item-group-form.component';
import UnitOfMeasuresList from '../../../pages/unit-of-measure/unit-of-measure-list/unit-of-measures-list.componenet';
import UnitOfMeasureForm from '../../../pages/unit-of-measure/unit-of-measure-form/unit-of-measure-form.component';
import LocationsList from '../../../pages/location/location-list/locations-list.componenet';
import LocationForm from '../../../pages/location/location-form/location-form.component';
import SupplierGroupsList from '../../../pages/supplier-group/supplier-group-list/supplier-groups-list.componenet';
import SupplierGroupForm from '../../../pages/supplier-group/supplier-group-form/supplier-group-form.component';
import CustomerGroupsList from '../../../pages/customer-group/customer-group-list/customer-groups-list.componenet';
import CustomerGroupForm from '../../../pages/customer-group/customer-group-form/customer-group-form.component';
import SupplierList from '../../../pages/supplier/supplier-list/suppliers-list.componenet';
import SupplierForm from '../../../pages/supplier/supplier-form/supplier-form.component';
import CustomersList from '../../../pages/customer/customer-list/customers-list.componenet';
import CustomerForm from '../../../pages/customer/customer-form/customer-form.component';
import ItemTaxList from '../../../pages/item-tax/item-tax-list/item-taxes-list.componenet';
import ItemTaxForm from '../../../pages/item-tax/item-tax-form/item-tax-form.component';
import SalesReport from '../../../pages/sales-report/sales-report.componenet';
import PurchaseReport from '../../../pages/purchase-report/purchase-report.componenet';
import InventoryReport from '../../../pages/inventory-report/inventory-report.componenet';

import AccessDenied from '../../../pages/access-denied/access-denied.component';
import Upgrade from '../../../pages/upgrade/upgrade.component';
import Help from '../../../pages/help/help.component';
import StockAdjustmentsList from '../../../pages/stock-adjustment/stock-adjustment-list/stock-adjustments-list.componenet';
import StockAdjustmentForm from '../../../pages/stock-adjustment/stock-adjustment-form/stock-adjustment-form.component';
import StockAdjustmentDocument from '../../../pages/stock-adjustment/stock-adjustment-document/stock-adjustment-document.componenet';
import PackingListDocument from '../../../pages/pack/packing-list-document/packing-list-document.componenet';
import ExpenseTypesList from '../../../pages/expense-type/expense-type-list/expense-types-list.componenet';
import ExpenseTypeForm from '../../../pages/expense-type/expense-type-form/expense-type-form.component';
import OperatingExpensesList from '../../../pages/operating-expense/operating-expense-list/operating-expenses-list.componenet';
import OperatingExpenseForm from '../../../pages/operating-expense/operating-expense-form/operating-expense-form.component';
import OpearingExpenseDocument from '../../../pages/operating-expense/operating-expense-document/opearing-expense-document.componenet';
import SalesQuoteList from '../../../pages/sales-quote/sales-quote-list/sales-quote-list.componenet';
import SalesQuoteForm from '../../../pages/sales-quote/sales-quote-form/sales-quote-form.component';
import SalesQuoteDocument from '../../../pages/sales-quote/sales-quote-document/sales-quote-document.componenet';
import SalesQuoteSummaryDocument from '../../../pages/sales-quote/sales-quote-document/sales-quote-summary-document.componenet';

import StockCountsList from '../../../pages/stock-count/stock-count-list/stock-counts-list.componenet';
import StockCountForm from '../../../pages/stock-count/stock-count-form/stock-count-form.component';
import StockCountDocument from '../../../pages/stock-count/stock-count-document/stock-count-document.componenet';
import TerritoriesList from '../../../pages/territory/territory-list/territories-list.componenet';
import TerritoryForm from '../../../pages/territory/territory-form/territory-form.component';
import MarketingDataList from '../../../pages/marketing-data/marketing-data-list/marketing-data-list.componenet';
import MarketingDataForm from '../../../pages/marketing-data/marketing-data-form/marketing-data-form.component';
import SalesTargetsList from '../../../pages/sales-target/sales-target-list/sales-targets-list.componenet';
import SalesTargetForm from '../../../pages/sales-target/sales-target-form/sales-target-form.component';
import ProductionEstimationForm from '../../../pages/production-estimation/production-estimation-form/production-estimation-form.component';
import ProductionPlanList from '../../../pages/production-plan/production-plan-list/production-plan-list.componenet';
import ProductionPlanForm from '../../../pages/production-plan/production-plan-form/production-plan-form.component';
import GoodsIssueNoteDocumentComponenet from '../../../pages/pick/pick-document/goods-issue-note-document.componenet.jsx';


const ProtectedLayout = ({
    noNavs
}) => <>
        {!noNavs && <ProtectedHeader />}
        <Switch>
            <ProtectedRoute exact path="/home" component={Home} appName={false} />
            <ProtectedRoute exact path="/logout" component={Logout} />
            <ProtectedRoute exact path="/help" component={Help} />
            <Route path='/access-denied' component={AccessDenied} />
            <Route path='/upgrade' component={Upgrade} />
       
            <ProtectedRoute exact path="/loggedintest" component={LoggedInTest} />
            <ProtectedRoute exact path="/detail-print" component={DetailPrintDisplay} />
            <ProtectedRoute exact path="/list-item-taxes" component={ListItemTaxes} />
            <ProtectedRoute exact path="/detail-item-tax" component={DetailItemTax} />
            <ProtectedRoute exact path="/list-items" component={ListItem} />
            <ProtectedRoute exact path="/detail-item" component={DetailItem} />

            <ProtectedRoute exact path="/company" component={Company} key={Date.now()} appName={`company`} />
            <ProtectedRoute exact path="/company/new" component={CompanyForm} key={Date.now()} isNew={true} appName={`company`} />
            <ProtectedRoute exact path="/company/:uuid" component={CompanyForm} key={Date.now()} appName={`company`} />
       
            <ProtectedRoute exact path="/accounts" component={AccountsList} key={Date.now()} appName={`account`} />
            <ProtectedRoute exact path="/accounts/new" component={AccountForm} key={Date.now()} isNew={true} appName={`account`} />
            <ProtectedRoute exact path="/accounts/:uuid" component={AccountForm} key={Date.now()} appName={`account`} />

            <ProtectedRoute exact path="/suppliers" component={SupplierList} key={Date.now()} appName={`supplier`} />
            <ProtectedRoute exact path="/suppliers/new" component={SupplierForm} isNew={true} key={Date.now()} appName={`supplier`} />
            <ProtectedRoute exact path="/suppliers/:uuid" component={SupplierForm} key={Date.now()} appName={`supplier`} />

            <ProtectedRoute exact path="/items" component={ItemsList} key={Date.now()} appName={`item`}/>
            <ProtectedRoute exact path="/items/new" component={ItemForm} isNew={true} key={Date.now()} appName={`item`}/>
            <ProtectedRoute exact path="/items/:uuid" component={ItemForm} key={Date.now()} appName={`item`}/>

            <ProtectedRoute exact path="/sales-quotes" component={SalesQuoteList} key={Date.now()} appName={`sales_quote`}/>
            <ProtectedRoute exact path="/sales-quotes/new" component={SalesQuoteForm} isNew={true} key={Date.now()} appName={`sales_quote`}/>
            <ProtectedRoute exact path="/sales-quotes/:uuid" component={SalesQuoteForm} key={Date.now()} appName={`sales_quote`}/>
            <ProtectedRoute exact path="/sales-quotes/:uuid/documents" component={SalesQuoteDocument} key={Date.now()} appName={`sales_quote`} />
            <ProtectedRoute exact path="/sales-quotes/:uuid/sales-quote-summary/documents" component={SalesQuoteSummaryDocument} key={Date.now()} appName={`sales_quote`} />

            <ProtectedRoute exact path="/sales-targets" component={SalesTargetsList} key={Date.now()} appName={`sales_targets`}/>
            <ProtectedRoute exact path="/sales-targets/new" component={SalesTargetForm} isNew={true} key={Date.now()} appName={`sales_targets`}/>
            <ProtectedRoute exact path="/sales-targets/:uuid" component={SalesTargetForm} key={Date.now()} appName={`sales_targets`}/>

            <ProtectedRoute exact path="/production-plans" component={ProductionPlanList} key={Date.now()} appName={`production_plans`}/>
            <ProtectedRoute exact path="/production-plans/new" component={ProductionPlanForm} isNew={true} key={Date.now()} appName={`production_plans`}/>
            <ProtectedRoute exact path="/production-plans/:uuid" component={ProductionPlanForm} key={Date.now()} appName={`production_plans`}/>

            <ProtectedRoute exact path="/item-groups" component={ItemGroupsList} key={Date.now()} appName={`item_group`}/>
            <ProtectedRoute exact path="/item-groups/new" component={ItemGroupForm} isNew={true} key={Date.now()} appName={`item_group`}/>
            <ProtectedRoute exact path="/item-groups/:uuid" component={ItemGroupForm} key={Date.now()} appName={`item_group`}/>

            <ProtectedRoute exact path="/unit-of-measures" component={UnitOfMeasuresList} key={Date.now()} appName={`unit_of_measure`} />
            <ProtectedRoute exact path="/unit-of-measures/new" component={UnitOfMeasureForm} key={Date.now()} isNew={true} appName={`unit_of_measure`} />
            <ProtectedRoute exact path="/unit-of-measures/:uuid" component={UnitOfMeasureForm} key={Date.now()} appName={`unit_of_measure`} />

            <ProtectedRoute exact path="/expense-types" component={ExpenseTypesList} key={Date.now()} appName={`expense_type`} />
            <ProtectedRoute exact path="/expense-types/new" component={ExpenseTypeForm} key={Date.now()} isNew={true} appName={`expense_type`} />
            <ProtectedRoute exact path="/expense-types/:uuid" component={ExpenseTypeForm} key={Date.now()} appName={`expense_type`} />
            
            <ProtectedRoute exact path="/locations" component={LocationsList} key={Date.now()} appName={`location`}/>
            <ProtectedRoute exact path="/locations/new" component={LocationForm} isNew={true} key={Date.now()} appName={`location`}/>
            <ProtectedRoute exact path="/locations/:uuid" component={LocationForm} key={Date.now()} appName={`location`}/>

            <ProtectedRoute exact path="/territories" component={TerritoriesList} key={Date.now()} appName={`territory`}/>
            <ProtectedRoute exact path="/territories/new" component={TerritoryForm} isNew={true} key={Date.now()} appName={`territory`}/>
            <ProtectedRoute exact path="/territories/:uuid" component={TerritoryForm} key={Date.now()} appName={`territory`}/>

            <ProtectedRoute exact path="/marketing-data" component={MarketingDataList} key={Date.now()} appName={`marketing_data`}/>
            <ProtectedRoute exact path="/marketing-data/new" component={MarketingDataForm} isNew={true} key={Date.now()} appName={`marketing_data`}/>
            <ProtectedRoute exact path="/marketing-data/:uuid" component={MarketingDataForm} key={Date.now()} appName={`marketing_data`}/>

            <ProtectedRoute exact path="/supplier-groups" component={SupplierGroupsList} key={Date.now()} appName={`supplier_group`} />
            <ProtectedRoute exact path="/supplier-groups/new" component={SupplierGroupForm} isNew={true} key={Date.now()} appName={`supplier_group`} />
            <ProtectedRoute exact path="/supplier-groups/:uuid" component={SupplierGroupForm} key={Date.now()} appName={`supplier_group`} />

            <ProtectedRoute exact path="/customer-groups" component={CustomerGroupsList} key={Date.now()} appName={`customer_group`}/>
            <ProtectedRoute exact path="/customer-groups/new" component={CustomerGroupForm} isNew={true} key={Date.now()} appName={`customer_group`}/>
            <ProtectedRoute exact path="/customer-groups/:uuid" component={CustomerGroupForm} key={Date.now()} appName={`customer_group`}/>

            <ProtectedRoute exact path="/customers" component={CustomersList} key={Date.now()} appName={`customer`}/>
            <ProtectedRoute exact path="/customers/new" component={CustomerForm} isNew={true} key={Date.now()} appName={`customer`}/>
            <ProtectedRoute exact path="/customers/:uuid" component={CustomerForm} key={Date.now()} appName={`customer`}/>

            <ProtectedRoute exact path="/purchase-orders" component={PurchaseOrdersList} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/new" component={PurchaseOrderForm} isNew={true} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid" component={PurchaseOrderForm} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/documents" component={PurchaseOrderDocument} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/receiving-notes/documents" component={PurchaseReceiveDocument} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/purchase-returns/documents" component={PurchaseReturnDocument} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/purchase-invoices/documents" component={PurchaseInvoiceDocument} key={Date.now()} appName={`purchase_order`} />

            <ProtectedRoute exact path="/sales-orders" component={SalesOrdersList} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/new" component={SalesOrderForm} isNew={true} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid" component={SalesOrderForm} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/documents" component={SalesOrderDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/invoices/documents" component={SalesInvoiceDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/receipts/documents" component={SalesReceiptDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/picks/documents" component={PickDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/packing-slips/documents" component={PackingSlipDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/packing-lists/documents" component={PackingListDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/box-labels/documents" component={BoxLabelDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/credit-note/documents" component={CreditNoteDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/goods-issue-note/documents" component={GoodsIssueNoteDocumentComponenet} key={Date.now()} appName={`sales_order`}/>

            <ProtectedRoute exact path="/work-orders" component={WorkOrdersList} key={Date.now()} appName={`work_order`} />
            <ProtectedRoute exact path="/work-orders/new" component={WorkOrderForm} isNew={true} key={Date.now()} appName={`work_order`} />
            <ProtectedRoute exact path="/work-orders/:uuid" component={WorkOrderForm} key={Date.now()} appName={`work_order`} />

            <ProtectedRoute exact path="/production-estimation" component={ProductionEstimationForm} key={Date.now()} appName={`production_estimation`} />

            <ProtectedRoute exact path="/stock-transfers" component={StockTransferList} key={Date.now()} appName={`stock_transfer`}/>
            <ProtectedRoute exact path="/stock-transfers/new" component={StockTransferForm} isNew={true} key={Date.now()} appName={`stock_transfer`}/>
            <ProtectedRoute exact path="/stock-transfers/:uuid" component={StockTransferForm} key={Date.now()} appName={`stock_transfer`}/>
            <ProtectedRoute exact path="/stock-transfers/:uuid/documents" component={StockTransferDocument} key={Date.now()} appName={`stock_transfer`}/>

            <ProtectedRoute exact path="/stock-adjustments" component={StockAdjustmentsList} key={Date.now()} appName={`stock_adjustment`}/>
            <ProtectedRoute exact path="/stock-adjustments/new" component={StockAdjustmentForm} isNew={true} key={Date.now()} appName={`stock_adjustment`}/>
            <ProtectedRoute exact path="/stock-adjustments/:uuid" component={StockAdjustmentForm} key={Date.now()} appName={`stock_adjustment`}/>
            <ProtectedRoute exact path="/stock-adjustments/:uuid/documents" component={StockAdjustmentDocument} key={Date.now()} appName={`stock_adjustment`}/>

            <ProtectedRoute exact path="/stock-counts" component={StockCountsList} key={Date.now()} appName={`stock_count`}/>
            <ProtectedRoute exact path="/stock-counts/new" component={StockCountForm} isNew={true} key={Date.now()} appName={`stock_count`}/>
            <ProtectedRoute exact path="/stock-counts/:uuid" component={StockCountForm} key={Date.now()} appName={`stock_count`}/>
            <ProtectedRoute exact path="/stock-counts/:uuid/documents" component={StockCountDocument} key={Date.now()} appName={`stock_count`}/>

            <ProtectedRoute exact path="/operating-expenses" component={OperatingExpensesList} key={Date.now()} appName={`operating_expense`}/>
            <ProtectedRoute exact path="/operating-expenses/new" component={OperatingExpenseForm} isNew={true} key={Date.now()} appName={`operating_expense`}/>
            <ProtectedRoute exact path="/operating-expenses/:uuid" component={OperatingExpenseForm} key={Date.now()} appName={`operating_expense`}/>
            <ProtectedRoute exact path="/operating-expenses/:uuid/documents" component={OpearingExpenseDocument} key={Date.now()} appName={`operating_expense`}/>
            
            <ProtectedRoute exact path="/item-taxes" component={ItemTaxList} key={Date.now()} appName={`item_tax`}/>
            <ProtectedRoute exact path="/item-taxes/new" component={ItemTaxForm} isNew={true} key={Date.now()} appName={`item_tax`}/>
            <ProtectedRoute exact path="/item-taxes/:uuid" component={ItemTaxForm} key={Date.now()} appName={`item_tax`}/>

            <ProtectedRoute exact path="/sales-reports" component={SalesReport} key={Date.now()} appName={`report`}/>
            <ProtectedRoute exact path="/purchase-reports" component={PurchaseReport} key={Date.now()} appName={`report`}/>
            <ProtectedRoute exact path="/inventory-reports" component={InventoryReport} key={Date.now()} appName={`report`}/>
        </Switch>
        {!noNavs && <ProtectedFooter />}
    </>

export default ProtectedLayout;