import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectModalShow, selectModalType } from '../../redux/modal/modal.reselect';

import MODAL_TYPE from './modal-type.data';

import AddItemGroup from '../add-item-group/add-item-group.componenet';
import AddSupplierGroup from '../add-supplier-group/add-supplier-group.component';

import AddSupplier from '../add-supplier/add-supplier.component';
import EditItemGroup from '../edit-item-group/edit-item-group.componenet';
import EditSupplierGroup from '../edit-supplier-group/edit-supplier-group.componenet';
import EditSupplier from '../edit-supplier/edit-supplier.componenet';
import AddItemTax from '../add-item-tax/add-item-tax.componenet';
import EditItemTax from '../edit-item-taxes/edit-item-tax.componenet';
import AddUnitOfMeasure from '../add-unit-of-measure/add-unit-of-measure.componenet';
import EditUnitOfMeasure from '../edit-unit-of-measures/edit-unit-of-measures.componenet';
import AddItem from '../add-item/add-item.componenet';
import EditItem from '../edit-item/edit-item.componenet';
import AddCustomerGroup from '../add-customer-group/add-customer-group.component';
import EditCustomerGroup from '../edit-customer-group/edit-customer-group.componenet';
import AddLocation from '../add-location/add-location.componenet';
import EditLocation from '../edit-location/edit-location.componenet';
import AddCustomer from '../add-customer/add-customer.component';
import EditCustomer from '../edit-customer/edit-customer.componenet';
import ItemModal from '../item-modal/item-modal.component';
import CustomerModal from '../customer-modal/customer-modal.component';
import SupplierModal from '../supplier-modal/supplier-modal.component';

const ModalManager = ({
    showModal
}) => {
    const loadModal = (showModal) => {
        switch (showModal) {
            case "item":
                return <ItemModal />
            case "customer":
                return <CustomerModal />
            case "supplier":
                return <SupplierModal />
            default:
                return null
        }
    }

    return (
        <div className="modal-manager">
            {loadModal(showModal)}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showModal: selectModalShow
})

export default connect(mapStateToProps)(ModalManager);

