import { format } from 'date-fns';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

export const PACKING_LIST_COLUMNS_DOCUMENT = [
  // {
  //   Header: "No.",
  //   id: "count",
  //   Cell: ({ row }) => Number(row.id) + 1,
  //   width: 50,
  // },
  {
    Header: "Box Number",
    accessor: "boxNumber",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Item Code",
    accessor: "itemCode",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Item Name",
    accessor: "itemName",
    width: 200,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "UoM",
    accessor: "unitOfMeasureCode",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Qty Packed",
    accessor: "qtyPacked",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "Packed Date",
    accessor: "packedDate",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  } 
]