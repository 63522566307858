import { createSelector } from "reselect";

const navigation = (state) => state.navigation;

export const selectSupplierDropdownHidden = createSelector(
    [navigation],
    navigation => navigation.supplierHidden
);

export const selectBurgerHidden = createSelector(
    [navigation],
    navigation => navigation.burgerHidden
);

export const selectWindowWidth = createSelector(
    [navigation],
    navigation => navigation.windowWidth
)