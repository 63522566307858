import React, { useEffect, useRef } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ITEM_GROUP_INFO, ITEM_GROUP_FORM_TEMPLATE } from './item-group.template';
import { ITEM_GROUP_ITEMS_INFO, ITEM_GROUP_ITEMS_COLUMN_TEMPLATE } from '../../item-group-items/item-group-items.template';

import { ITEM_GROUP_DOCUMENT_DROP_DOWN_ITEMS } from './item-group-documents-nav.template';

import FormContainer from '../../../components/form/form.container';
import { selectFormSideBarNavActiveItem, selectFormTableLatestData, selectFormTableLatestRows, selectFormTableRows, selectFormTemplate, selectSelectedField } from '../../../redux/form/form.reselect';
import { setEditDataForm, setFormChangedValues, setFormEdit, setFormInfo, setFormProgress, setFormSideBarNav, setFormSideBarNavActiveItem, setFormTableData, setFormTableLatestData, setFormTableLatestRows, setFormTemplate, setFormTotalValues, setFormType, setSelectedFieldValue } from '../../../redux/form/form.actions';
import { useState } from 'react';
import { removeEmptyValuedObjects } from '../../../utils/object-values.util';
import { removeObjectWithoutSpecifiedPropertiesHasValue } from '../../../utils/array-values.utils';
import { formatNumberToTwoDecimal, roundNumberTwoDecimal, stringToNumber, stringToNumberWithTwoDecmialPoint } from '../../../utils/format-number.util';
import { withRouter } from 'react-router-dom';
import useFetchEdit from '../../../effects/use-fetch-edit.effect';
import { authTokenSelector, selectAccessRights } from '../../../redux/user/user.reselect';
import { selectIsRefresh } from '../../../redux/refresh/refresh.reselect';
import { setDocumentDropDownItems } from '../../../redux/document/document.actions';
import useFetchFieldValues from '../../../effects/use-fetch-field-values';
import { disableFormFields } from '../../../utils/form-values.utils';
import { selectIsItemHistory, selectItemHistoryNavNumberTemp } from '../../../redux/report/report.reselect';
import { setIsItemHistory, setItemHistoryNavNumberTemp, setLoadedData,setReportInfo, setReportSideBarNavActiveItem } from '../../../redux/report/report.actions';


const APP_NAME = `item_group`;

const SUB_ITEMS = [
    {
        text: "Category",
        info: ITEM_GROUP_INFO,
        formTemplate: ITEM_GROUP_FORM_TEMPLATE
    },
    {
        text: "Category Products",
        info: ITEM_GROUP_ITEMS_INFO,
        formTemplate: ITEM_GROUP_FORM_TEMPLATE,
        reportColumn: ITEM_GROUP_ITEMS_COLUMN_TEMPLATE
    }
]

const ItemGroupForm = ({
    match,
    setFormInfo,
    setFormTemplate,
    setFormEdit,
    setFormTableData,
    setFormTableLatestRows,
    formTableLatestRows,
    setFormTotalValues,
    setFormTableLatestData,
    formLatestData,
    authToken,
    setFormChangedValues,
    setEditDataForm,
    isNew,
    isRefresh,
    selectedField,
    setSelectedFieldValue,
    setDocumentDropDownItems,
    setFormSideBarNav,
    setFormSideBarNavActiveItem,
    formSideBarNavActiveItem,
    setFormProgress,
    setLoadedData,
    setIsItemHistory,
    isItemHistory,
    setReportInfo,
    accessRights
}) => {
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isViewOnly = useRef(false);
    
    const endpoint = isNew ?
        `${SUB_ITEMS[formSideBarNavActiveItem].info.path}` :
        !formSideBarNavActiveItem ?
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}` :
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}${SUB_ITEMS[formSideBarNavActiveItem].info.subPath}`;

    const authTokenFromState = authToken;
    const { data, error } = useFetchEdit(
        endpoint,
        authTokenFromState,
        !isNew,
        true,
        isRefresh,
        formSideBarNavActiveItem,
        SUB_ITEMS[formSideBarNavActiveItem].info.report
    );

    console.log("report value:", formSideBarNavActiveItem ,SUB_ITEMS[formSideBarNavActiveItem].info.report)

    const reportEndpoint = `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}${SUB_ITEMS[formSideBarNavActiveItem].info.subPath}`;

    const fieldEndpoint = `/itemvalues`;
    const { fieldData, fieldError } = useFetchFieldValues(
        fieldEndpoint,
        authTokenFromState,
        selectedField,
        isNew
    )

    const TABLE_DATA = [{
        id: 0
    }];

    useEffect(() => {
        if (selectedField) {
            const selectedFieldValue = {
                name: selectedField.name,
                value: fieldData
            }

            setSelectedFieldValue(selectedFieldValue)
        }
    }, [fieldData])

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === APP_NAME && APP_NAME.toLowerCase()
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "view only")) {
            isViewOnly.current = true;
        }
        setIsAccessRightCheked(true)
    }, [])

    useEffect(() => {
        setFormInfo(SUB_ITEMS[formSideBarNavActiveItem].info);

        const formTemplate = JSON.parse(JSON.stringify(SUB_ITEMS[formSideBarNavActiveItem].formTemplate));

        if (isViewOnly.current && Object.keys(formTemplate).length) {
            const bodySections = formTemplate.bodySections;
            disableFormFields(bodySections)
        }

        setFormTemplate(formTemplate);
        setDocumentDropDownItems(ITEM_GROUP_DOCUMENT_DROP_DOWN_ITEMS);
        setFormTableData(TABLE_DATA);

        if (!isNew) {
            setFormSideBarNav(SUB_ITEMS);
            setFormEdit(true);
        }
    }, [formSideBarNavActiveItem, isViewOnly]);

    useEffect(() => {
        setReportInfo(SUB_ITEMS[formSideBarNavActiveItem].info);
    }, [formSideBarNavActiveItem]);

    useEffect(() => {
        setEditDataForm(data);
    }, [data]);

    const [confirmIsCategoryItemsSetAndReportRest, setconfirmIsCategoryItemsSetAndReportRest] = useState(false);

    useEffect(() => {
        if (!isItemHistory && formSideBarNavActiveItem === (SUB_ITEMS.length - 1)) {
            setLoadedData({
                data: null,
                skip: null,
                totalNumItems: 0
            })

            setIsItemHistory(true)
            setconfirmIsCategoryItemsSetAndReportRest(true)
        } else {
            setIsItemHistory(false)
        }


    }, [formSideBarNavActiveItem])

    useEffect(() => {
        if (isItemHistory) {
            setFormSideBarNavActiveItem(SUB_ITEMS.length - 1)
        }
    }, [isItemHistory])

    useEffect(() => {
        return () => {
            setFormInfo(null);
            setFormTemplate(null);
            setDocumentDropDownItems(null);
            setFormTableData(null);
            setFormEdit(false)
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setEditDataForm([]);
            setSelectedFieldValue(null);
            setFormSideBarNav([]);
            setFormSideBarNavActiveItem(0);
            setReportInfo(null);
            setIsItemHistory(false)
        }
    }, [])

    return (
        <div>
            {isAccessRightChecked &&
                (isItemHistory ? formSideBarNavActiveItem === (SUB_ITEMS.length - 1) : true) &&
                Object.keys(ITEM_GROUP_FORM_TEMPLATE).length > 0 ?
                !SUB_ITEMS[formSideBarNavActiveItem].info.report ?
                    <FormContainer
                        endpoint={endpoint}
                        isViewOnly={isViewOnly.current}
                    /> :
                    confirmIsCategoryItemsSetAndReportRest &&
                    <FormContainer
                        showReport={true}
                        reportColumn={SUB_ITEMS[formSideBarNavActiveItem].reportColumn}
                        isViewOnly={isViewOnly.current}
                        reportEndpoint={reportEndpoint}
                        dataKeys={[]}
                        hasNoDate={true}
                    />
                : null
            }
        </div>

        // <div>
        //     {isAccessRightChecked &&
        //         Object.keys(ITEM_GROUP_FORM_TEMPLATE).length > 0 ?
        //         !SUB_ITEMS[formSideBarNavActiveItem].info.report ?
        //             <FormContainer
        //                 endpoint={endpoint}
        //                 isViewOnly={isViewOnly.current}
        //             /> :
        //             <FormContainer
        //                 showReport={true}
        //                 reportColumn={SUB_ITEMS[formSideBarNavActiveItem].reportColumn}
        //                 isViewOnly={isViewOnly.current}
        //                 reportEndpoint={reportEndpoint}
        //                 dataKeys={[]}
        //                 hasNoDate={true}
        //             />
        //             : null
        //     }
        // </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatestRows: selectFormTableLatestRows,
    formLatestData: selectFormTableLatestData,
    authToken: authTokenSelector,
    isRefresh: selectIsRefresh,
    selectedField: selectSelectedField,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem,
    accessRights: selectAccessRights,
    isItemHistory: selectIsItemHistory
})

const mapDispatchToProps = (disptach) => ({
    setFormInfo: (formInfo) =>
        disptach(setFormInfo(formInfo)),
    setFormTemplate: (formTemplate) =>
        disptach(setFormTemplate(formTemplate)),
    setDocumentDropDownItems: (dropDownItems) =>
        disptach(setDocumentDropDownItems(dropDownItems)),
    setFormType: (modalType) =>
        disptach(setFormType(modalType)),
    setFormTableData: (tableData) =>
        disptach(setFormTableData(tableData)),
    setFormTotalValues: (tableData) =>
        disptach(setFormTotalValues(tableData)),
    setFormChangedValues: (data) =>
        disptach(setFormChangedValues(data)),
    setFormEdit: (data) =>
        disptach(setFormEdit(data)),
    setEditDataForm: (data) =>
        disptach(setEditDataForm(data)),
    setFormTableLatestRows: (data) =>
        disptach(setFormTableLatestRows(data)),
    setFormTableLatestData: (data) =>
        disptach(setFormTableLatestData(data)),
    setSelectedFieldValue: (data) =>
        disptach(setSelectedFieldValue(data)),
    setFormSideBarNav: (data) =>
        disptach(setFormSideBarNav(data)),
    setFormSideBarNavActiveItem: (data) =>
        disptach(setFormSideBarNavActiveItem(data)),
    setFormProgress: (data) =>
        disptach(setFormProgress(data)),
    setLoadedData: (data) =>
        disptach(setLoadedData(data)),
    setIsItemHistory: (data) =>
        disptach(setIsItemHistory(data)),
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemGroupForm));