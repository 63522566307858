import React, { useRef, useEffect } from 'react';
import { components } from 'react-select';

const CustomMenuList = (props) => {
    const menuListRef = useRef(null);

    useEffect(() => {
        const handleWheel = (e) => {
            const menuList = menuListRef.current;
            if (menuList) {
                const { scrollTop, scrollHeight, clientHeight } = menuList;
                const atBottom = scrollTop + clientHeight >= scrollHeight;
                const atTop = scrollTop === 0;

                if (e.deltaY > 0 && !atBottom) {
                    // Scrolling down and not at the bottom
                    e.stopPropagation();
                } else if (e.deltaY < 0 && !atTop) {
                    // Scrolling up and not at the top
                    e.stopPropagation();
                }
            }
        };

        const menuList = menuListRef.current;
        if (menuList) {
            menuList.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (menuList) {
                menuList.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    useEffect(() => {
        const selectedOption = document.querySelector('.react-select__option--is-selected');
        if (selectedOption) {
            selectedOption.scrollIntoView({ block: 'nearest' });
        }
    }, [props.children]);

    return (
        <components.MenuList {...props}>
            <div className="custom-menu-list" style={{ position: 'relative' }}>
                <div
                    ref={menuListRef}
                    className="custom-menu-list__list"
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                >
                    {props.children}
                </div>
                {props.createNew && (
                    <div className="custom-menu-list__button" style={{
                        padding: '10px',
                        cursor: 'pointer',
                        color: '#155FF6',
                        textAlign: 'left',
                        fontSize: "14px",
                        fontFamily: "'PoppinsRegular', Arial, sans-serif",
                        width: '100%',
                        background: '#fff',
                        borderTop: '1px solid #ccc',
                        boxSizing: 'border-box'
                    }}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            props.onClickCreateButton(e);
                        }}>
                        + {props.createNew.text}
                    </div>
                )}
            </div>
        </components.MenuList>
    );
};

export default CustomMenuList;
