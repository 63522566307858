import React, { useRef } from "react";

import DetailHeader from "../../../components/detail-header/detail-header.component";
import DetailBody from "../../../components/detail-body/detail-body.componenet";
import { useEffect } from "react";
import { setShowEditItemModal } from "../../../redux/modal/modal.actions";
import { connect } from "react-redux";
import { setDetailPrint } from "../../../redux/detail/detail.actions";

import { ITEM_DETAIL_COLUMNS } from "./columns-items.data";

const DetailItem = ({ 
    setShowEditItemModal, 
    setDetailPrint 
}) => {
    const title = "Item";
    const endpoint = "/items";

    useEffect(() => {
        setDetailPrint({ title, endpoint })
    }, [])

    return (
        <>
            <DetailHeader
                title={title}
                setEditModal={setShowEditItemModal}
            />
            <DetailBody
                title={title}
                endpoint={endpoint}
                detailTableColumns={ITEM_DETAIL_COLUMNS}
            />
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setShowEditItemModal: () =>
        dispatch(setShowEditItemModal()),
    setDetailPrint: (detailPrint) =>
        dispatch(setDetailPrint(detailPrint))
})
export default connect(null, mapDispatchToProps)(DetailItem)