import "./list-header.styles.scss";

import { IoMdAdd } from "react-icons/io";
import IconAdd from "../../assets/icons/add-white.svg";

import CustomButton from "../custom-button/custom-button.componenet";
import TitleHeader from "../title-header/title-header.componenet";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import DropDownContainer from "../drop-down-container/drop-down-container.componenet";
import ListGlobalFilter from "../list-global-filter/list-global-filter.component";
import ListExport from "../list-export/list-export.compoenent";
import ListPrint from "../list-print/list-print.compoenent";
import ListRefresh from "../list-refresh/list-refresh.component";
import ListDeleteItems from "../list-delete-items/list-delete-items.componenet";
import CheckBox from "../checkbox/checkbox.component";
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectDeactivateCheck } from "../../redux/list/list.reselect";
import { setDeactivateCheck } from '../../redux/list/list.actions';
import { useEffect, useState } from "react";

const ListHeader = ({
    buttonAction,
    buttonActionLabel,
    endpoint,
    title,
    isViewOnly,
    deactivateCheck,
    setDeactivateCheck
}) => {
    const [isMobile, setIsMobile] = useState(false);

    const setWindowSize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }

    useEffect(() => {
        window.addEventListener("resize", setWindowSize);

        return () => {
            window.removeEventListener("scroll", setWindowSize)
        }
    }, []);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [])

    const handleDeactivate = (event) => {
        setDeactivateCheck(event.target.checked)
    }

    return (
        <div className="list-header container">
            <div className="list-header__title">
                <TitleHeader>{title}</TitleHeader>
            </div>
            <div className="list-header__search">
                <ListGlobalFilter
                    endpoint={endpoint}
                />
            </div>
            <div className="list-header__tools">
                <div className="list-header__tools__actions">
                    {!isMobile &&
                        <>
                            <CheckBox
                                handleDeactivate={(event) => handleDeactivate(event)}
                                checked={deactivateCheck}
                            > Deactivated
                            </CheckBox>
                            <ListRefresh
                                DropDownButton={DropDownButton}
                            />
                        </>
                    }
                </div>
                {
                    !isViewOnly &&
                    <CustomButton
                        handleClick={buttonAction}
                        icon={IoMdAdd}
                    >
                        {!isMobile ? buttonActionLabel : "Add New"}
                    </CustomButton>
                }
            </div>
        </div>
    )
};


const mapStateToProps = createStructuredSelector({
    deactivateCheck: selectDeactivateCheck
});

const mapDispatchToProps = (dispatch) => ({
    setDeactivateCheck: (data) =>
        dispatch(setDeactivateCheck(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListHeader)
