import React, { useRef } from "react";

import DetailHeader from "../../../components/detail-header/detail-header.component";
import DetailBody from "../../../components/detail-body/detail-body.componenet";
import { useEffect } from "react";
import { useState } from "react";
import { setShowEditItemGroupModal, setShowEditItemTaxModal, setShowEditSupplierGroupModal } from "../../../redux/modal/modal.actions";
import { connect } from "react-redux";
import { setDetailPrint } from "../../../redux/detail/detail.actions";

const DetailItemTax = ({ 
    setShowEditItemTaxModal, 
    setDetailPrint 
}) => {
    const title = "Item Tax";
    const endpoint = "/itemtaxes";

    useEffect(() => {
        setDetailPrint({ title, endpoint })
    }, [])

    return (
        <>
            <DetailHeader
                title={title}
                setEditModal={setShowEditItemTaxModal}
            />
            <DetailBody
                title={title}
                endpoint={endpoint}
            />
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setShowEditItemTaxModal: () =>
        dispatch(setShowEditItemTaxModal()),
    setDetailPrint: (detailPrint) =>
        dispatch(setDetailPrint(detailPrint))
})
export default connect(null, mapDispatchToProps)(DetailItemTax)