import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const MARKETING_DATA_PRODUCT_INFO = {
  formTitle: "Marketing Data",
  formTitleForNew: "New Marketing Data",
  path: "/marketingdata",
  subPath: "/products",
  listPage: "/marketing-data"
}

export const MARKETING_DATA_PRODUCT_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "marketingData",
      fieldItems: [
        {
          label: "MD Code",
          type: "text",
          name: "mdCode",
          initialFocus: false,
          disabled: true,
          defaultValue: `MD-`
        },
        {
          label: "Sales Rep",
          type: "select",
          name: "salesRep",
          initialFocus: false,
          muliselect: false,
          url: "/salesreps",
          disabled: true,
          validationProps: {
            required: "Sales Rep is required"
          }
        },
        {
          label: "Territory",
          type: "select",
          name: "territory",
          initialFocus: false,
          muliselect: false,
          url: "/territories",
          disabled: true,
          validationProps: {
            required: "Territory is required"
          }
        },
      
        {
          label: "Date",
          type: "date",
          name: "collectDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "Collect date is required"
          }
        },
      ]
    },
    tableForm: {
      sectionType: "Product Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Customer"}
                required={true}
              />
            )
          },
          accessor: "customer",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/customersindirect";
            const validationProps = {
              required: "Customer is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Customer"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Customer"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
              createNew={{text:"Add New Customer", modalName: "customer"}}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/itemsfgandcompetitor";
            const validationProps = {
              required: "Product Code/Name is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              isMobileView={isMobileView}
              noAvailability={true}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Availability"}
                required={true}
              />
            )
          },
          accessor: "availability",
          width: 100,
          Cell: ({ row }) => {
            const endpointUrl = "/availability";
            const validationProps = {
              required: "Availability is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"availability"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Availability"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Buying Price"}
                required={false}
              />
            )
          },
          accessor: "buyingPrice",
          width: 100,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"buyingPrice"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
              headerName={"Buying Price"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Selling Price"}
                required={false}
              />
            )
          },
          accessor: "sellingPrice",
          width: 100,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"sellingPrice"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
              headerName={"Selling Price"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
  },
  watchFields: []
}
