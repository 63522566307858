import { formActionTypes } from "./form.types";
import modalFormSubmit from "../../services/form-submit.service";
import formSubmit from "../../services/form-submit.service";
import formSubmitForComplete from "../../services/form-submit-for-complete.service";
import fetchQuickComplete from "../../services/quick-complete.service";
import fetchQuickPay from "../../services/quick-pay.service";
import fetchApprove from "../../services/approve.service";
import formMultipartSubmit from "../../services/form-multi-submit.service";

export const setShowAddSupplierForm = () => ({
    type: formActionTypes.SHOW_ADD_SUPPLIER_FORM
});

export const setShowEditSupplierForm = () => ({
    type: formActionTypes.SHOW_EDIT_SUPPLIER_FORM
});

export const setShowAddPurchaseOrderForm = () => ({
    type: formActionTypes.SHOW_ADD_PURCHASE_ORDER_FORM
});

export const setShowEditForm = (listType) => {
    return async dispatch => {
        try {
            switch (listType) {
                case "LIST_SUPPLIER":
                    return dispatch(setShowEditSupplierForm());
                default:
                    return null
            }
        } catch (error) {
            throw new Error();
        }
    }
}

export const setEditUUIDForm = (uuid) => ({
    type: formActionTypes.SET_EDIT_UUID,
    payload: uuid
});

export const setFormTableData = (tableData) => ({
    type: formActionTypes.SET_FORM_TABLE_DATA,
    payload: tableData
});

export const setEditDataForm = (data) => ({
    type: formActionTypes.SET_EDIT_DATA,
    payload: data
});

export const setAutoFillDataForm = (data) => ({
    type: formActionTypes.SET_AUTO_FILL_DATA_FORM,
    payload: data
});

export const setIsFormFetching = (isFetching) => ({
    type: formActionTypes.SET_IS_FETCHING,
    payload: isFetching
});

export const setFormExpandSection = (sectionTypeId, isExpand) => ({
    type: formActionTypes.SET_FORM_EXPAND_SECTION,
    sectionTypeId: sectionTypeId,
    isExpand: isExpand
});

export const setFormType = (formType) => ({
    type: formActionTypes.SET_FORM_TYPE,
    payload: formType
})

export const setFormTemplate = (formTemplate) => ({
    type: formActionTypes.SET_FORM_TEMPLATE,
    payload: formTemplate
})

export const setFormEdit = (formEdit) => ({
    type: formActionTypes.SET_FORM_EDIT,
    payload: formEdit
})

export const setFormTableLatestData = (formTableLatestData) => ({
    type: formActionTypes.SET_FORM_LATEST_DATA,
    payload: formTableLatestData
})

export const setFormTableLatestRows = (tableLatestRow) => ({
    type: formActionTypes.SET_TABLE_LATEST_ROWS,
    payload: tableLatestRow
})

const submitFormStart = () => ({
    type: formActionTypes.SUBMIT_FORM_START
})

const submitFormSuccess = () => ({
    type: formActionTypes.SUBMIT_FORM_SUCCESS
})

const submitFormFailure = (errorMessage) => ({
    type: formActionTypes.SUBMIT_FORM_FAILURE,
    payload: errorMessage
})

export const submitForm = (formValue, authTokenFromState, url, formEdit, isMultipart) => {
    return async dispatch => {
        try {
            console.log("isMultipart ", isMultipart)
            dispatch(submitFormStart());
            let response;
            if(!isMultipart){
                response = await formSubmit(formValue, authTokenFromState, url, formEdit);
            } else {
                response = await formMultipartSubmit(formValue, authTokenFromState, url, formEdit);
            }
           
            dispatch(submitFormSuccess());

            return response;
        } catch (error) {
            dispatch(submitFormFailure(error.message))
            throw new Error();
        }
    }
}

export const submitFormForComplete = (formValue, authTokenFromState, url, formEdit) => {
    return async dispatch => {
        // try {
        //     dispatch(submitFormStart());
        //     const response = await formSubmitForComplete(formValue, authTokenFromState, url, formEdit);
        //     dispatch(submitFormSuccess());

        //     return response;
        // } catch (error) {
        //     dispatch(submitFormFailure(error.message))
        //     throw new Error();
        // }
    }
}

const setFormForCompleteStart = () => ({
    type: formActionTypes.COMPLETE_FORM_START
})

const setFormForCompleteSuccess = (isCompleteStatus) => ({
    type: formActionTypes.COMPLETE_FORM_SUCCESS,
    payload: isCompleteStatus
})

const setFormForCompleteFailure = (errorMessage) => ({
    type: formActionTypes.COMPLETE_FORM_FAILURE,
    payload: errorMessage
})

export const setFormForComplete = (authTokenFromState, url) => {
    return async dispatch => {
        try {
            dispatch(setFormForCompleteStart());
            const response = await fetchQuickComplete(authTokenFromState, url);
            dispatch(setFormForCompleteSuccess(response.isComplete));

            return response;
        } catch (error) {
            dispatch(setFormForCompleteFailure(error.message))
            throw new Error();
        }
    }
}

const setFormForPayStart = () => ({
    type: formActionTypes.PAY_FORM_START
})

const setFormForPaySuccess = (isPayStatus) => ({
    type: formActionTypes.PAY_FORM_SUCCESS,
    payload: isPayStatus
})

const setFormForPayFailure = (errorMessage) => ({
    type: formActionTypes.PAY_FORM_FAILURE,
    payload: errorMessage
})

export const setFormForPay = (authTokenFromState, url) => {
    return async dispatch => {
        try {
            dispatch(setFormForPayStart());
            const response = await fetchQuickPay(authTokenFromState, url);
            dispatch(setFormForPaySuccess(response.isPay));

            return response;
        } catch (error) {
            dispatch(setFormForPayFailure(error.message))
            throw new Error();
        }
    }
}

const setFormForApproveStart = () => ({
    type: formActionTypes.APPROVE_FORM_START
})

const setFormForApproveSuccess = (isApporveStatus) => ({
    type: formActionTypes.APPROVE_FORM_SUCCESS,
    payload: isApporveStatus
})

const setFormForApproveFailure = (errorMessage) => ({
    type: formActionTypes.APPROVE_FORM_FAILURE,
    payload: errorMessage
})

export const setFormForApprove = (authTokenFromState, url) => {
    return async dispatch => {
        try {
            dispatch(setFormForApproveStart());
            const response = await fetchApprove(authTokenFromState, url);
            dispatch(setFormForApproveSuccess(response));

            return response;
        } catch (error) {
            dispatch(setFormForApproveFailure(error.message))
            throw new Error();
        }
    }
}

export const setErrorMessage = (errorMessage) => ({
    type: formActionTypes.GENERAL_ERROR_MESSAGE,
    payload: errorMessage
})

export const resetErrorMessageAndIsFail = () => ({
    type: formActionTypes.RESET_ERROR_MESSAGE_AND_IS_FAIL,
})

export const setIsFailForm = (error) => ({
    type: formActionTypes.SET_IS_FAIL,
    payload: error
})

export const setFromValidationError = (errors) => ({
    type: formActionTypes.SET_FORM_VALIDATION_ERROR,
    payload: errors
})

export const setWindowHeightForm = (height) => ({
    type: formActionTypes.SET_WINDOW_HEIGHT,
    payload: height
});

export const addRowFormTable = () => ({
    type: formActionTypes.ADD_ROW_FORM_TABLE,
})

export const removeRowFormTable = (data) => ({
    type: formActionTypes.REMOVE_ROW_FORM_TABLE,
    payload: data
})

export const setFormTotalValues = (data) => ({
    type: formActionTypes.SET_FORM_TOTAL_VALUES,
    payload: data
})

export const setFormChangedValues = (data) => ({
    type: formActionTypes.SET_FORM_CHANGED_VALUES,
    payload: data
})

export const setIsEditByFullForm = (data) => ({
    type: formActionTypes.SET_IS_EDIT_FULL_FORM,
    payload: data
})

export const setFormInfo = (data) => ({
    type: formActionTypes.SET_FORM_INFO,
    payload: data
})

export const setSelectedField = (data) => ({
    type: formActionTypes.SET_SELECTED_FIELD,
    payload: data
});

export const setSelectedFieldValue = (data) => ({
    type: formActionTypes.SET_SELECTED_FIELD_VALUE,
    payload: data
})

export const setFormSideBarNav = (data) => ({
    type: formActionTypes.SET_FORM_SIDE_BAR_NAV,
    payload: data
});

export const setFormSideBarNavActiveItem = (data) => ({
    type: formActionTypes.SET_FORM_SIDE_BAR_NAV_ACTIVE_ITEM,
    payload: data
})

export const setFormProgress = (data) => ({
    type: formActionTypes.SET_FORM_PROGRESS,
    payload: data
})

export const removeRemoveTableRows = () => ({
    type: formActionTypes.SET_REMOVE_REMOVE_TABLE_ROW
})

export const setFormTemplateEnableField = (data) => ({
    type: formActionTypes.SET_FORM_TEMPLATE_ENABLE_FIELD,
    payload: data
});


export const setValueEntredBlur = (data) => ({
    type: formActionTypes.SET_VALUE_ENTERED_BLUR,
    payload: data
})

export const setIsSelectDropdownOpen = (data) => ({
    type: formActionTypes.SET_SELECT_DROP_DOWN_OPEN,
    payload: data
})

export const setIsFormTableFieldChange = (data) => ({
    type: formActionTypes.IS_FORM_TABLE_FIELD_CHANGE,
    payload: data
})


