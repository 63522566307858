import { Axios } from "../config";
import { getAuthToken } from "../utils/auth_token.utils";

const fetchListItem = async (authTokenFromState, url) => {
    try {
        const tokenHeader = getAuthToken(authTokenFromState);
        const response = await Axios.get(url, {
            headers: tokenHeader,
            params: {
                type: "LIST"
            }
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            if (error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 404
            ) {
                if (error.response.data !== null) {
                    throw {
                        name: "client_error",
                        message: error.response.data
                    }
                }
            } else {
                throw {
                    name: "server_error",
                    message: "System error, please try again after few moments."
                }
            }
        }
        else if (error.request) {
            throw {
                name: "network_error",
                message: "Please, make sure you are connected to the network."
            }
        }
        else {
            throw {
                name: "unknown_error",
                message: "An unknown error occurred, please contact your administrator."
            }
        }
    }
}

export default fetchListItem;