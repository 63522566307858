import detailActionType from "./detail.types";

export const setUUID = (data) => ({
    type: detailActionType.SET_UUID,
    payload: data
});

export const setDetailData = (data) => ({
    type: detailActionType.SET_DETAIL_DATA,
    payload: data
});

export const setDetailPagePath = (pagePath) => ({
    type: detailActionType.SET_DETAIL_PAGE_PATH,
    payload: pagePath
});

export const setDetailPrint = (detailPrint) => ({
    type: detailActionType.SET_DETAIL_PRINT,
    payload: detailPrint
})

