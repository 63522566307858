import HelpBodyItem from "../help-body-item/help-body-item.component";
import "./help-body.styles.scss";

const HelpBody = ({
    helpData
}) => {
    console.log(helpData)
    return (
        <div className="help-body">
            <div className="help-body__wrapper">
                {helpData.length ?
                    helpData.map((item, index) => {
                        return (<div key={index} className="help-body__item">
                            <HelpBodyItem
                                item={item}
                            />
                        </div>)
                    }) : 
                    <span className="help-body__error">
                        No result found!
                    </span>
                    
                }
            </div>
        </div>
    )
}

export default HelpBody;