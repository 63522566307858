import React, { useEffect, useRef, useState } from 'react';

import './form-body.styles.scss';

import ModalBodySection from '../modal-body-section/modal-body-section.componenet';
import ModalError from '../modal-error/modal-error.componenet';
import { createStructuredSelector } from 'reselect';
import {
    selectEditData,
    selectIsFail,
    // selectModalEdit, 
    selectFormIsFetching,
    selectFormEdit,
    selectFormSideBarNavActiveItem,
    selectFormInfo
} from '../../redux/form/form.reselect';
import { connect } from 'react-redux';
import Spinner from '../spinner/spinner.component';
import { setIsFormFetching, setValueEntredBlur } from '../../redux/form/form.actions';
import ModalBodyEditSection from '../modal-body-edit-section/modal-body-edit-section.compoenent';
import FormBodySection from '../form-body-section/form-body-section.componenet';
import FormTable from '../form-table/form-table.component';
import { selectFormValidationErrors } from '../../redux/form/form.reselect';
import FormFullError from '../form-full-error/form-full-error.componenet';
import StockDetails from '../stock-details/stock-details.component';
import { selectFeatureFlags } from '../../redux/user/user.reselect';
import ErrorBox from '../error-box/error-box.component';
import FormTableMulti from '../form-table-multi/form-table-multi.component';

const ERROR = {
    name: "Upgrade",
    message: "Upgrade to use this feature. Please contact your representative to arrange the upgrade."
}

const FormBody = ({
    bodySections,
    multiTableForm,
    formErrors,
    isFail,
    isFetching,
    modalEdit,
    editData,
    formTableData,
    formEdit,
    endpoint,
    isSideNavOn,
    setValueEntredBlur,
    isViewOnly,
    formSideBarNavActiveItem,
    formInfo,
    featureFlags
}) => {
    const [isFeatureFlagSet, setIsFeatureFlagSet] = useState(false);
    const [isFeatureDisalbed, setIsFeatureDisalbed] = useState(false);
    // const isFeatureDisalbed = useRef(false);

    const getEditSectionData = (editData, fieldName) => {
        return editData[fieldName].map(object => {
            Object.keys(object).forEach(key => {
                if (key.includes("Date")) {
                    object[key] = new Date(object[key])
                }
            })
            return object
        })
    }

    useEffect(() => {
        if (formEdit && !isFetching) {
            setValueEntredBlur(true)
        }
    }, [isFetching])

    useEffect(() => {
        if (featureFlags.length) {
            const featureFlag = featureFlags.find((featureFlag => {
                return featureFlag.feature.toLowerCase() === formInfo.formId && formInfo.formId.toLowerCase()
            }))

            if (featureFlag && (featureFlag.flag.toLowerCase() === "disable")) {
                setIsFeatureDisalbed(true);
            } else {
                setIsFeatureDisalbed(false);
            }

            setIsFeatureFlagSet(true);
        }
    }, [formInfo])

    return (
        <div className="form-body">
            {
                isFeatureFlagSet &&
                <div className="form-body__wrapper">
                    {
                        isFeatureDisalbed ?
                            <div className="form-body__error">
                                <ErrorBox
                                    error={ERROR}
                                    icon={true}
                                /> 
                            </div>
                            :
                            <>
                                {
                                    formErrors ?
                                        <FormFullError formValidationError={true} /> :
                                        isFail ? <FormFullError formResponseError={true} /> :
                                            null
                                }
                                {
                                    isFetching ?
                                        <div className="form-body__spinner">
                                            <Spinner />
                                        </div>
                                        :
                                        Object.keys(bodySections).map(key => {
                                            if (formEdit && !editData[bodySections[key].fieldName]) {
                                                return null
                                            }

                                            if (!formEdit && bodySections[key].showEditOnly) {
                                                return null
                                            }

                                            let editSectionData
                                            if (formEdit) {
                                                editSectionData = getEditSectionData(editData, bodySections[key].fieldName)
                                            }

                                            if (bodySections[key].sectionTypeId === "tableForm" && !multiTableForm) {
                                                return (
                                                    <FormTable
                                                        key={key}
                                                        bodySection={bodySections[key]}
                                                        formTableData={formTableData}
                                                        editSectionData={editData.items}
                                                        endpoint={formEdit && endpoint}
                                                        isViewOnly={isViewOnly}
                                                    />
                                                )
                                            } 
                                            
                                            return (
                                                <FormBodySection
                                                    key={key}
                                                    bodySection={bodySections[key]}
                                                    editSectionData={editSectionData}
                                                    isViewOnly={isViewOnly}
                                                />
                                            )
                                        })
                                }
                            </>
                    }
                </div>
            }
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    formErrors: selectFormValidationErrors,
    isFail: selectIsFail,
    isFetching: selectFormIsFetching,
    // modalEdit: selectModalEdit,
    editData: selectEditData,
    formEdit: selectFormEdit,
    featureFlags: selectFeatureFlags,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem,
    formInfo: selectFormInfo
})

const mapDispatchToProps = dispatch => ({
    setValueEntredBlur: (data) =>
        dispatch(setValueEntredBlur(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormBody);