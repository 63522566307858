import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUser } from "../../redux/user/user.reselect";
import "./account-display.styles.scss";

import { FaUserAlt } from "react-icons/fa";
import { useEffect } from "react";

const AccountDisplay = ({ userFromState }) => {

    
    const user = userFromState ?
        userFromState : JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).user;

    const settings = userFromState ?
        userFromState : JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).settings;

        useEffect(() => {
            console.log("My settings", JSON.parse(localStorage.getItem("user")))
        },[])

    return (
        user &&
        <div className="account-display">
            <div className="account-display__icon-holder">
                <FaUserAlt className="account-display__icon"/>
            </div>
            <div className="account-display__content">
                <span className="account-display__content__username">
                    {user.username}
                </span>
                <span className="account-display__content__email">
                    {user.email}
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    userFromState: selectUser,
});

export default connect(mapStateToProps)(AccountDisplay);
