import React from 'react';

import './add-remove-button.styles.scss';
import ButtonSpinner from '../button-spinner/button-spinner.compoenent';

import IconAdd from '../../assets/icons/add-blue.svg';
import IconRemove from '../../assets/icons/remove-white.svg';

const AddRemoveButton = ({type ,children, handleClick, ...otherProps}) => (
    <button 
        className={`add-remove-button ${type === "remove" ? "add-remove-button__remove" : null}` }  
        {...otherProps} 
        onClick= { handleClick }
    >
        {
            type === "add" ? <img className='add-remove-button__icon' src={ IconAdd } alt="add"/> :
            // <img className='add-remove-button__icon' src={ IconRemove } alt="remove"/>
            null
        }
        { children }
    </button>
);


export default AddRemoveButton;