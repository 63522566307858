import { useState } from "react";
import { withRouter } from "react-router-dom";
import HelpBody from "../../components/help-body/help-body.component";
import HelpHeader from "../../components/help-header/help-header.component";

import { HELP_DATA } from "./help.data";

const Help = ({
    history
}) => {
    const [helpData, setHelpData] = useState(HELP_DATA);

    const handleFilter = (data) => {
        setHelpData(data)
    }

    const handleClose = () => {
        history.goBack();
    }

    return (
        <div className="help">
            <div className="help__header">
                <HelpHeader
                    title={`Help`}
                    handleFilter={handleFilter}
                    handleClose={handleClose}
                    helpData = {HELP_DATA}
                />
            </div>
            <div className="help__body">
                <HelpBody 
                    helpData = {helpData}
                />
            </div>
        </div>
    )
}

export default withRouter(Help);