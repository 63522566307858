import alertActionType from "./alert.types";

export const setAlert = (alertType) => ({
    type: alertActionType.SET_ALERT,
    alertType
});

export const setUUIDForAlert = (uuid) => ({
    type: alertActionType.SET_UUID_FOR_ALERT,
    uuid
});

export const setAlertEndpoint = (endpoint) => ({
    type: alertActionType.SET_ENDPOINT_FOR_ALERT,
    endpoint
});

export const resetAlert = () => ({
    type: alertActionType.RESET_ALERT,
});

