import react, { useEffect } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { logoutUser } from '../../redux/user/user.actions';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { setLoadedData } from '../../redux/list/list.actions';

const Logout = ({ logoutUser, authToken, setLoadedData }) => {
    useEffect(() => {
        logoutUser(authToken);
        setLoadedData({data: null, skip: null, totalNumItems: 0})
    }, [])
   
    return (
        <></>
    )
}

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector
});

const mapDisptachToProps = (dispatch) => ({
    logoutUser: (authToken) => dispatch(logoutUser(authToken)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Logout);