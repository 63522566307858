import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { displayCellValue } from '../../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const ITEM_SHELF_INFO = {
  formTitle: "Product",
  path: "/items",
  subPath: "/itemshelves",
  listPage: "/items",
}

export const ITEM_SHELF_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "item",
      fieldItems: [
        {
          label: "Product Code",
          type: "text",
          name: "itemCode",
          initialFocus: true,
          validationProps: {
            required: "Item code is required"
          },
          disabled: true
        },
        {
          label: "Product Name",
          type: "text",
          name: "itemName",
          initialFocus: true,
          validationProps: {
            required: "Item name is required"
          },
          disabled: true
        }
      ]
    },
    tableForm: {
      sectionType: "Beginning Stock",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: true,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Branch/Shelf"}
                required={false}
              />
            )
          },
          accessor: "shelf",
          width: 100,
          Cell: ({ row }) => {
            const endpointUrl = "/shelves";
            const validationProps = {
              required: "Shelf is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Shelf"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Branch/Shelf"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true, 
      addButton: true,
      position: "LAST",
      breakPoint: 1,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: []
}
