
import { useEffect } from "react";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import "./checkbox-form.styles.scss";

const CheckBoxForm = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    partNumber,
    fieldItemName,
    validationProps,
    fieldIndex,
    defaultValue,
    sectionTypeId,
    disabled,
    setValueEntredBlur,
    ...otherProps
}) => {
    const { register, formState: { errors }, control } = useFormContext();
    const name = fieldName + '.' + partNumber + '.' + fieldItemName;
    // const checkBoxRef = useRef(null);

    // useEffect(() => {
    //     checkBoxRef.current.style.autoFocus = false
    // }, [])

    return (
        <div className="checkbox" >
            <label className="checkbox__label">
                <input
                    className="checkbox__field"
                    type="checkbox"
                    // checked={checked}
                    disabled={disabled}
                    {...register(name, validationProps)}
                    {...otherProps}
   
                />
                <span className="checkbox__text">{label}</span>
            </label>
        </div>
    )
}

export default CheckBoxForm;