import react, { useEffect, useRef, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { authTokenSelector, isLoggedInSelector, selectAccessRights, selectFeatureFlags, selectIsFeatureFlagsLoading } from '../../../redux/user/user.reselect';
import useAccessRight from '../../../effects/use-access-right';
import Spinner from '../../spinner/spinner.component';
import ErrorBox from '../../error-box/error-box.component';

import "./protected-route.styles.scss"

const ACCESS_ENDPOINT = `/accessrights`

const ProtectedRoute = ({
    component: WrappedComponent,
    isLoggedIn,
    isNew,
    appName,
    authToken,
    featureFlags,
    isFeatureFlagsLoading,
    ...otherProps
}) => {
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const hasNoAccess = useRef(false);
    const isFeatureDisalbed = useRef(false);

    const { accessRightData, accessRightError, isAccessRightLoading } = useAccessRight(
        ACCESS_ENDPOINT,
        authToken,
        appName
    )

    useEffect(() => {
        if (accessRightData.length) {
            const accessRight = accessRightData.find((accessRight => {
                return accessRight.application.toLowerCase() === appName && appName.toLowerCase()
            }))

            if (accessRight && (accessRight.permission.toLowerCase() === "no access")) {
                hasNoAccess.current = true;
            }
            setIsAccessRightCheked(true)
        }

    }, [accessRightData])

    useEffect(() => {
        if (featureFlags.length) {
            const featureFlag = featureFlags.find((featureFlag => {
                return featureFlag.feature.toLowerCase() === appName && appName.toLowerCase()
            }))

            if (featureFlag && (featureFlag.flag.toLowerCase() === "disable")) {
                isFeatureDisalbed.current = true;
            } else {
                isFeatureDisalbed.current = false;
            }
        }

    }, [featureFlags])

    const renderdRoute = (hasNoAccess, error) => {
        return (<Route {...otherProps} render={routeProps => (
            error ?
                <div className="protected-route__error">
                    <div className="protected-route__error__box-wrapper">
                        <ErrorBox
                            error={error}
                            icon={true}
                        />
                    </div>

                </div>
                :
                isLoggedIn ? (
                    isFeatureDisalbed.current ? 
                        <Redirect to={{
                            pathname: '/upgrade'
                        }} /> :
                            hasNoAccess ?
                                <Redirect to={{
                                    pathname: '/access-denied'
                                }} />
                                : <WrappedComponent
                                    isNew={isNew}
                                    key={isNew && Date.now()}
                                    {...routeProps}
                                />
                ) : (
                    <Redirect to={{
                        pathname: '/'
                    }} />
                )
        )} />)
    }

    return isAccessRightLoading ?
        <Spinner wide={true} /> :
        (isAccessRightChecked || accessRightError) && renderdRoute(hasNoAccess.current, accessRightError);
};


const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    isLoggedIn: isLoggedInSelector,
    featureFlags: selectFeatureFlags,
    isFeatureFlagsLoading: selectIsFeatureFlagsLoading
})

export default connect(mapStateToProps)(ProtectedRoute);


