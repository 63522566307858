
import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from 'react-redux'
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'
import { setIsModalFetching } from "../redux/modal/modal.actions";
import { setIsFormFetching } from "../redux/form/form.actions";

const useFetchEdit = (
    endpoint,
    authTokenFromState,
    shouldFetch,
    isFullForm,
    isRefresh,
    formSideBarNavActiveItem,
    showReport
) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            if (isFullForm) {
                dispatch(setIsFormFetching(true))
            } else {
                dispatch(setIsModalFetching(true))
            }

            const response = await Axios.get(endpoint, {
                headers: tokenHeader,
            })

            setData(response.data);

            if (isFullForm) {
                dispatch(setIsFormFetching(false))
            } else {
                dispatch(setIsModalFetching(false))
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result you are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result you are looking for, contact your administrator."
                })
            }
            else {
                setError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setData([]);

            if (isFullForm) {
                dispatch(setIsFormFetching(false))
            } else {
                dispatch(setIsModalFetching(false))
            }
        }
    }

    useEffect(() => {
        if (shouldFetch && !showReport) {
            fetchData();
        }
    }, [formSideBarNavActiveItem]);

    useEffect(() => {
      
        if (shouldFetch && isRefresh && !showReport) {
            fetchData();
        }
    }, [isRefresh]);

    return {
        data,
        error
    };
}

export default useFetchEdit;