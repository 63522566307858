export const LOCAL_FILTER_FIELDS = [
    {
        label: "Username",
        type: "text",
        name: "username",
    },
    {
        label: "First Name",
        type: "text",
        name: "firstName",
    },
    {
        label: "Last Name",
        type: "text",
        name: "lastName",
    },
    {
        label: "Phone Number",
        type: "text",
        name: "phoneNumber",
    },
    {
        label: "Email",
        type: "text",
        name: "email",
    },
    {
        label: "Role",
        type: "text",
        name: "role",
    }
]