import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SALES_REPORT_BY_USER_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/salesbyuser",
  listPage: "/sales-reports",
  pagePath:"accounts",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const SALES_REPORT_BY_USER_COLUMN_TEMPLATE = [
  {
    Header: "Username",
    accessor: "username",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Total Sales (ETB)",
    accessor: "totalSales",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Sales: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalSales'
  },
  {
    Header: "Cost of Goods Sold (ETB)",
    accessor: "costOfGoodsSold",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return null;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandcostOfGoodsSold'
  },
  {
    Header: "Gross Profit (ETB)",
    accessor: "grossProfit",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Gross Profit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    }
  },

]
