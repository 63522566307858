import React from 'react';
import FormFieldError from '../form-field-error/form-field-error.componenet';
import { useFormContext } from 'react-hook-form';

import './form-textarea.styles.scss';

const FormTextarea = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    partNumber,
    fieldItemName,
    validationProps,
    ...otherProps }) => {
        const { register, formState: { errors }} = useFormContext();
    const name = fieldName + '.' + partNumber + '.' + fieldItemName;
 
    return (
        <div className='form-textarea'>
            <label
                className="form-textarea__label">
                {label}
                {
                    validationProps ? 
                        validationProps.required ? <span className='form-textarea__label__required'> * </span> :
                        null :
                    null
                }
            </label>
            <textarea
                className={ `form-textarea__input ${errors[fieldName]?.[partNumber]?.[fieldItemName] && 'form-field__input--error'}`} 
                type={type}
                {...register(name , validationProps)}
                autoFocus={initialFocus}
                onChange={handleChange}
                {...otherProps}
            />
             {errors?.[fieldName]?.[partNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[partNumber]?.[fieldItemName] .message} />}
        </div>
    )
}

export default FormTextarea;