import "./home-nav-item.styles.scss";

import IconNavList from '../../assets/icons/nav-list.svg';
import IconRightArrow from '../../assets/icons/right-arrow.svg';
import { Link } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";

const HomeNavItem = ({
    navItem
}) => {
    const Icon = navItem.icon && navItem.icon;
    return (
        <div className="home-nav-item">
            <div className="home-nav-item__header">
                <span className="home-nav-item__header__icon">
                    {Icon && <Icon className="home-nav-item__header__icon__item" />}
                </span>

                <span className="home-nav-item__header__title">
                    {navItem.title}
                </span>
            </div>
            <ul className="home-nav-item__body">
                {
                    navItem.items.map(item => {
                        const IconList = item.icon && item.icon;
                        return (
                            <li className="home-nav-item__body__item">
                                <Link
                                    to={item.page}
                                    className="home-nav-item__body__item__link"
                                >
                                    {IconList && <IconList className="home-nav-item__body__item__link__icon" />}
                                    <div calssName="home-nav-item__body__item__link__text">
                                        <div className="home-nav-item__body__item__link__text__title">
                                            {item.text}
                                        </div>
                                        {
                                            item.description &&
                                            <div className="home-nav-item__body__item__link__text__desc">
                                                {item.description}
                                            </div>
                                        }

                                    </div>

                                    <IoIosArrowForward className="home-nav-item__body__item__link__icon-arrow" />

                                </Link>

                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default HomeNavItem;