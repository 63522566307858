import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectModalTitle, selectShowAddCustomerGroupModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_CUSTOMER_GROUP_FORM_TEMPLATE from './add-customer-group.template.js';
import { setModalTemplate, setModalType, setShowAddCustomerGroupModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddCustomerGroup = ({
    showAddCustomerGroup,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddCustomerGroupModal
}) => {
    useEffect(() => {
        setModalType("ADD_CUSTOMER_GROUP")
        setModalTemplate(ADD_CUSTOMER_GROUP_FORM_TEMPLATE);
    }, []);
    
    return (
        <div className="add_customer_group">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddCustomerGroup}
                        setShowModal={setShowAddCustomerGroupModal}
                        size={"MEDIUM"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddCustomerGroup: selectShowAddCustomerGroupModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddCustomerGroupModal: () =>
        disptach(setShowAddCustomerGroupModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerGroup);