import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const INVENTORY_REPORT_REORDER_BY_ITEM_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/inventoryreorder",
  listPage: "/inventory-reports",
  pagePath:"items",
  hasChart: false,
  tableHasGrandTotal: false,
  hasLocationFilter: true,
  hasSearch: true
}

export const INVENTORY_REPORT_REORDER_BY_ITEM_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Branch",
    accessor: "locationCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Reorder Level",
    accessor: "reorderLevel",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return `Reorder Level: ${value ? displayCellValueNumberToTwoDecimal(value) : ""}`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "Qty on Hand",
    accessor: "qtyOnHand",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
          return displayCellValue(value);
      }

      value.label = value && value.label && isNaN(value.label) ?
          value.label :
          !isMobileView ? formatNumberToTwoDecimal(value.label) : `${formatNumberToTwoDecimal (value.label)} Quantity`


      return (
          <div className={isMobileView && isRightAligned ?
              'list-table__body__row__cell--right-aligned-mobile-cell' : ''}>
              <TableCellStatus
                  value={value}
                  noDot={true}
              />
          </div>
      );
    }
  }
]
