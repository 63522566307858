import UserActionTypes from './user.types';

const INITIAL_STATE = {
    currentUser: {
        authToken: null,
        user: null
    },
    isLoading: false,
    isLoggedIn: false,
    isFail: false,
    messageUser: null,
    accessRights: [],
    featureFlags: [],
    isFeatureFlagsLoading: false
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.LOGIN_START:
            return {
                ...state,
                isLoading: true
            };
        case UserActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                currentUser: action.payload
            };
        case UserActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFail: true,
                currentUser: {
                    authToken: null,
                    user: null
                }
            };
        case UserActionTypes.SET_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: true
            };
        case UserActionTypes.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                currentUser: {
                    authToken: null,
                    user: null
                }
            };
        case UserActionTypes.SET_USER_MESSAGE:
            return {
                ...state,
                messageUser: action.payload
            };
        case UserActionTypes.CLEAR_USER_MESSAGE:
            return {
                ...state,
                messageUser: null
            };
        case UserActionTypes.SET_ACCESS_RIGHT:
            return {
                ...state,
                accessRights: action.payload
            };
        case UserActionTypes.SET_FEATURE_FLAG:
            return {
                ...state,
                featureFlags: action.payload
            };
        case UserActionTypes.SET_IS_FEATURE_FLAG_LOADING:
            return {
                ...state,
                isFeatureFlagsLoading: action.payload
            };
        default:
            return state;
    };
}

export default userReducer;



