import { format } from 'date-fns';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

export const OPERATING_EXPENSE_COLUMNS_DOCUMENT = [
  {
    Header: "No.",
    id: "count",
    Cell: ({ row }) => Number(row.id) + 1,
    width: 50,
  },
  {
    Header: "Payment Method",
    accessor: "paymentMethod",
    width: 100,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Payment Description",
    accessor: "paymentDescription",
    width: 200,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Amount (ETB)",
    accessor: "amount",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  }
]