import documentActionType from "./document.types";

export const setDocumentDropDownItems = (data) => ({
    type: documentActionType.SET_DOCUMENT_DROP_DOWN_ITEMS,
    payload: data
});

export const setBoxNumber = (data) => ({
    type: documentActionType.SET_BOX_NUMBER,
    payload: data
});



