import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { selectReportColumn, selectReportInfo } from "../../redux/report/report.reselect"
import ReportLineChart from "../report-line-chart/report-line-chart.componenet"
import ReportTable from "../report-table/report-table.component"
import "./report-body.styles.scss"
import { useEffect } from "react"
import { selectFeatureFlags } from "../../redux/user/user.reselect"

const ReportBody = ({
    reportColumn,
    endpoint,
    dataKeys,
    reportInfo,
    hasNoDate,
    featureFlags
}) => {
    // useEffect(() => {
    //     if (featureFlags.length && reportColumn) {
    //         let index = 0;
    //         for (const column of reportColumn) {
    //             if (column.columnId) {
    //                 console.log("report column show",column.columnId)
    //                 const featureFlag = featureFlags.find((featureFlag => {
    //                     return featureFlag.feature.toLowerCase() === column.columnId && column.columnId.toLowerCase()
    //                 }))

    //                 if (featureFlag && (featureFlag.flag.toLowerCase() === "disable")) {
    //                     // reportColumn[index] = undefined
    //                 } 
    //             }
    //             index++;
    //         }
    //     }
    // }, [])

    useEffect(() => {
        if (featureFlags.length && reportColumn) {
            const updatedColumns = reportColumn.filter(column => {
                if (!column.columnId) return true;

                const featureFlag = featureFlags.find(featureFlag => 
                    featureFlag.feature.toLowerCase() === column.columnId.toLowerCase()
                );

                return !(featureFlag && featureFlag.flag.toLowerCase() === "disable");
            });

            // Directly updating the reportColumn prop
            reportColumn.length = 0;
            reportColumn.push(...updatedColumns);
        }
    }, [featureFlags, reportColumn]);

    return (
        <div className="report-body">
            <div className="report-body__wrapper report-body__table">
                <ReportTable
                    reportColumn={reportColumn}
                    endpoint={endpoint}
                    hasNoDate={hasNoDate}
                />
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    reportInfo: selectReportInfo,
    featureFlags: selectFeatureFlags,
})

export default connect(mapStateToProps)(ReportBody);
