
import { useState, useEffect, useReducer } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccessRights } from "../redux/user/user.actions";
import { setFeatureFlags, setisfeatureFlagsLoading }from "../redux/user/user.actions";

const useFeatureFlag = (
    endpoint,
    authTokenFromState,
    isLoggedIn,

) => {
    const [featureFlagData, setFeatureFlagData] = useState([]);
    const [featureFlagError, setfeatureFlagError] = useState(null);
    const [isfeatureFlagLoading, setIsfeatureFlagLoading] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch()

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            setIsfeatureFlagLoading(true);
            dispatch(setisfeatureFlagsLoading(true))
            const response = await Axios.get(endpoint, {
                headers: tokenHeader
            })

            dispatch(setFeatureFlags(response.data))
            dispatch(setisfeatureFlagsLoading(false))
            setFeatureFlagData(response.data);
            setIsfeatureFlagLoading(false);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setfeatureFlagError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setfeatureFlagError({
                        name: "system error",
                        message: "Please, try again after zfew moments. If you still can’t get the result you are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setfeatureFlagError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result you are looking for, contact your administrator."
                })
            }
            else {
                setfeatureFlagError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setIsfeatureFlagLoading(false);
            setFeatureFlagData([]);
        }
    }

    useEffect(() => {
        if(isLoggedIn){
            fetchData();
        }
       
    }, [isLoggedIn]);



    return null
}

export default useFeatureFlag;