import "./form-table-header-cell.styles.scss";

const FormTableHeaderCell = ({ text, required }) => {
    return (
        <span className="form-table-header-cell">
            {text} 
            { required && <span className="form-table-header-cell__required"> * </span> } 
        </span>
    )
}

export default FormTableHeaderCell;