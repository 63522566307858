import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/items";

export const TERRITORIES_COLUMNS = [
    {
        Header: "Territory code",
        accessor: "territoryCode",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        }
    },
    {
        Header: "Territory name",
        accessor: "territoryName",
    }
]