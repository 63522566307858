import { useForm, FormProvider } from "react-hook-form";

import "./report-timeframe-select.styles.scss";

import SelectField from "../select-field/select-field.componenet";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setBoxNumber } from "../../redux/document/document.actions";
import SelectFieldSync from "../select-field-sync/select-field-sync.component";
import { useState } from "react";

const ReportTimeFrame = ({
    endpoint,
    authToken,
    setBoxNumber
}) => {
    const [timeFrameRange, setTimeFrameRange] = useState(null);

    const timeFrame = [
        { value: 1, label: "Today" },
        { value: 2, label: "This Week" },
        { value: 3, label: "This Month" },
        { value: 4, label: "This Quarter" },
        { value: 5, label: "This Year" },
        { value: 6, label: "Yesterday" },
        { value: 7, label: "Last Week" },
        { value: 8, label: "Last Month" },
        { value: 9, label: "Last Quarter" },
        { value: 10, label: "Last Year" }
    ]

    const methods = useForm({
        shouldUnregister: true
    });

    const onSelected = (value) => {
        setTimeFrameRange(value)
    }

    useEffect(() => {
        console.log(timeFrameRange)
        //conver the time fram into date range
        
    }, [timeFrameRange])

    return (
        <SelectFieldSync
            options={timeFrame}
            label={false}
            defaultValueIndex={2}
            initialFocus={false}
            isNotArrayField={true}
            name={"timeframe"}
            mutliselect={false}
            disabled={false}
            isSearchable={false}
            inverted={true}
            onSelected={ onSelected }
        />

    )
}

const mapDispatchToProps = (dispatch) => ({
    setBoxNumber: (data) =>
        dispatch(setBoxNumber(data))
})

export default connect(null, mapDispatchToProps)(ReportTimeFrame);
