import { Axios } from "../config";
import { getAuthToken } from "../utils/auth_token.utils";

const formMultipartSubmit = async (formValues, authTokenFromState, url, formEdit) => {
    try {
        const tokenHeader = getAuthToken(authTokenFromState);

        const formData = new FormData();

        // Append each field to FormData
        for (const key in formValues) {
            if (formValues[key] instanceof File) {
                formData.append(key, formValues[key]); // Append files as is
            } else if (typeof formValues[key] === 'object') {
                formData.append(key, JSON.stringify(formValues[key])); // Serialize objects to JSON
            } else {
                formData.append(key, formValues[key]); // Append primitive types (strings, numbers)
            }
        }

        let response;
        if (formEdit) {
            response = await Axios.patch(url, formData, {
                headers: {
                    ...tokenHeader,
                    'Content-Type': 'multipart/form-data', // Ensure correct header for file uploads
                }
            });
        } else {
            response = await Axios.post(url, formData, {
                headers: {
                    ...tokenHeader,
                    'Content-Type': 'multipart/form-data', // Ensure correct header for file uploads
                }
            });
        }

        return {
            status: response.status,
            data: response.data
        };

    } catch (error) {
        if (error.response) {
            if (error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 404
            ) {
                if (error.response.data !== null) {
                    throw {
                        name: "client_error",
                        message: error.response.data
                    }
                }
            } else {
                throw {
                    name: "server_error",
                    message: "System error, please try again after few moments. If you still can’t save, contact your administrator."
                }
            }
        }
        else if (error.request) {
            throw {
                name: "network_error",
                message: "Please, make sure you are connected to the network. If you still can’t save, contact your administrator."
            }
        }
        else {
            throw {
                name: "unknown_error",
                message: "An unknown error occurred, please contact your administrator."
            }
        }
    }
}

export default formMultipartSubmit;