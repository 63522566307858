import { format } from 'date-fns';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/purchaseorders";

export const PURCHASE_RECEIVE_COLUMNS_DOCUMENT = [
  {
    Header: "No.",
    id: "count",
    Cell: ({ row }) => Number(row.id) + 1,
    width: 50,
  },
  {
    Header: "Item Code",
    accessor: "itemCode",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Item Name",
    accessor: "itemName",
    width: 180,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "UoM",
    accessor: "unitOfMeasureCode",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Qty Received",
    accessor: "qtyReceived",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "location",
    accessor: "location",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  }
]