import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const STOCK_COUNT_REPORT_BY_ORDER_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/stockcount",
  listPage: "/stock-count-reports",
  pagePath:"stock-counts",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const STOCK_COUNT_REPORT_BY_ORDER_COLUMN_TEMPLATE = [
  {
    Header: "Count Number",
    accessor: "countNumber",
  },
  {
    Header: "Branch",
    accessor: "location",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return `From: ${displayCellValue(value)}`;
      }

      return displayCellValue(value);
  }
  },
  {
    Header: "Count Date",
    accessor: "countDate",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Status",
    accessor: "stockCountStatus",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
        return displayCellValue(value);
      }

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={value}
          />
        </div>
      )
    }
  },
  {
    Header: "Value",
    accessor: "value",
    Cell: ({ value }) => {
      return formatNumberToTwoDecimal(value);
    }
  },
]
