import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFieldValues, selectModalFormTemplate, selectModalTitle, selectShowAddSupplierModal, selectShowSupplierModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ITEM_MODAL_FORM_TEMPLATE from './item-modal.template.js';
import { setModalFieldValuesFilterd, setModalTemplate, setModalType, setShowAddSupplierModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const ItemModal = ({
    showAddSupplier,
    setModalTemplate,
    setModalType,
    formTemplate,
    modalFieldValues,
    setModalFieldValuesFilterd
}) => {
    useEffect(() => {
        setModalTemplate(ITEM_MODAL_FORM_TEMPLATE);
    }, []);

    useEffect(() => {
        if (modalFieldValues && modalFieldValues.itemCode) {
            const fieldValue = modalFieldValues;

            const itemLabel = `${fieldValue.ItemGroup ? fieldValue.ItemGroup.label + " |" : ""} ${fieldValue.itemCode && fieldValue.itemCode} | ${fieldValue.itemName && fieldValue.itemName}`;

            const newFieldValue = {
                Item: {
                    value: fieldValue.uuid,
                    label: itemLabel
                },
                UnitOfMeasure: fieldValue.UnitOfMeasure,
                qty: "",
                unitPrice: "",
                unitCost: ""
            }

            setModalFieldValuesFilterd(newFieldValue)
        }
    }, [modalFieldValues])

    return (
        <div className="item-modal">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddSupplier}
                        size={"LARGE"}
                    />
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTemplate: selectModalFormTemplate,
    modalFieldValues: selectModalFieldValues
})

const mapDispatchToProps = (disptach) => ({
    setShowAddSupplierModal: () =>
        disptach(setShowAddSupplierModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (data) =>
        disptach(setModalType(data)),
    setModalFieldValuesFilterd: (data) =>
        disptach(setModalFieldValuesFilterd(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemModal);