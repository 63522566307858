import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SALES_REPORT_BY_ITEM_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/salesbyitem",
  listPage: "/sales-reports",
  pagePath:"items",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const SALES_REPORT_BY_ITEM_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Qty Sold",
    accessor: "qtySold",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      const newValue = { value: 3, label: isMobileView ? `${value} Sold` :  `${value}`}

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={newValue}
            noDot={true}
          />
        </div>
      )
    }
  },
  {
    Header: "Total Sales (ETB)",
    accessor: "totalSales",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Sales: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalSales'
  },
  {
    Header: "Cost of Goods Sold (ETB)",
    accessor: "costOfGoodsSold",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return null;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandcostOfGoodsSold'
  },
  {
    Header: "Gross Profit (ETB)",
    accessor: "grossProfit",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Gross Profit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    }
  },

]
