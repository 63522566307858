const ITEM_MODAL_FORM_TEMPLATE = {
  modalTitle: "Add New Supplier",
  path: "/suppliers",
  listPage: "/list-suppliers",
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "basic",
      expandable: false,
      breakPoint: 0,
      position: "LAST",
      fieldName: "supplier",
      fieldItems: [
        {
          label: "Supplier name",
          type: "text",
          name: "supplierName",
          required: true,
          initialFocus: false,
          validationProps: {
            required: "Supplier Name is required"
          }
        },
        {
          label: "Supplier Phone number",
          type: "text",
          name: "supplierPhone",
          required: false,
          initialFocus: false,
        },
        {
          label: "Supplier TIN number",
          type: "text",
          name: "supplierTinNumber",
          required: true,
          initialFocus: false,
    
        },
        {
          label: "Supplier Code",
          type: "text",
          name: "supplierCode",
          initialFocus: false,
        },
      ]
    },
  }
}

export default ITEM_MODAL_FORM_TEMPLATE;