import "./docuemnt-total.styles.scss";
import lodash from 'lodash';
import { formatNumberToTwoDecimal } from "../../utils/format-number.util";

const DocumentTotal = ({
    totalData,
    totalBalance,
    remark
}) => {
    const totalDataKeys = Object.keys(totalData);
    const totalDataLength = totalDataKeys.length;
    console.log("show remakr", remark)
    return (
        <div className="document-total">
            <div className="document-total__remark">
                <div className="document-total__remark__header">
                        {
                           remark && remark.title    
                        }
                </div>
                <div className="document-total__remark__body" style={{ whiteSpace: 'pre-line' }}>
                        {
                           remark && remark.content    
                        }
                </div>
            </div>
            <ul className="document-total__list">
                {
                    totalDataKeys.map((key, index) => {
                        return (
                            <li className={`document-total__list__item ${totalDataLength === (index + 1) && !totalBalance ?
                                "document-total__list__item--main" : null}`
                            }>
                                <span className={`document-total__list__item__title ${totalDataLength === (index + 1)  ?
                                    "document-total__list__item__title--main" : null}`
                                }>
                                    {lodash.startCase(key)}
                                </span>
                                <span className={`document-total__list__item__text ${totalDataLength === (index + 1)  ?
                                    "document-total__list__item__title--main" : null}`
                                }>
                                   { index ? "ETB" : totalBalance ? "ETB" : null }  {formatNumberToTwoDecimal(totalData[key])}
                                </span>
                            </li>
                        )
                    })

                }
            </ul>
        </div>
    )
}

export default DocumentTotal;