import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';

export const MARKETING_DATA_CUSTOMER_INFO = {
  formTitle: "Marketing Data",
  formTitleForNew: "New Marketing Data",
  path: "/marketingdata",
  subPath: "/customers",
  listPage: "/marketing-data"
}

export const MARKETING_DATA_CUSTOMER_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "marketingData",
      fieldItems: [
        {
          label: "MD Code",
          type: "text",
          name: "mdCode",
          initialFocus: false,
          disabled: true,
          defaultValue: `MD-`
        },
        {
          label: "Sales Rep",
          type: "select",
          name: "salesRep",
          initialFocus: false,
          muliselect: false,
          url: "/salesreps",
          disabled: true,
          validationProps: {
            required: "Sales Rep is required"
          }
        },
        {
          label: "Territory",
          type: "select",
          name: "territory",
          initialFocus: false,
          muliselect: false,
          url: "/territories",
          disabled: true,
          validationProps: {
            required: "Territory is required"
          }
        },
        {
          label: "Date",
          type: "date",
          name: "collectDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "Collect date is required"
          }
        },
      ]
    },
    tableForm: {
      sectionType: "Customer Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Customer"}
                required={true}
              />
            )
          },
          accessor: "customer",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/customersindirect";
            const validationProps = {
              required: "Customer is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Customer"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Customer"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
              createNew={{text:"Add New Customer", modalName: "customer"}}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Provider"}
                required={true}
              />
            )
          },
          accessor: "provider",
          width: 170,
          Cell: ({ row }) => {
            const endpointUrl = "/providers";
            const validationProps = {
              required: "Provider is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"provider"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              disabled={row.isDisable}
              headerName={"provider"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Visit Status"}
                required={true}
              />
            )
          },
          accessor: "VisitStatus",
          width: 100,
          Cell: ({ row }) => {
            const endpointUrl = "/visitstatuses";
            const validationProps = {
              required: "Visit Status Date is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"VisitStatus"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Visit Status"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Last Visit Date"}
                required={true}
              />
            )
          },
          accessor: "LastVisit",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/lastVisitDates";
            const validationProps = {
              required: "Last Visit Date is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"LastVisit"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
          
              disabled={row.isDisable}
              headerName={"Last Visit Date"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Activity"}
                required={true}
              />
            )
          },
          accessor: "Activity",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/marketingdataactivities";
         
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Activity"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              disabled={row.isDisable}
              headerName={"Activity"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
  },
  watchFields: []
}
