import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setIsItemHistory, setLoadedData, setLocationFilter, setPaymentMethod, setPaymentStatus, setReportColumn, setReportDateRange, setReportInfo, setReportSideBarNav, setReportSideBarNavActiveItem, setReportType } from "../../redux/report/report.actions";
import { selectReportSideBarNavActiveItem, selectReportType } from "../../redux/report/report.reselect";
import { setTabTitle } from '../../utils/tab-title.utils';

import { SALES_REPORT_BY_PROIFT_INFO, SALES_REPORT_BY_PROIFT_COLUMN_TEMPLATE } from './sales-report-by-profit.template';
import { SALES_REPORT_BY_REVENUE_INFO, SALES_REPORT_BY_REVENUE_COLUMN_TEMPLATE } from './sales-report-by-revenue.template';
import { SALES_REPORT_BY_PAYMENT_INFO, SALES_REPORT_BY_PAYMENT_COLUMN_TEMPLATE } from './sales-report-by-payment.template';
import { SALES_REPORT_PAYMENT_DETAILS_INFO, SALES_REPORT_PAYMENT_DETAILS_COLUMN_TEMPLATE } from './sales-report-payment-details.template';
import { SALES_REPORT_BY_ITEM_INFO, SALES_REPORT_BY_ITEM_COLUMN_TEMPLATE } from './sales-report-by-item.template';
import { SALES_REPORT_BY_COST_OF_SALES_INFO, SALES_REPORT_BY_COST_OF_SALES_COLUMN_TEMPLATE } from './sales-report-by-cost-of-sales.template.js';
import { SALES_REPORT_BY_CUSTOMER_INFO, SALES_REPORT_BY_CUSTOMER_COLUMN_TEMPLATE } from './sales-report-by-customer.template';
import { SALES_REPORT_BY_CUSTOMER_BALANCE_INFO, SALES_REPORT_BY_CUSTOMER_BALANCE_COLUMN_TEMPLATE } from './sales-report-by-customer-balance.template';
import { SALES_REPORT_BY_USER_INFO, SALES_REPORT_BY_USER_COLUMN_TEMPLATE } from './sales-report-by-user.template';
import { SALES_REPORT_BY_QUOTE_ITEM_INFO, SALES_REPORT_BY_QUOTE_ITEM_COLUMN_TEMPLATE } from './sales-report-by-quote-item.template';
import { SALES_REPORT_BY_MARKETING_DATA_INFO, SALES_REPORT_BY_MARKETING_DATA_COLUMN_TEMPLATE } from './sales-report-by-marketing-data.template.js';
import {SALES_REPORT_BY_SALES_TARGET_INFO, SALES_REPORT_BY_SALES_TARGET_COLUMN_TEMPLATE} from './sales-report-by-sales-target.template.js'
import {SALES_REPORT_BY_COMPARISON_INSIGHTS_DATA_INFO, SALES_REPORT_BY_COMPARISON_INSIGHTS_COLUMN_TEMPLATE} from './sales-report-by-comparison-insights.template'


import Report from "../../components/report/report.component";
import { setFilterEmpty } from "../../redux/filter/filter.actions";

const TYPE = {
    SALES_REPORT: "SALES_REPORT",
}

const SUB_ITEMS = [
    {
        text: "Sales by Revenue",
        info: SALES_REPORT_BY_REVENUE_INFO,
        reportColumn: SALES_REPORT_BY_REVENUE_COLUMN_TEMPLATE
    },
    {
        text: "Sales by Profit",
        info: SALES_REPORT_BY_PROIFT_INFO,
        reportColumn: SALES_REPORT_BY_PROIFT_COLUMN_TEMPLATE
    },
    {
        text: "Sales by Payment",
        info: SALES_REPORT_BY_PAYMENT_INFO,
        reportColumn: SALES_REPORT_BY_PAYMENT_COLUMN_TEMPLATE
    },
    {
        text: "Payment Details",
        info: SALES_REPORT_PAYMENT_DETAILS_INFO,
        reportColumn: SALES_REPORT_PAYMENT_DETAILS_COLUMN_TEMPLATE
    },
    {
        text: "Sales by Product",
        info: SALES_REPORT_BY_ITEM_INFO,
        reportColumn: SALES_REPORT_BY_ITEM_COLUMN_TEMPLATE
    },
    {
        text: "Sales by Customer",
        info: SALES_REPORT_BY_CUSTOMER_INFO,
        reportColumn: SALES_REPORT_BY_CUSTOMER_COLUMN_TEMPLATE
    },
    {
        text: "Customer Balance",
        info: SALES_REPORT_BY_CUSTOMER_BALANCE_INFO,
        reportColumn: SALES_REPORT_BY_CUSTOMER_BALANCE_COLUMN_TEMPLATE
    },
    {
        text: "Cost of Sales",
        info: SALES_REPORT_BY_COST_OF_SALES_INFO,
        reportColumn: SALES_REPORT_BY_COST_OF_SALES_COLUMN_TEMPLATE
    },
    {
        text: "Sales by User",
        info: SALES_REPORT_BY_USER_INFO,
        reportColumn: SALES_REPORT_BY_USER_COLUMN_TEMPLATE
    },
    {
        text: "Quote by Product",
        info: SALES_REPORT_BY_QUOTE_ITEM_INFO,
        reportColumn: SALES_REPORT_BY_QUOTE_ITEM_COLUMN_TEMPLATE
    },
    {
        text: "Sales Targets",
        info: SALES_REPORT_BY_SALES_TARGET_INFO,
        reportColumn: SALES_REPORT_BY_SALES_TARGET_COLUMN_TEMPLATE
    },
    {
        text: "Marketing Data",
        info: SALES_REPORT_BY_MARKETING_DATA_INFO,
        reportColumn: SALES_REPORT_BY_MARKETING_DATA_COLUMN_TEMPLATE
    },   
]

const dataKeys = ["totalSales", "grossProfit"]

const SalesReport = ({
    reportType,
    setReportType,
    setReportColumn,
    setReportSideBarNav,
    setReportSideBarNavActiveItem,
    reportSideBarNavActiveItem,
    setReportInfo,
    setReportDateRange,
    setLoadedData,
    setPaymentMethod,
    setPaymentStatus,
    setLocationFilter,
    setFilterEmpty,
    setIsItemHistory,
    match
}) => {
    const [confrimLoadedData, setConfirmLoadedData] = useState(false);
    const [isFristRender, setIsFristRender] = useState(true);
    const endpoint = reportType === TYPE.SALES_REPORT ? `${SUB_ITEMS[reportSideBarNavActiveItem].info.path}${SUB_ITEMS[reportSideBarNavActiveItem].info.subPath}` :
        `${SUB_ITEMS[0].info.path}${SUB_ITEMS[0].info.subPath}`

    useEffect(() => {
        if (reportType === TYPE.SALES_REPORT) {
            setReportInfo(SUB_ITEMS[reportSideBarNavActiveItem].info);
            setReportSideBarNav(SUB_ITEMS);
        } else {
            setReportInfo(SUB_ITEMS[0].info);
            setReportSideBarNav(SUB_ITEMS);
        }
    }, [reportSideBarNavActiveItem]);

    useEffect(() => {
        setIsFristRender(false);
    }, [])

    useEffect(() => {
        setTabTitle(`Sales Report`)
    }, [])

    useEffect(() => {
        setIsItemHistory(false)
    }, [])

    useEffect(() => {
        if (reportType !== TYPE.SALES_REPORT) {
            setLoadedData({
                data: null,
                skip: null,
                totalNumItems: 0
            })

            setLocationFilter(null);
            setPaymentMethod(null);
            setPaymentStatus(null);
            setReportDateRange({})
            setReportSideBarNavActiveItem(0)
            setFilterEmpty();
        }

        setReportType(TYPE.SALES_REPORT);
        setConfirmLoadedData(true);
    }, [])

    useEffect(() => {
        return () => {
            setReportInfo(null);
        }
    }, [])

    return (
        <>
            {!isFristRender && confrimLoadedData &&
                <Report
                    reportColumn={SUB_ITEMS[reportSideBarNavActiveItem].reportColumn}
                    endpoint={endpoint}
                    dataKeys={dataKeys}
                />
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem,
    reportType: selectReportType
})

const mapDispatchToProps = (disptach) => ({
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setReportColumn: (data) =>
        disptach(setReportColumn(data)),
    setReportSideBarNav: (data) =>
        disptach(setReportSideBarNav(data)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
    setReportDateRange: (data) =>
        disptach(setReportDateRange(data)),
    setReportType: (data) =>
        disptach(setReportType(data)),
    setLoadedData: (data) =>
        disptach(setLoadedData(data)),
    setPaymentMethod: (data) =>
        disptach(setPaymentMethod(data)),
    setPaymentStatus: (data) =>
        disptach(setPaymentStatus(data)),
    setLocationFilter: (data) =>
        disptach(setLocationFilter(data)),
    setFilterEmpty: (data) =>
        disptach(setFilterEmpty(data)),
    setIsItemHistory: (data) =>
        disptach(setIsItemHistory(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesReport));