import React from 'react';
import ReactDOM from 'react-dom';

import "./alert-box.styles.scss";

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSSTransition } from 'react-transition-group';
import { setIsFail, setModalFromValidationError, setShowSupplierModal, submitModalForm } from '../../redux/modal/modal.actions';
import { selectIsSaved, selectModalEdit, selectModalFormTemplate, selectModalFormValue, selectShowSupplierModal, selectWindowHeight } from '../../redux/modal/modal.reselect';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { useForm, FormProvider } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import CustomButton from '../custom-button/custom-button.componenet';
import { selectAlertEndpoint, selectAlertIsOn, selectAlertType, selectAlertUUID } from '../../redux/alert/alert.reselect';
import { resetAlert, setAlert, setAlertEndpoint, setUUIDForAlert } from '../../redux/alert/alert.actions';
import { deleteItems, setDeletedItems } from "../../redux/delete/delete.actions";
import { selectDeleteIsLoading } from '../../redux/delete/delete.reselect';
import { selectSelectedRowsUUID } from '../../redux/list/list.reselect';
import ButtonSpinner from '../button-spinner/button-spinner.compoenent';
import { useEffect } from 'react';

const AlertBox = ({
    isOn,
    setAlert,
    deleteItems,
    alertUUID,
    deleteIsLoading,
    alertType,
    authToken,
    alertEndpoint,
    resetAlert,
    setDeletedItems
}) => {
    let isLoading = deleteIsLoading;
    const handleCloseAlert = () => {
        setAlert(alertType)
    }

    const handleConifrm = async (event) => {
        try {
            let response;
            switch (alertType) {
                case "DELETE_ITEMS":
                    response = await deleteItems(
                        { uuid: [alertUUID] },
                        authToken,
                        alertEndpoint
                    );
                    break;
                default:
                    break;
            }

            if (response == 200 || response == 201) {
                toast.success('Item Successfully Deleted.');
                setDeletedItems(alertUUID);
                resetAlert();
            }
        } catch (error) {
            toast.error(error.message);
            resetAlert();
        }
    }

    return (
        ReactDOM.createPortal(
            <>
                <CSSTransition
                    in={isOn}
                    timeout={300}
                    classNames="alert-box"
                    unmountOnExit
                >
                    <div className="alert-box">
                        <div className="alert-box__dialog">
                            <div className="alert-box__dialog__header">
                                Confirm
                            </div>
                            <div className="alert-box__dialog__body">
                                Delete 1 item/s permanently?
                            </div>
                            <div className="alert-box__dialog__footer">
                                <div className='alert-box__dialog__footer__spinner'>
                                    {
                                        isLoading &&
                                        <ButtonSpinner />
                                    }
                                </div>
                                <CustomButton
                                    inverted={true}
                                    handleClick={(event) => { handleCloseAlert(event) }}
                                >
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    isLoading={isLoading}
                                    handleClick={(event) => { handleConifrm(event) }}
                                >
                                    Confirm
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                {/* <ToastContainer
                    hideProgressBar={true}
                /> */}
            </>
            , document.getElementById('app-alert')
        )
    )
}

const mapStateToProps = createStructuredSelector({
    isOn: selectAlertIsOn,
    alertUUID: selectAlertUUID,
    alertType: selectAlertType,
    deleteIsLoading: selectDeleteIsLoading,
    authToken: authTokenSelector,
    alertEndpoint: selectAlertEndpoint,
    selectSelectedRowsUUID: selectSelectedRowsUUID
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (alertType) =>
        dispatch(setAlert(alertType)),
    deleteItems: (uuid, authToken, endpoint) =>
        dispatch(deleteItems(uuid, authToken, endpoint)),
    resetAlert: () =>
        dispatch(resetAlert()),
    setDeletedItems: (items) =>
        dispatch(setDeletedItems(items)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertBox);


