import "./title-header.styles.scss";

import IconHamburger from "../../assets/icons/hamburgerGray.svg";

const TitleHeader = ({
    children,
    hamburger,
    handleSideNav
}) => {
    return (
        <h3 className="title-header">
            {
                hamburger && <button className="title-header__button" onClick={handleSideNav}>
                    <img
                        src={IconHamburger}
                        className="title-header__icon"
                    />
                </button>
            }
            <div className="title-header__text">
                {children}
            </div>
        </h3>
    )
}

export default TitleHeader;