import React, { useEffect } from 'react';

import './modal-body.styles.scss';
import ModalBodySection from '../modal-body-section/modal-body-section.componenet';
import ModalError from '../modal-error/modal-error.componenet';
import { createStructuredSelector } from 'reselect';
import { selectEditData, selectIsFail, selectModalEdit, selectModalFromErrors, selectModalIsFetching } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import Spinner from '../spinner/spinner.component';
import { setIsModalFetching } from '../../redux/modal/modal.actions';
import ModalBodyEditSection from '../modal-body-edit-section/modal-body-edit-section.compoenent';

const ModalBody = ({
    bodySections,
    formErrors,
    focusFieldName,
    isFail,
    isFetching,
    modalEdit,
    editData
}) => {

    const getEditSectionData = (editData, sectionId) => {
       return editData.find(data => {
            return (
                data.sectionId === sectionId ? data : null
            )
        })
    }

    return (
        <div className="general-modal__body">
            {
                formErrors ?
                    <ModalError formValidationError={true} /> :
                    isFail ? <ModalError formResponseError={true} /> :
                        null
            }
            {
                isFetching ?
                    <div className="general-modal__body__spinner">
                        <Spinner />
                    </div> :
                    Object.keys(bodySections).map(key => {
                        if (modalEdit) {
                            const editSectionData = getEditSectionData(editData, bodySections[key].sectionTypeId)
                            return (
                                <ModalBodyEditSection
                                    key={key}
                                    focusFieldName={focusFieldName}
                                    bodySection={bodySections[key]}
                                    editSectionData={editSectionData}
                                />
                            )
                        }
                        
                        return (
                            <ModalBodySection
                                key={key}
                                focusFieldName={focusFieldName}
                                bodySection={bodySections[key]}
                            />)
                    })
            }
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    formErrors: selectModalFromErrors,
    isFail: selectIsFail,
    isFetching: selectModalIsFetching,
    modalEdit: selectModalEdit,
    editData: selectEditData
})



export default connect(mapStateToProps)(ModalBody);