export const LOCAL_FILTER_FIELDS = [
    {
        label: "Branch Code",
        type: "text",
        name: "locationCode",
    },
    {
        label: "Branch Name",
        type: "text",
        name: "locationName",
    }
]