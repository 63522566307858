import { format, startOfWeek, endOfWeek, subWeeks } from "date-fns";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { connect } from "react-redux";
import { setReportDateRange } from "../../redux/report/report.actions";

import { getToday, getThisMonth } from "../../utils/date.utils";
import CustomButton from "../custom-button/custom-button.componenet";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import ReportDateRangeButton from "../report-date-range-button/report-date-range-button.compoenent";
import "./date-range-holder.styles.scss";
import { selectReportDateRange } from "../../redux/report/report.reselect";
import { createStructuredSelector } from "reselect";

const DateRangeHolder = ({
    setReportDateRange,
    reportDateRange
}) => {
    const today = getToday();
    const thisMonth = getThisMonth()
    const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: Object.keys(reportDateRange).length ? new Date(reportDateRange.startDate) : today.startDate,
            endDate: Object.keys(reportDateRange).length ? new Date(reportDateRange.endDate) : today.endDate,
            key: 'selection'
        }
    ]);

    const dateRangePickerRef = useRef(null);
    const dateRangeButtonRef = useRef(null);

    const handleSelect = () => {
        const startDate = format(selectionRange[0].startDate, "yyyy-MM-dd");
        const endDate = format(selectionRange[0].endDate, "yyyy-MM-dd");
        setReportDateRange({
            startDate,
            endDate
        })
        setIsDateRangeOpen(false)
    }

    const handleOnClickOutside = (event) => {
        if (dateRangePickerRef.current &&
            !dateRangeButtonRef.current.contains(event.target) &&
            !dateRangePickerRef.current.contains(event.target)
        ) {
            setIsDateRangeOpen(false)
        }
    }

    const customStaticRanges = [
        {
            label: 'Today',
            range: () => ({
                startDate: today.startDate,
                endDate: today.endDate,
            }),
            isSelected(range) {
                const now = new Date();
                const startDate = today.startDate;
                const endDate = today.endDate;
                return range.startDate.getTime() === startDate.getTime() && range.endDate.getTime() === endDate.getTime();
            },
        },
        {
            label: 'Yesterday',
            range: () => ({
                startDate: new Date(today.startDate.getTime() - 24 * 60 * 60 * 1000),
                endDate: new Date(today.endDate.getTime() - 24 * 60 * 60 * 1000),
            }),
            isSelected(range) {
                const yesterdayStart = new Date(today.startDate.getTime() - 24 * 60 * 60 * 1000);
                const yesterdayEnd = new Date(today.endDate.getTime() - 24 * 60 * 60 * 1000);
                return (
                    range.startDate.getTime() === yesterdayStart.getTime() && range.endDate.getTime() === yesterdayEnd.getTime()
                );
            },
        },
        {
            label: 'This Week',
            range: () => ({
                startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
                endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
            }),
            isSelected(range) {
                const now = new Date();
                const startDate = startOfWeek(now, { weekStartsOn: 1 });
                const endDate = endOfWeek(now, { weekStartsOn: 1 });
                return range.startDate.getTime() === startDate.getTime() && range.endDate.getTime() === endDate.getTime();
            },
        },
        {
            label: 'Last Week',
            range: () => ({
                startDate: startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
                endDate: endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
            }),
            isSelected(range) {
                const now = new Date();
                const startDate = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
                const endDate = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
                return range.startDate.getTime() === startDate.getTime() && range.endDate.getTime() === endDate.getTime();
            },
        },
        {
            label: 'This Month',
            range: () => ({
                startDate: thisMonth.startDate,
                endDate: thisMonth.endDate,
            }),
            isSelected(range) {
                const startDate = thisMonth.startDate;
                const endDate = thisMonth.endDate;
                return range.startDate.getTime() === startDate.getTime() && range.endDate.getTime() === endDate.getTime();
            },
        },
        {
            label: 'Last Month',
            range: () => {
                const today = new Date();
                const lastMonthFirstDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastMonthLastDate = new Date(today.getFullYear(), today.getMonth(), 0);
                return {
                    startDate: lastMonthFirstDate,
                    endDate: lastMonthLastDate,
                };
            },
            isSelected(range) {
                const today = new Date();
                const lastMonthFirstDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastMonthLastDate = new Date(today.getFullYear(), today.getMonth(), 0);
                return (
                    range.startDate.getTime() === lastMonthFirstDate.getTime() && range.endDate.getTime() === lastMonthLastDate.getTime()
                );
            },
        },
        {
            label: 'This 3 Months',
            range: () => ({
                startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
                endDate: new Date(),
            }),
            isSelected(range) {
                const now = new Date();
                const startDate = new Date(now.getFullYear(), now.getMonth() - 2, 1);
                const endDate = new Date();
                return range.startDate.getTime() === startDate.getTime() && range.endDate.getTime() === endDate.getTime();
            },
        },
        {
            label: 'Last 3 Months',
            range: () => ({
                startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1),
                endDate: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 0),
            }),
            isSelected(range) {
                const now = new Date();
                const startDate = new Date(now.getFullYear(), now.getMonth() - 5, 1);
                const endDate = new Date(now.getFullYear(), now.getMonth() - 2, 0);
                return range.startDate.getTime() === startDate.getTime() && range.endDate.getTime() === endDate.getTime();
            },
        },
        // Include other default static ranges here if needed
    ];

    useEffect(() => {
        window.addEventListener("click", handleOnClickOutside);

        return () => {
            window.removeEventListener("click", handleOnClickOutside);
        }
    }, []);

    return (
        <>
            <div className="date-range-button" ref={dateRangeButtonRef}>
                <ReportDateRangeButton
                    DropDownButton={DropDownButton}
                    handleClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
                    inverted={true}
                />
            </div>
            <div className="date-range-picker-wrapper" ref={dateRangePickerRef}>
                {isDateRangeOpen &&
                    <div className="date-range-picker">
                        <DateRangePicker
                            onChange={item => setSelectionRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={selectionRange}
                            direction="horizontal"
                            weekStartsOn={1}
                            staticRanges={customStaticRanges}
                            inputRanges={[]}

                        />
                        <div className="date-range-picker-actions">
                            <CustomButton
                                inverted={false}
                                handleClick={handleSelect}
                            >
                                Apply
                            </CustomButton>
                            <CustomButton
                                inverted={true}
                                handleClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
                            >
                                Cancle
                            </CustomButton>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    reportDateRange: selectReportDateRange,
});

const mapDispatchToProps = (dispatch) => ({
    setReportDateRange: (exportData) =>
        dispatch(setReportDateRange(exportData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeHolder)