import React, { useRef } from "react";

import { useEffect } from "react";
import { useState } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import DocumentHeader from "../../../components/document-header/document-header.componenet";
import DocumentBody from "../../../components/document-body/document-body.componenet";
import { SALES_QUOTES_COLUMNS_DOCUMENT } from "./sales-quotes-columns-document.data";
import useFetchDocument from "../../../effects/use-fetch-document";
import { withRouter } from "react-router-dom";
import { authTokenSelector, selectSettings } from "../../../redux/user/user.reselect";
import { selectIsRefresh } from "../../../redux/refresh/refresh.reselect";

const SalesQuoteDocument = ({
    authTokenFromState,
    match,
    settings,
    isRefresh
}) => {
    const contentRef = useRef(null);
    const title = "Sales Quote"
    const path = "/salesquotes";

    const endpoint = `${path}/doucments/${match.params.uuid}`;
    const { data, error, isLoading } = useFetchDocument(
        endpoint,
        authTokenFromState,
        isRefresh
    );

    const [dataIsReady, setDataIsReady] = useState(false);
    const [dataForDoucment, setDataForDoucment] = useState(null);

    useEffect(() => {
        setDataForDoucment({
            header: [
                data.company,
                data.salesQuoteSummary
            ],
            body: {
                sections: [
                    data.bankAccountSummary,
                    data.customerWithBillingAddressSummary,
                    data.customerWithShippingAddressSummary,
                   
                ],
                table: data.items,
                total: data.total,
                remark: data.remarkSummary
            }
        })
        setDataIsReady(true)
    }, [data]);

    const [salesQuoteColumnsDoc, setSalesQuoteColumnsDoc] = useState(SALES_QUOTES_COLUMNS_DOCUMENT);
    const [isSalesQuoteColumnDocSet, setIsSalesQuoteColumnDocSet] = useState(false);

    useEffect(() => {
        if (!settings.length && isSalesQuoteColumnDocSet === false) {    
        
            if (Object.keys(SALES_QUOTES_COLUMNS_DOCUMENT).length) {
                SALES_QUOTES_COLUMNS_DOCUMENT.splice(4, 1);
            }
    
            setSalesQuoteColumnsDoc(SALES_QUOTES_COLUMNS_DOCUMENT);
            setIsSalesQuoteColumnDocSet(true);
        } else {
            if (Object.keys(SALES_QUOTES_COLUMNS_DOCUMENT).length) {
                SALES_QUOTES_COLUMNS_DOCUMENT.splice(3, 1);
            }
    
            setSalesQuoteColumnsDoc(SALES_QUOTES_COLUMNS_DOCUMENT);
            setIsSalesQuoteColumnDocSet(true);
        }
    }, [salesQuoteColumnsDoc]);

    return (
        <>{dataIsReady && salesQuoteColumnsDoc &&
            <>
                <DocumentHeader
                    title={title}
                    contentRef={contentRef}
                />
                <DocumentBody
                    title={title}
                    endpoint={endpoint}
                    contentRef={contentRef}
                    data={dataForDoucment}
                    tableColumns={salesQuoteColumnsDoc}
                    tableData={data.items}
                    error={error}
                    isLoading={isLoading}
                />
            </>
        }

        </>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    isRefresh: selectIsRefresh,
    settings: selectSettings
});

export default withRouter(connect(mapStateToProps, null)(SalesQuoteDocument));
