import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const MARKETING_DATA_INFO = {
  formTitle: "Marketing Data",
  formTitleForNew: "New Marketing Data",
  path: "/marketingdata",
  listPage: "/marketing-data"
}

export const MARKETING_DATA_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "marketingData",
      fieldItems: [
        {
          label: "MD Code",
          type: "text",
          name: "mdCode",
          initialFocus: false,
          disabled: true,
          defaultValue: `MD-`
        },
        {
          label: "Sales Rep",
          type: "select",
          name: "salesRep",
          initialFocus: false,
          muliselect: false,
          url: "/salesreps",
          validationProps: {
            required: "Sales Rep is required"
          }
        },
        {
          label: "Territory",
          type: "select",
          name: "territory",
          initialFocus: false,
          muliselect: false,
          url: "/territories",
          validationProps: {
            required: "Territory is required"
          }
        },
        {
          label: "Route",
          type: "text",
          name: "route",
          initialFocus: false,
          required: false,
        },
        {
          label: "Date",
          type: "date",
          name: "collectDate",
          initialFocus: false,
          validationProps: {
            required: "Collect date is required"
          }
        },
        {
          label: "Remark",
          type: "text",
          name: "description",
          initialFocus: false,
          required: false,
        },
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History details",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true,
      addButton: true,
      position: "LAST",
      breakPoint: 2,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Approved By",
          type: "text",
          name: "approvedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: [
    "stockTransfer.0.fromLocation",
  ]
}
