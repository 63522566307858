import './spinner.styles.scss';
import SpinnerImg from '../../assets/icons/spinner.svg'

const Spinner = ({
    position,
    size,
    wide
}) => (
    <span className={`spinner 
        ${position === "left" ?
            'spinner--left' :
            position === "center" ?
                'spinner--center' :
                position === "right" ?
                    'spinner--right' :
                    null}
        ${wide && "spinner--wide"}
                    `}>
        <img className={`spinner__img 
            ${size === "small" ?
                'spinner__img--small' :
                size === "medium" ?
                    'spinner__img--medium' :
                    size === "large" ?
                        'spinner__img--large' :
                        null
        }`}
            src={SpinnerImg} alt="spinner"
        />
    </span>
)

export default Spinner;