import ErrorBox from "../../components/error-box/error-box.component";
import "./upgrade.styles.scss";

const ERROR = {
    name: "Upgrade",
    message: "Upgrade to use this feature. Please contact your representative to arrange the upgrade."
}

const Upgrade = () => {
    return (
        <div className="upgrade">
            <div className="upgrade__box">
                <ErrorBox
                    error={ERROR}
                    icon={true}
                />
            </div>
        </div>
    )
}

export default Upgrade;