import {createSelector} from 'reselect'

const documentSelector = (state) => state.document; 

export const selectDocumentDropDownItems = createSelector(
    [documentSelector],
    document => document.documentDropDownItems
);

export const selectBoxNumber = createSelector(
    [documentSelector],
    document => document.boxNumber
);