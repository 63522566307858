import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const LOCATION_INFO = {
  formTitle: "Branch",
  formTitleForNew: "New Branch",
  path: "/locations",
  listPage: "/locations",
}

export const LOCATION_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "location",
      fieldItems: [
        {
          label: "Branch code",
          type: "text",
          name: "locationCode",
          initialFocus: false,
          validationProps: {
            required: "Location code is required"
          }
        },
        {
          label: "Branch name",
          type: "text",
          name: "locationName",
          initialFocus: false,
        }
      ]
    },
    tableForm: {
      sectionType: "Shelf Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [ 
       {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Shelf/Bin Name"}
                required={false}
              />
            )
          },
          accessor: "shelfName",
          width: 40,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"shelfName"}
              rowNumber={row.id}
              valueType={"text"}
              validationProps={false}
              align="left"
              disabled={row.isDisable}
              headerName={"shelfName"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    addressDetails: {
      sectionType: "address details",
      sectionTypeId: "addressDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 5,
      position: "LAST",
      fieldName: "locationAddress",
      fieldItems: [
        {
          label: "Street",
          type: "text",
          name: "street",
          required: false,
          initialFocus: false
        },
        {
          label: "City",
          type: "text",
          name: "city",
          required: false,
          initialFocus: false
        },
        {
          label: "State",
          type: "text",
          name: "state",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Zip code",
          type: "text",
          name: "zipCode",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Country",
          type: "text",
          name: "country",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone no 1",
          type: "text",
          size: "small",
          name: "phoneNumber1",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone no 2",
          type: "text",
          size: "small",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "Email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on payment address details',
            }
          }
        },
        {
          label: "Website",
          type: "text",
          name: "website",
          required: false,
          initialFocus: false
        }
      ]
    },
  },
  watchFields: []
}
