import refreshActionType from "./refresh.types"

const INITIAL_STATE = {
    isRefresh: false,
}
const refreshReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case refreshActionType.SET_REFRESH:
            return {
                ...state,
                isRefresh: action.payload
            }
        default:
            return state;
    }
}

export default refreshReducer;