import React, { useEffect, useRef, useState } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { RM_ESTIMATION_INFO, RM_ESTIMATION_FORM_TEMPLATE } from './rm-estimation.template';

import { COMPANY_DOCUMENT_DROP_DOWN_ITEMS } from './production-estimation-documents-nav.template';

import FormContainer from '../../../components/form/form.container';
import { selectFormSideBarNavActiveItem, selectFormTableLatestData, selectFormTableLatestRows, selectFormTableRows, selectFormTemplate, selectSelectedField } from '../../../redux/form/form.reselect';
import { setAutoFillDataForm, setEditDataForm, setFormChangedValues, setFormEdit, setFormInfo, setFormProgress, setFormSideBarNav, setFormSideBarNavActiveItem, setFormTableData, setFormTableLatestData, setFormTableLatestRows, setFormTemplate, setFormTotalValues, setFormType, setSelectedFieldValue } from '../../../redux/form/form.actions';
import { withRouter } from 'react-router-dom';
import useFetchEdit from '../../../effects/use-fetch-edit.effect';
import { authTokenSelector, selectAccessRights } from '../../../redux/user/user.reselect';
import { selectIsRefresh } from '../../../redux/refresh/refresh.reselect';
import { setDocumentDropDownItems } from '../../../redux/document/document.actions';
import useFetchFieldValues from '../../../effects/use-fetch-field-values';
import useAccessRight from '../../../effects/use-access-right';
import { disableFormFields } from '../../../utils/form-values.utils';
import useFetchAutoFill from '../../../effects/use-fetch-autofill';

const APP_NAME = `production-estimation`;

const SUB_ITEMS = [
    {
        text: "RM Estimation",
        info: RM_ESTIMATION_INFO,
        formTemplate: RM_ESTIMATION_FORM_TEMPLATE
    }
]

const ProductionEstimation = ({
    match,
    setFormInfo,
    setFormTemplate,
    setFormEdit,
    setFormTableData,
    setFormTableLatestRows,
    formTableLatestRows,
    setFormTotalValues,
    setFormTableLatestData,
    formLatestData,
    authToken,
    setFormChangedValues,
    setEditDataForm,
    isNew,
    isRefresh,
    selectedField,
    setSelectedFieldValue,
    setDocumentDropDownItems,
    setFormSideBarNav,
    setFormSideBarNavActiveItem,
    formSideBarNavActiveItem,
    setFormProgress,
    setAutoFillDataForm,
    accessRights
}) => {
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isViewOnly = useRef(false);

    const endpoint =
        `${SUB_ITEMS[formSideBarNavActiveItem].info.path}`

    const authTokenFromState = authToken;
    const { data, error } = useFetchEdit(
        endpoint,
        authTokenFromState,
        !isNew,
        true,
        isRefresh,
        formSideBarNavActiveItem
    );

    const [isAutoFill, setIsAutoFill] = useState(false);
    const [selectedRM, setSelectedRM] = useState(null);

    const autoFillUrl = `/rmestimations/autofill`;
    const { autoFillData, autoFillError, isAutoFillLoading } = useFetchAutoFill(
        autoFillUrl,
        authTokenFromState,
        setIsAutoFill,
        isAutoFill,
        selectedRM
    )

    console.log("selectedRM", selectedRM);

    const fieldEndpoint = `/itemvalues`;
    const { fieldData, fieldError } = useFetchFieldValues(
        fieldEndpoint,
        authTokenFromState,
        selectedField,
        isNew
    )

    const TABLE_DATA = [{
        id: 0
    }];

    useEffect(() => {
        if (selectedField) {
            const selectedFieldValue = {
                name: selectedField.name,
                value: fieldData
            }

            setSelectedFieldValue(selectedFieldValue)
        }
    }, [fieldData])

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === APP_NAME && APP_NAME.toLowerCase()
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "view only")) {
            isViewOnly.current = true;
        }
        setIsAccessRightCheked(true)
    }, [])

    useEffect(() => {
        setFormInfo(SUB_ITEMS[formSideBarNavActiveItem].info);

        const formTemplate = JSON.parse(JSON.stringify(SUB_ITEMS[formSideBarNavActiveItem].formTemplate));

        if (isViewOnly.current && Object.keys(formTemplate).length) {
            const bodySections = formTemplate.bodySections;
            disableFormFields(bodySections)
        }

        setFormTemplate(formTemplate);
        setDocumentDropDownItems(COMPANY_DOCUMENT_DROP_DOWN_ITEMS);
        setFormTableData(TABLE_DATA);

        if (!isNew) {
            setFormSideBarNav(SUB_ITEMS);
            setFormEdit(true);
        }
    }, [formSideBarNavActiveItem, isViewOnly]);

    useEffect(() => {
        setEditDataForm(data);
    }, [data]);


    useEffect(() => {
        if (Object.keys(formLatestData).length && formLatestData.Item) {
            setSelectedRM(formLatestData.Item);
            setIsAutoFill(true)
        }
    }, [formLatestData.Item])

    useEffect(() => {
        console.log("i am autofill ", autoFillData)
        if(autoFillData && !Array.isArray(autoFillData)){
            setAutoFillDataForm({ data: autoFillData.results, total: autoFillData.total })
        } else {
            setAutoFillDataForm({ data: [] })
        }
    }, [autoFillData])

    useEffect(() => {
        return () => {
            setFormInfo(null);
            setFormTemplate(null);
            setDocumentDropDownItems(null);
            setFormTableData(null);
            setFormEdit(false)
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setAutoFillDataForm(null);
            setEditDataForm([]);
            setSelectedFieldValue(null);
            setFormSideBarNav([]);
            setFormSideBarNavActiveItem(0);
        }
    }, [])

    return (
        <div>
            {isAccessRightChecked &&
                Object.keys(RM_ESTIMATION_FORM_TEMPLATE).length > 0 ?
                <FormContainer
                    endpoint={endpoint}
                    isViewOnly={isViewOnly.current}
                    isNoDeactivate={true}
                />
                : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatestRows: selectFormTableLatestRows,
    formLatestData: selectFormTableLatestData,
    authToken: authTokenSelector,
    isRefresh: selectIsRefresh,
    selectedField: selectSelectedField,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem,
    accessRights: selectAccessRights
})

const mapDispatchToProps = (disptach) => ({
    setFormInfo: (formInfo) =>
        disptach(setFormInfo(formInfo)),
    setFormTemplate: (formTemplate) =>
        disptach(setFormTemplate(formTemplate)),
    setDocumentDropDownItems: (dropDownItems) =>
        disptach(setDocumentDropDownItems(dropDownItems)),
    setFormType: (modalType) =>
        disptach(setFormType(modalType)),
    setFormTableData: (tableData) =>
        disptach(setFormTableData(tableData)),
    setFormTotalValues: (tableData) =>
        disptach(setFormTotalValues(tableData)),
    setFormChangedValues: (data) =>
        disptach(setFormChangedValues(data)),
    setFormEdit: (data) =>
        disptach(setFormEdit(data)),
    setEditDataForm: (data) =>
        disptach(setEditDataForm(data)),
    setFormTableLatestRows: (data) =>
        disptach(setFormTableLatestRows(data)),
    setFormTableLatestData: (data) =>
        disptach(setFormTableLatestData(data)),
    setSelectedFieldValue: (data) =>
        disptach(setSelectedFieldValue(data)),
    setFormSideBarNav: (data) =>
        disptach(setFormSideBarNav(data)),
    setFormSideBarNavActiveItem: (data) =>
        disptach(setFormSideBarNavActiveItem(data)),
    setFormProgress: (data) =>
        disptach(setFormProgress(data)),
    setAutoFillDataForm: (data) =>
        disptach(setAutoFillDataForm(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductionEstimation));