import navigationActionsTypes from "./navigation.types";

const INITAL_STATE = {
    supplierHidden: true,
    burgerHidden: true,
    windowWidth: null
}

const navigationReducer = (state = INITAL_STATE, action) => {
    switch(action.type){
        case navigationActionsTypes.SET_SUPPLIER_NAV:
            return {
                ...state,
                //here set each nav items hidden to true
                supplierHidden: !state.supplierHidden 
            }
        case navigationActionsTypes.RESET_NAV:
            return {
                ...state,
                burgerHidden: true,
                supplierHidden: true,
            }
        case navigationActionsTypes.SET_BURGER_MENU:
            return {
                ...state,
                burgerHidden: !state.burgerHidden
            }
        case navigationActionsTypes.SET_WINDOW_WIDTH:
            return {
                ...state,
                windowWidth: action.payload
            }
        default:
            return state;
    }
}

export default navigationReducer;