import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { createStructuredSelector } from "reselect";
import fetchSelect from "../../effects/fetch-select";
import useFetchDefault from "../../effects/use-fetch-default";
import { setValueEntredBlur } from "../../redux/form/form.actions";
import { selectFormEdit, selectSelectedField } from "../../redux/form/form.reselect";
import FormFieldError from "../form-field-error/form-field-error.componenet";

import './select-field-sync.styles.scss';

const SelectFieldSync = ({
    options,
    label,
    type,
    name,
    handleChange,
    initialFocus,
    mutliselect,
    onChange,
    required,
    disabled,
    inverted,
    validationProps,
    isSearchable,
    defaultValueIndex,
    defaultValue,
    onSelected,
    ...otherProps
}) => {
    const customStyles = {
        control: (styles) => ({
            ...styles,
            fontFamily: '"Roboto", sans-serif',
            border: '1px solid #c2c7cc',
            height: '33.5px',
            minHeight: '33.5px',
            backgroundColor: disabled ? '#FFFFFF' : inverted ? '#FFF' : '#FFFFFF',
            color: disabled ? '#888777' : '#212529',
            textIndent: '10px',
            borderRadius: '5px',
            fontSize: '14px',
            boxShadow: "none",
            textTransform: "capitalize",
            pointerEvents: "auto",
            "&:focus-within": {
                borderColor: "#3084B7"
            },
            "&:hover": {
                cursor: disabled ? "not-allowed" : "pointer"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            color: "red",
            fontSize: "13px",
            backgroundColor: state.isSelected ? "#F4F5F6" : "",
            borderRadius: "7px",
            marginTop: "3px",
            textTransform: "capitalize",
            color: state.isSelected ? "#111" : null,
            "&:hover": {
                backgroundColor: "#F4F5F6",
            },
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.7 : 1;
            const color = state.isDisabled ? '#000' : "";
            const transition = 'opacity 300ms';
            return {
                ...provided,
                opacity,
                transition,
                color,
            };
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const { control, register, formState: { errors }, setValue } = useFormContext();

    return (
        <div className='select-field-sync'>
            {
                label &&
                <label
                    className="select-field-sync__label">
                    {label}
                    {
                        validationProps ?
                            validationProps.required ? <span className="select-field-sync__label__required"> * </span> :
                                null :
                            null
                    }
                </label>
            }
            <Select
                styles={customStyles}
                className={`select-field-sync__select`}
                options={options}
                autocomplete={true}
                defaultValue={defaultValue}
            />
            {/* <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                // defaultValue={defaultData}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                }) => {
                    return (
                        <Select
                            // className={`select-field-sync__select`}
                            // styles={customStyles}
                            // defaultOptions={true}
                            // cacheOptions={true}
                            // value={value}
                            // onChange={(value) => {
                            //     onChange(value);
                            //     onSelected(value)
                            // }}
                            // options={options}
                            // isMulti={mutliselect}
                            // menuPlacement="auto"
                            // menuPortalTarget={document.body}
                            // isDisabled={disabled}
                            // isSearchable={isSearchable}
                            // {...otherProps}
                        />
                    )
                }}
            /> */}
        </div >
    )
};

export default SelectFieldSync;
