import React, { useEffect } from 'react';

import './form-table-external-field.styles.scss';

import IconExpand from '../../assets/icons/expand-arrow-modal.svg';
import FormTextarea from '../form-textarea/form-textarea.componenet';
import SelectField from '../select-field/select-field.componenet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { selectModalEdit, selectModalFormValue } from '../../redux/modal/modal.reselect';
import {
    setIsFormFetching, setFormExpandSection
} from '../../redux/form/form.actions';

import FormField from '../form-field/form-field.component';
import AddRemoveButton from '../add-remove-button/add-remove-button.componenet';
import { authTokenSelector } from '../../redux/user/user.reselect';
import DatePickerField from '../date-picker-field/date-picker-field.component';
import { selectEditData, selectFormChangedValues, selectFormEdit, selectFormExpandSection, selectFormTotalValues } from '../../redux/form/form.reselect';
import { useState } from 'react';
import { selectIsRefresh } from '../../redux/refresh/refresh.reselect';
import { setRefresh } from '../../redux/refresh/refresh.actions';
import { method } from 'lodash';
import CheckBox from '../checkbox/checkbox.component';
import CheckBoxForm from '../checkbox-form/checkbox-form.component';
import SelectFieldSync from '../select-field-sync/select-field-sync.component';

const FormTableExternalField = ({
    setModalFieldValue,
    setIsFormFetching,
    authToken,
    bodySection: {
        sectionType,
        sectionTypeId,
        fieldName,
        externalFields,
    },
    editSectionData,
    formEdit
}) => {
    const { setValue, reset } = useFormContext();

    const { fields: externalFieldsList, append, remove } = useFieldArray({
        name: `${fieldName}-${sectionTypeId}`
    });

    let partNumber = null;
    const part = "part";
    const handleChange = (event, partNumber) => {
        setModalFieldValue(
            sectionTypeId,
            part + (partNumber),
            event.target.name,
            event.target.value
        )
    }
    const handleSelectChange = (value, action, partNumber) => {
        setModalFieldValue(
            sectionTypeId, part + (partNumber),
            action.name,
            value
        )
    }

    const renderForm = (field, partNumber) => {
        switch (field.type) {
            case 'text':
            case 'email':
            case 'password':
                return (
                    <FormField
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.isNoEditValidation && formEdit ? {} : field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        defaultValue={field.defaultValue}
                        disabled={field.disabled}
                    />)
            case 'textarea':
                return (
                    <FormTextarea
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        disabled={field.disabled}
                    />
                )
            case 'select':
                return (
                    <SelectField
                        label={field.label}
                        type={field.type}
                        required={field.required}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        authToken={authToken}
                        endpointUrl={field.url}
                        mutliselect={field.muliselect}
                        hasDefaultValue={field.hasDefaultValue}
                        defaultValueUrl={field.defaultValueUrl}
                        defaultValue={field.defaultValue}
                        disabled={field.disabled}
                    />
                )
            case 'select-sync':
                return (
                    <SelectFieldSync
                        label={field.label}
                        type={field.type}
                        required={field.required}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        authToken={authToken}
                        endpointUrl={field.url}
                        mutliselect={field.muliselect}
                        hasDefaultValue={field.hasDefaultValue}
                        defaultValueUrl={field.defaultValueUrl}
                        defaultValue={field.defaultValue}
                        disabled={field.disabled}
                        options={field.options}
                    />
                )
            case 'date':
                return (
                    <DatePickerField
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        defaultValue={field.defaultValue}
                        initalValueEmpty={field.initalValueEmpty}
                        disabled={field.disabled}
                       
                    />)
            case 'checkbox':
                return (
                    <CheckBoxForm
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        disabled={field.disabled}
                    />)
            default:
                break;
        }
    }

    const [subSectionCount, setSubSectionCount] = useState(0)
    useEffect(() => {
        if (formEdit && editSectionData &&
            (subSectionCount < (editSectionData.length))) {
            append({ ...editSectionData[subSectionCount] }, {
                shouldFocus: true,
            });
            return setSubSectionCount(subSectionCount + 1)
        }
    }, [subSectionCount])


    useEffect(() => {
        setIsFormFetching(false)
    }, [])

    useEffect(() => {
        return () => {
            reset({});
        }
    }, [])

    return (
        <div className="form-table-external-field">
            {
                externalFieldsList.map((field, index) => {
                    partNumber = index;
                    const fieldLength = externalFields.length;
                    return (
                        <div key={field.id} >

                            <div className="container-fluid">
                                <div className="row form-table-external-field__body">
                                    <div className={`col-md-12`}>
                                        <div className="row">
                                            {externalFields.map((fieldItem, fieldIndex) => (
                                                <div
                                                    className={`form-table-external-field__body__item  ${fieldItem.size === 'small' ?
                                                        'col-12  col-md-12' :
                                                        'col-md-12'
                                                        }`}
                                                    key={fieldItem.name}
                                                >
                                                    {renderForm(fieldItem, partNumber)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>)
                })
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    modalFormValues: selectModalFormValue,
    authToken: authTokenSelector,
    formTotalValues: selectFormTotalValues,
    formChangedValues: selectFormChangedValues,
    formEdit: selectFormEdit,
    isRefresh: selectIsRefresh
});

const mapDispatchToProps = (dispatch) => ({
    setIsFormFetching: (isFetching) =>
        dispatch(setIsFormFetching(isFetching)),
    setRefresh: (isFetching) =>
        dispatch(setRefresh(isFetching)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormTableExternalField);