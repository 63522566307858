const EDIT_CUSTOMER_FORM_TEMPLATE ={
    modalTitle: "Edit Customer",
    path: "/customers",
    listPage: "/list-customers",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "BASIC_DETAILS",
        expandable: false,
        breakPoint: 0,
        fieldName: "customer",
        fieldItems: [
          {
            label: "Customer Code",
            type: "text",
            name: "customerCode",
            initialFocus: true,
            validationProps: {
              required: "Customer Code is required"
            }
          },
          {
            label: "Customer name",
            type: "text",
            name: "customerName",
            required: true,
            initialFocus: false,
            validationProps: {
              required: "Customer Name is required"
            }
          },
          {
            label: "Customer TIN number",
            type: "text",
            name: "customerTin",
            required: true,
            initialFocus: false,
            validationProps: {
              required: "Customer Tin is required"
            }
          },
          {
            label: "Customer Group",
            type: "select",
            name: "CustomerGroups",
            required: false,
            initialFocus: false,
            muliselect:true,
            url: "/customergroups"
          }
        ]
      },
      contactDetails: {
        sectionType: "contact details",
        sectionTypeId: "CONTACT_DETAILS",
        multiForm: true,
        mutliFormTitle: "Contact",
        expandable: true,
        addButton: true,
        breakPoint: 3,
        fieldName: "customerContact",
        fieldItems: [
          {
            label: "contact name",
            type: "text",
            name: "contactName",
            required: false,
            initialFocus: false
          },
          {
            label: "phone no 1",
            type: "text",
            name: "phoneNumber1",
            required: false,
            initialFocus: false,
            break: true
          },
          {
            label: "phone no 2",
            type: "text",
            name: "phoneNumber2",
            required: false,
            initialFocus: false
          },
          {
            label: "email",
            type: "text",
            name: "email",
            required: false,
            initialFocus: false,
            validationProps: {
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Valid email required on contact details',
              }
            }
          }
        ]
      },
      addressDetails: {
        sectionType: "address details",
        sectionTypeId: "ADDRESS_DETAILS",
        multiForm: true,
        mutliFormTitle: "address",
        expandable: true,
        addButton: true,
        breakPoint: 5,
        fieldName: "customerAddress",
        fieldItems: [
          {
            label: "street",
            type: "textarea",
            name: "street",
            required: false,
            initialFocus: false
          },
          {
            label: "city",
            type: "text",
            name: "city",
            required: false,
            initialFocus: false
          },
          {
            label: "state",
            type: "text",
            name: "state",
            size: "small",
            required: false,
            initialFocus: false
          },
          {
            label: "zip code",
            type: "text",
            name: "zipCode",
            size: "small",
            required: false,
            initialFocus: false
          },
          {
            label: "country",
            type: "text",
            name: "country",
            required: false,
            initialFocus: false
          },
          {
            label: "phone no 1",
            type: "text",
            size: "small",
            name: "phoneNumber1",
            required: false,
            initialFocus: false
          },
          {
            label: "phone no 2",
            type: "text",
            size: "small",
            name: "phoneNumber2",
            required: false,
            initialFocus: false
          },
          {
            label: "email",
            type: "text",
            name: "email",
            required: false,
            initialFocus: false,
            validationProps: {
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Valid email required on payment address details',
              }
            }
          },
          {
            label: "website",
            type: "text",
            name: "website",
            required: false,
            initialFocus: false
          }
        ]
      }
    }
}

export default EDIT_CUSTOMER_FORM_TEMPLATE;