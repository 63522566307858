import "./home-body.styles.scss";

import IconSales from "../../assets/icons/increasing.svg"
import IconCost from "../../assets/icons/expense.svg"
import IconProfit from "../../assets/icons/profit.svg"
import { FaChartColumn } from "react-icons/fa6";
import { BsCashStack } from "react-icons/bs";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa";

import HomeNavCard from "../home-nav-card/home-nav-card.component";
import HomeNav from "../home-nav/home-nav.component";
import { getThisWeek } from "../../utils/date.utils";
import { getToday, getThisMonth } from "../../utils/date.utils";
import { useState } from "react";
import { format } from "date-fns";
import useFetchReport from "../../effects/use-fetch-report.effect";
import { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { authTokenSelector } from "../../redux/user/user.reselect";
import { selectReportDateRange } from "../../redux/report/report.reselect";
import { setReportDateRange, setReportSummaryData } from "../../redux/report/report.actions";
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';

const HomeBody = ({
    navItems1,
    authToken,
    reportDateRange,
    setReportSummaryData,
    isReportNoAccess
}) => {
    const endpoint = "/salesreports/salesbyprofit"

    const today = getToday();
    const thisMonth = getThisMonth();
    const [defaultDateRange, setDefaultDateRange] = useState({
        startDate: format(thisMonth.startDate, "yyyy-MM-dd"),
        endDate: format(thisMonth.endDate, "yyyy-MM-dd"),
    })

    const { data, dataExport, summaryData, totalData, numItems, error, isLoading } = useFetchReport(
        endpoint,
        authToken,
        null,
        0,
        0,
        Object.keys(reportDateRange).length ? reportDateRange : defaultDateRange,
        false,
        null,
        null
    );

    const isMobileView = useMediaQuery({ maxWidth: 991 });

    useEffect(() => {
        if (summaryData) {
            setReportSummaryData(summaryData)
        }
    }, [summaryData])

    return (
        <div className="home-body">
            <HomeNav navItems={navItems1} />
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    reportDateRange: selectReportDateRange,
});

const mapDispatchToProps = (dispatch) => ({
    setReportDateRange: (data) =>
        dispatch(setReportDateRange(data)),
    setReportSummaryData: (data) =>
        dispatch(setReportSummaryData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeBody);