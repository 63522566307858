import "./table-cell-status.styles.scss";

const TableCellStatus = ({ value, noDot }) => {
    return (
        <span className={`table-cell-status ${value.value === 1 ?
            "table-cell-status--danger" : value.value === 2 ?
                "table-cell-status--warning" : value.value === 3 ? "table-cell-status--success" : ""
            }`}>
                   {!noDot && <></>} {value.label}
            {/* {!noDot && <>&#x2022;</>} {value.label} */}
        </span>
    )
}

export default TableCellStatus;