import { createSelector } from 'reselect'

const listSelector = (state) => state.list;

export const selectListType = createSelector(
    [listSelector],
    list => list.listType
);

export const selectSelectedRowsUUID = createSelector(
    [listSelector],
    list => list.selectedRowsUUID
);

export const selectLoadedData = createSelector(
    [listSelector],
    list => list.data
);

export const selectLoadedSkip = createSelector(
    [listSelector],
    list => list.skip
);

export const selectScrollPosition = createSelector(
    [listSelector],
    list => list.scrollPosition
);

export const selectTotalNumItems = createSelector(
    [listSelector],
    list => list.totalNumItems
);

export const selectEditedItem = createSelector(
    [listSelector],
    list => list.editedItem
);

export const selectPagePath = createSelector(
    [listSelector],
    list => list.pagePath
);

export const selectDeactivateCheck = createSelector(
    [listSelector],
    list => list.deactivateCheck
);



