import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import IconPrint from "../../assets/icons/print.svg";
import { selectExportData } from "../../redux/export/export.reselect";
import { printData } from "../../utils/export-print-data-to-pdf.util";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';


import jsPDF from "jspdf";
import { useRef } from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider } from 'react-redux';
import { store} from '../../redux/store';
import { withRouter } from "react-router-dom";


const DetailPrintView = ({
    DropDownButton,
    dataPrint,
    contentRef,
    history
}) => {
    const cloneRef = useRef(true);
    const testRef = useRef(null)
    const handleClick = () => {
        history.push("/detail-print")
    }

    return (
        <>
            <DropDownContainer
                DropDownButton={DropDownButton}
                icon={IconPrint}
                size="medium"
                dropDwon={false}
                handleClick={handleClick}
            />
            {/* <div ref={testRef} style={{width: '100px', border: '1px solid coral'}}>
                <table border="1">
                    <tr>
                        <td>Name</td>
                        <td>Sex</td>
                    </tr>
                    <tr>
                        <td>Nati</td>
                        <td>Male</td>
                    </tr>
                </table>
            </div> */}

        </>
    )
}


const mapStateToProps = createStructuredSelector({
    dataPrint: selectExportData,
});

export default withRouter(connect(mapStateToProps)(DetailPrintView))