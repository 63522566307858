import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const TERRITORY_INFO = {
  formTitle: "Territory",
  formTitleForNew: "New Territory",
  path: "/territories",
  listPage: "/territories",
}

export const TERRITORY_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "territory",
      fieldItems: [
        {
          label: "Territory code",
          type: "text",
          name: "territoryCode",
          initialFocus: false,
          validationProps: {
            required: "Territory code is required"
          }
        },
        {
          label: "Territory name",
          type: "text",
          name: "territoryName",
          initialFocus: false,
        }
      ]
    }
  },
  watchFields: []
}
