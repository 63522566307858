import { modalActionTypes } from "./modal.types";
import formSubmit from "../../services/form-submit.service";

export const setShowAddSupplierModal = () => ({
    type: modalActionTypes.SHOW_ADD_SUPPLIER_MODAL
});

export const setShowEditSupplierModal = () => ({
    type: modalActionTypes.SHOW_EDIT_SUPPLIER_MODAL
});

export const setShowAddSupplierGroupModal = () => ({
    type: modalActionTypes.SHOW_ADD_SUPPLIER_GROUP_MODAL
});

export const setShowEditSupplierGroupModal = () => ({
    type: modalActionTypes.SHOW_EDIT_SUPPLIER_GROUP_MODAL
});

export const setShowAddItemGroupModal = () => ({
    type: modalActionTypes.SHOW_ADD_ITEM_GROUP_MODAL
});

export const setShowEditItemGroupModal = () => ({
    type: modalActionTypes.SHOW_EDIT_ITEM_GROUP_MODAL
});

export const setShowAddItemTaxModal = () => ({
    type: modalActionTypes.SHOW_ADD_ITEM_TAX_MODAL
});

export const setShowEditItemTaxModal = () => ({
    type: modalActionTypes.SHOW_EDIT_ITEM_TAX_MODAL
});

export const setShowAddUnitOfMeasureModal = () => ({
    type: modalActionTypes.SHOW_ADD_UNIT_OF_MEASURE_MODAL
});

export const setShowEditUnitOfMeasureModal = () => ({
    type: modalActionTypes.SHOW_EDIT_UNIT_OF_MEASURE_MODAL
});

export const setShowAddItemModal = () => ({
    type: modalActionTypes.SHOW_ADD_ITEM_MODAL
});

export const setShowEditItemModal = () => ({
    type: modalActionTypes.SHOW_EDIT_ITEM_MODAL
});

export const setShowAddCustomerGroupModal = () => ({
    type: modalActionTypes.SHOW_ADD_CUSTOMER_GROUP_MODAL
});

export const setShowEditCustomerGroupModal = () => ({
    type: modalActionTypes.SHOW_EDIT_CUSTOMER_GROUP_MODAL
});

export const setShowAddLocationModal = () => ({
    type: modalActionTypes.SHOW_ADD_LOCATION_MODAL
});

export const setShowEditLocationModal = () => ({
    type: modalActionTypes.SHOW_EDIT_LOCATION_MODAL
});

export const setShowAddCustomerModal = () => ({
    type: modalActionTypes.SHOW_ADD_CUSTOMER_MODAL
});

export const setShowEditCustomerModal = () => ({
    type: modalActionTypes.SHOW_EDIT_CUSTOMER_MODAL
});

export const setShowAddPurchaseOrderModal = () => ({
    type: modalActionTypes.SHOW_ADD_PURCHASE_ORDER_MODAL
});

export const setShowModal = (modalType) => {
    return async dispatch => {
        try {
            // switch (modalType) {
            //     case "ADD_SUPPLIER":
            //         return dispatch(setShowAddSupplierModal());
            //     case "ADD_SUPPLIER_GROUP":
            //         return dispatch(setShowAddSupplierGroupModal());
            //     case "ADD_ITEM_GROUP":
            //         return dispatch(setShowAddItemGroupModal());
            //     case "ADD_ITEM_TAX":
            //         return dispatch(setShowAddItemTaxModal());
            //     case "ADD_UNIT_OF_MEASURE":
            //         return dispatch(setShowAddUnitOfMeasureModal());
            //     case "ADD_ITEM":
            //         return dispatch(setShowAddItemModal());
            //     default:
            //         return null
            // }
        } catch (error) {
            throw new Error();
        }
    }
}

export const setShowEdit = (listType) => {
    return async dispatch => {
        try {
            // switch (listType) {
            //     case "LIST_SUPPLIER":
            //         return dispatch(setShowEditSupplierModal());
            //     case "LIST_SUPPLIER_GROUP":
            //         return dispatch(setShowEditSupplierGroupModal());
            //     case "LIST_ITEM_GROUP":
            //         return dispatch(setShowEditItemGroupModal());
            //     case "LIST_ITEM_TAX":
            //         return dispatch(setShowEditItemTaxModal());
            //     case "LIST_UNIT_OF_MEASURE":
            //         return dispatch(setShowEditUnitOfMeasureModal());
            //     case "LIST_ITEM":
            //         return dispatch(setShowEditItemModal());
            //     case "LIST_CUSTOMER_GROUP":
            //         return dispatch(setShowEditCustomerGroupModal());
            //     case "LIST_LOCATION":
            //         return dispatch(setShowEditLocationModal());
            //     case "LIST_CUSTOMER":
            //         return dispatch(setShowEditCustomerModal());
            //     default:
            //         return null
            // }
        } catch (error) {
            throw new Error();
        }
    }
}

export const setEditUUID = (uuid) => ({
    type: modalActionTypes.SET_EDIT_UUID,
    payload: uuid
});

export const setEditData = (data) => ({
    type: modalActionTypes.SET_EDIT_DATA,
    payload: data
});

export const setIsModalFetching = (isFetching) => ({
    type: modalActionTypes.SET_IS_FETCHING,
    payload: isFetching
});

export const setModalExpandSection = (sectionTypeId) => ({
    type: modalActionTypes.SET_MODAL_EXPAND_SECTION,
    sectionTypeId: sectionTypeId
});

export const setModalType = (modalType) => ({
    type: modalActionTypes.SET_MODAL_TYPE,
    payload: modalType
})

export const setModalTemplate = (formTemplate) => ({
    type: modalActionTypes.SET_MODAL_FORM_TEMPLATE,
    payload: formTemplate
})

export const addMorePart = (sectionTypeId, addedField) => ({
    type: modalActionTypes.ADD_MORE_PART,
    sectionTypeId: sectionTypeId,
    payload: addedField
})

export const removePart = (sectionTypeId, partNumber) => ({
    type: modalActionTypes.REMOVE_PART,
    sectionTypeId: sectionTypeId,
    part: partNumber
})


const submitModalFormStart = () => ({
    type: modalActionTypes.SUBMIT_MODAL_FORM_START
})

const submitModalFormSuccess = () => ({
    type: modalActionTypes.SUBMIT_MODAL_FORM_SUCCESS
})

const submitModalFormFailure = (errorMessage) => ({
    type: modalActionTypes.SUBMIT_MODAL_FORM_FAILURE,
    payload: errorMessage
})

export const submitModalForm = (formValue, authTokenFromState, url, modalEdit) => {
    return async dispatch => {
        try {
            dispatch(submitModalFormStart());
            const response = await formSubmit(formValue, authTokenFromState, url, modalEdit);
            dispatch(submitModalFormSuccess());

            return response;
        } catch (error) {
            dispatch(submitModalFormFailure(error.message))
            throw new Error();
        }
    }
}

export const setIsFail = (error) => ({
    type: modalActionTypes.SET_IS_FAIL,
    payload: error
})

export const setModalFromValidationError = (errors) => ({
    type: modalActionTypes.SET_MODAL_FORM_VALIDATION_ERROR,
    payload: errors
})

export const setWindowHeight = (height) => ({
    type: modalActionTypes.SET_WINDOW_HEIGHT,
    payload: height
});

export const setModalShow = (data) => ({
    type: modalActionTypes.SET_MODAL_SHOW,
    payload: data
})

export const setModalFieldValues= (data) => ({
    type: modalActionTypes.SET_MODAL_FIELD_VALUES,
    payload: data
})

export const setModalFieldValuesFilterd= (data) => ({
    type: modalActionTypes.SET_MODAL_FIELD_VALUES_FILTERD,
    payload: data
})

export const setModalTriggerName= (data) => ({
    type: modalActionTypes.SET_MODAL_TRIGGER_NAME,
    payload: data
})
