import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { displayCellValue } from '../../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const CUSTOMER_PAYMENT_INFO = {
  formTitle: "Customer",
  path: "/customers",
  subPath: "/beginningcreditpayments",
  listPage: "/customers",
}

export const CUSTOMER_PAYMENT_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "customer",
      fieldItems: [
        {
          label: "Customer name",
          type: "text",
          name: "customerName",
          required: true,
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "Customer Name is required"
          }
        },
        {
          label: "Beginning Credit (ETB)",
          type: "text",
          name: "beginningCredit",
          initialFocus: false,
          disabled: false
        },
        {
          label: "Total Credit (ETB)",
          type: "text",
          name: "totalCredit",
          initialFocus: false,
          disabled: true
        },
      ]
    },
    tableForm: {
      sectionType: "Beginning Credit Payments",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      showTotal: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      isCurrency: true,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Date"}
                required={true}
              />
            )
          },
          accessor: "paymentDate",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Picked date is required"
            }
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableDatePicker
              fieldName={"items"}
              fieldItemName={"paymentDate"}
              rowNumber={row.id}
              value={row.value}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Date"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Method"}
                required={true}
              />
            )
          },
          accessor: "paymentMethod",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/paymentmethods";
            const validationProps = {
              required: "Payment method is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"paymentMethod"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={false}
              defaultValue={{ value: 1, label: "Cash" }}
              headerName={"Method"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Remark"}
                required={false}
              />
            )
          },
          accessor: "remark",
          width: 110,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"remark"}
              rowNumber={row.id}
              validationProps={false}
              align="left"
              headerName={"Remark"}
              disabled={row.isDisable}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Applied By"}
                required={false}
              />
            )
          },
          accessor: "appliedBy",
          width: 70,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"appliedBy"}
              rowNumber={row.id}
              validationProps={false}
              disabled={true}
              align="left"
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Amount (ETB)"}
                required={true}
              />
            )
          },
          accessor: "amountApplied",
          width: 100,
          Cell: ({ row }) => {
            // const validationProps = {
            //   required: "Amount applied is required"
            // }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"amountApplied"}
              rowNumber={row.id}
              valueType={"number"}
              align="right"
              // validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Amount (ETB)"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
  },
  watchFields: []
}
