import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const CUSTOMER_INFO = {
  formTitle: "Customer",
  formTitleForNew: "New Customer",
  path: "/customers",
  listPage: "/customers",
}

export const CUSTOMER_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "customer",
      fieldItems: [
        {
          label: "Customer name",
          type: "text",
          name: "customerName",
          required: true,
          initialFocus: false,
          validationProps: {
            required: "Customer Name is required"
          }
        },
        {
          label: "Customer TIN number",
          type: "text",
          name: "customerTin",
          required: false,
          initialFocus: false,
        },
        {
          label: "Customer Phone number",
          type: "text",
          name: "customerPhone",
          required: false,
          initialFocus: false,
        },
        {
          label: "Customer Code",
          type: "text",
          name: "customerCode",
          initialFocus: false,
        },
        {
          label: "Customer Type",
          type: "select",
          name: "CustomerType",
          initialFocus: false,
          muliselect: false,
          url: "/customertypes",
          defaultValue: { label: 'Direct Customer', value: 1 },
        },
      ]
    },
    contactDetails: {
      sectionType: "contact",
      sectionTypeId: "contactDetails",
      multiForm: true,
      mutliFormTitle: "Contact",
      expandable: true,
      addButton: true,
      breakPoint: 3,
      fieldName: "customerContact",
      fieldItems: [
        {
          label: "Contact Name",
          type: "text",
          name: "contactName",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone Number 1",
          type: "text",
          name: "phoneNumber1",
          required: false,
          initialFocus: false,
          break: true
        },
        {
          label: "Phone Number 2",
          type: "text",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "Email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on contact details',
            }
          }
        }
      ]
    },
    addressDetails: {
      sectionType: "address",
      sectionTypeId: "addressDetails",
      multiForm: true,
      mutliFormTitle: "Address",
      expandable: true,
      addButton: true,
      breakPoint: 5,
      fieldName: "customerAddress",
      fieldItems: [
        {
          label: "Address Type",
          type: "select",
          name: "customerAddressType",
          required: false,
          initialFocus: false,
          url: "/customeraddresstypes"
        },
        {
          label: "Street",
          type: "text",
          name: "street",
          required: false,
          initialFocus: false
        },
        {
          label: "City",
          type: "text",
          name: "city",
          required: false,
          initialFocus: false
        },
        {
          label: "State",
          type: "text",
          name: "state",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Zip Code",
          type: "text",
          name: "zipCode",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "Country",
          type: "text",
          name: "country",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone Number 1",
          type: "text",
          size: "small",
          name: "phoneNumber1",
          required: false,
          initialFocus: false
        },
        {
          label: "Phone Number 2",
          type: "text",
          size: "small",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "Email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on payment address details',
            }
          }
        },
        {
          label: "Website",
          type: "text",
          name: "website",
          required: false,
          initialFocus: false
        },
      ]
    },
  },
  watchFields: []
}
