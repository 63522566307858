import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectShowAddLocationModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_LOCATION_FORM_TEMPLAT from './add-location.template';
import { setModalTemplate, setModalType, setShowAddLocationModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddLocation = ({
    showAddItem,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddLocationModal
}) => {
    useEffect(() => {
        setModalType("ADD_LOCATION")
        setModalTemplate(ADD_LOCATION_FORM_TEMPLAT);
    }, []);
    
    return (
        <div className="add_location">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddItem}
                        setShowModal={setShowAddLocationModal}
                        size={"LARGE"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddItem: selectShowAddLocationModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddLocationModal: () =>
        disptach(setShowAddLocationModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);