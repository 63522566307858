
import { useState, useEffect } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'

const useFetchAutoFill = (
    endpoint,
    authTokenFromState,
    setIsAutoFill,
    isAutoFill,
    selectedValue
) => {
    const [autoFillData, setAutoFillData] = useState([]);
    const [autoFillError, setAutoFillError] = useState(null);
    const [isAutoFillLoading, setIsAutoFillLoading] = useState(false);

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            setIsAutoFillLoading(true);
            const response = await Axios.get(endpoint, {
                headers: tokenHeader,
                params: {
                    selectedValue
                }
            })

            setAutoFillData(response.data);
            setIsAutoFillLoading(false);
            setIsAutoFill(false);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setAutoFillError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setAutoFillError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result you are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setAutoFillError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result you are looking for, contact your administrator."
                })
            }
            else {
                setAutoFillError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setIsAutoFillLoading(false);
            setAutoFillData([]);
            setIsAutoFill(false)
        }
    }

    useEffect(() => {
        if (isAutoFill) {            
            fetchData();
        }
    }, [isAutoFill]);

    return {
        autoFillData,
        autoFillError,
        isAutoFillLoading
    };
}

export default useFetchAutoFill;