import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const RM_ESTIMATION_INFO = {
  formTitle: "Production Estimation",
  formTitleForNew: "Production Estimation",
  path: "/rmestimations",
  listPage: "/production-estimation"
}

export const RM_ESTIMATION_FORM_TEMPLATE = {
  noAbalityToAddNew: true,
  noSaved: true,
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "RmEstimation",
      fieldItems: [
        {
          label: "Raw Material",
          type: "select",
          name: "Item",
          initialFocus: false,
          muliselect: false,
          url: "/itemsrm",
          validationProps: {
            required: "Raw Material is required"
          }
        },
      ]
    },
    dataDisplayTable: {
      sectionType: "Estimations",
      sectionTypeId: "estimationResults",
      fieldName: "estimationResults",
      expandable: true,
      isExpand: true,
      dataDisplayTable: true,
      showEditOnly: true,
      columns: [
        {
          Header: "Finished Goods",
          accessor: "finishedGoods", // Accessor is the key in the data
      },
      {
          Header: "Maximum Proudction",
          accessor: "maxProduction",
      },
      ],
      fieldItems: [{}]
    },
  },
  watchFields: [
    "RmEstimation.0.Item",
  ]
}
