const ITEM_MODAL_FORM_TEMPLATE = {
  modalTitle: "Add New Product",
  path: "/items",
  listPage: "/list-items",
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "basic",
      expandable: false,
      breakPoint: 0,
      position: "LAST",
      fieldName: "item",
      fieldItems: [
        {
          label: "Product Code",
          type: "text",
          name: "itemCode",
          initialFocus: false,
          validationProps: {
            required: "Product Code is required"
          }
        },
        {
          label: "Product Name",
          type: "text",
          name: "itemName",
          initialFocus: false,
          validationProps: {
            required: "Product name is required"
          }
        },
        {
          label: "Unit of Measure",
          type: "select",
          name: "UnitOfMeasure",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/unitofmeasures",
          validationProps: {
            required: "Unit of measure is required"
          }
        },
        {
          label: "Product Type",
          type: "select",
          name: "ItemType",
          required: false,
          initialFocus: false,
          muliselect: false,
          hasDefaultValue: true,
          defaultValue: { label: 'Stocked Product', value: 1 },
          url: "/itemtypes",
          validationProps: {
            required: "Product Type is required"
          }
        },
        {
          label: "Category",
          type: "select",
          name: "ItemGroup",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/itemgroups",
        },
      ]
    },
  }
}

export default ITEM_MODAL_FORM_TEMPLATE;