import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const RM_ESTIMATION_REPORT_BY_ORDER_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/rmestimation",
  listPage: "/inventory-reports",
  pagePath:"items",
  hasChart: false,
  hasDateRange: false,
  tableHasGrandTotal: true,
  hasSearch: false
}

export const RM_ESTIMATION_REPORT_BY_ORDER_COLUMN_TEMPLATE = [
]
