import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectModalTitle, selectShowAddItemGroupModal, selectShowAddSupplierGroupModal, selectShowAddSupplierModal, selectShowSupplierModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_ITEM_GROUP_FORM_TEMPLATE from './add-item-group.template.js';
import { setModalTemplate, setModalType, setShowAddItemGroupModal, setShowAddSupplierGroupModal, setShowAddSupplierModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddItemGroup = ({
    showAddItemGroup,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddItemGroupModal
}) => {
    useEffect(() => {
        setModalType("ADD_ITEM_GROUP")
        setModalTemplate(ADD_ITEM_GROUP_FORM_TEMPLATE);
    }, []);
    
    return (
        <div className="add_item_group">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddItemGroup}
                        setShowModal={setShowAddItemGroupModal}
                        size={"SMALL"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddItemGroup: selectShowAddItemGroupModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddItemGroupModal: () =>
        disptach(setShowAddItemGroupModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddItemGroup);