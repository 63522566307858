import { useEffect, useState } from "react";
import "./company-logo.styles.scss";
import { Axios } from "../../config";
import { getAuthToken } from "../../utils/auth_token.utils";
import { createStructuredSelector } from "reselect";
import { authTokenSelector } from "../../redux/user/user.reselect";
import { connect } from "react-redux";

const CompanyLogo = ({
    authTokenFromState
}) => {
  const [imageUrl, setImageUrl] = useState(null);

  const tokenHeader = getAuthToken(authTokenFromState);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        // Make the request using axios
        const response = await Axios.get(`/company/logo`, {
          headers: tokenHeader, 
          responseType: 'blob'
        });

        // If the request is successful
        const url = URL.createObjectURL(response.data); // Create a URL for the blob
        setImageUrl(url); // Set the URL to display the image
      } catch (error) {
        console.error("Error fetching company logo:", error);
      }
    };

    fetchLogo(); // Call the function to fetch the image
  }, []);

    return (
        <>
        {imageUrl &&
            <div className="company-logo">
           
                 <img className="company-logo__img" src={imageUrl} alt="Company Logo" />
           
                </div>
        }
      </>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector
});

export default connect(mapStateToProps)(CompanyLogo)