import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";


import { FiPrinter } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import IconPrint from "../../assets/icons/print.svg";
import { selectExportData } from "../../redux/export/export.reselect";
import { printData } from "../../utils/export-print-data-to-pdf.util";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';
import IconArrowDown from "../../assets/icons/down-arrow.svg";


import jsPDF from "jspdf";
import { useRef } from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider } from 'react-redux';
import { store} from '../../redux/store';
import { withRouter } from "react-router-dom";
import { selectFormInfo } from "../../redux/form/form.reselect";
import { selectDocumentDropDownItems } from "../../redux/document/document.reselect";

const DocumentViewButton = ({
    DropDownButton,
    dropDownItems,
    text,
    dataPrint,
    contentRef,
    history,
    formInfo,
    noText,
    match
}) => {
    const cloneRef = useRef(true);
    const testRef = useRef(null)
    
    const handleClick = (event) => {
        const subPath = event.target.id
        if (event.target.id === (formInfo.listPage.split('/').join(''))) {
            return window.open(`${formInfo.listPage}/${match.params.uuid}/documents`, "_blank")
        } 

        window.open(`${formInfo.listPage}/${match.params.uuid}/${subPath}/documents`, "_blank")
    }

    return (
        <>
            <DropDownContainer
                DropDownButton={DropDownButton}
                dropDownItems={dropDownItems}
                iconLeft={FiPrinter}
                icon={IoIosArrowDown}
                size="medium"
                dropDwon={true}
                handleClick={handleClick}
            > 
                {!noText? "Print" : ""}
            </DropDownContainer>
        </>
    )
}


const mapStateToProps = createStructuredSelector({
    dataPrint: selectExportData,
    formInfo: selectFormInfo,
    dropDownItems: selectDocumentDropDownItems
});

export default withRouter(connect(mapStateToProps)(DocumentViewButton))