import { useEffect, useState } from "react";
import "./stock-details.component.scss";
import { formatNumberToFiveDecimal, formatNumberToTwoDecimal } from "../../utils/format-number.util";
import { selectFeatureFlags } from '../../redux/user/user.reselect';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

const StockDetails = ({
    editSectionData,
    featureFlags
}) => {
    const [qtyOnHand, setQtyOnHand] = useState(0);
    const [isFeatureDisalbed, setIsFeatureDisalbed] = useState(true);

    useEffect(() => {
        setQtyOnHand(editSectionData.reduce((preVale, stock) => (
            stock.qtyOnHand + preVale
        ), 0))
    }, [])

    useEffect(() => {
        if (featureFlags.length) {
            const featureFlag = featureFlags.find((featureFlag => {
                return featureFlag.feature.toLowerCase() === "work_order"
            }))

            if (featureFlag && (featureFlag.flag.toLowerCase() === "disable")) {
                setIsFeatureDisalbed(true);
            } else {
                setIsFeatureDisalbed(false);
            }
        }
    }, [featureFlags])


    return (
        <div className="stock-detials">
            <div className={`stock-detials__total ${!qtyOnHand && "stock-detials__total--noBottomBorderPadding"}`}>
                <div className="stock-detials__total__single">
                    {editSectionData.length ?
                        <div className="stock-detials__total__single__title">
                            Total Quantity on Hand
                        </div> : null
                    }
                    {editSectionData.length ?
                        <div className="stock-detials__total__single__content">
                            {formatNumberToFiveDecimal(qtyOnHand)}
                        </div> :
                        <p className="stock-detials__total__single__content__non-stock">
                            Non stocked Products do not track stock.
                        </p>
                    }
                </div>
            </div>
            {
                editSectionData.map(stock => (
                    <div className="stock-detials__branch">
                        <div className="stock-detials__branch__title">
                            {stock.location}
                            {stock.locationName &&
                                <span className="stock-detials__branch__title__subtitle">
                                    | {stock.locationName}
                                </span>
                            }
                        </div>
                        <div className="stock-detials__branch__breakdown">
                            <div className="stock-detials__branch__breakdown__single">
                                <div className="stock-detials__branch__breakdown__single__title">
                                    Quanitiy On Hand
                                </div>
                                <div className="stock-detials__branch__breakdown__single__content">
                                    {formatNumberToFiveDecimal(stock.qtyOnHand)}
                                </div>
                            </div>
                            {!isFeatureDisalbed &&
                                <div className="stock-detials__branch__breakdown__single">
                                    <div className="stock-detials__branch__breakdown__single__title">
                                        Recovered
                                    </div>
                                    <div className="stock-detials__branch__breakdown__single__content">
                                        {formatNumberToFiveDecimal(stock.recovered)}
                                    </div>
                                </div>
                            }
                            <div className="stock-detials__branch__breakdown__single">
                                <div className="stock-detials__branch__breakdown__single__title">
                                    Reserved
                                </div>
                                <div className="stock-detials__branch__breakdown__single__content">
                                    {formatNumberToFiveDecimal(stock.reserve)}
                                </div>
                            </div>
                            <div className="stock-detials__branch__breakdown__single">
                                <div className="stock-detials__branch__breakdown__single__title">
                                    Available
                                </div>
                                <div className="stock-detials__branch__breakdown__single__content">
                                    {formatNumberToFiveDecimal(stock.available)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    featureFlags: selectFeatureFlags,
});

export default connect(mapStateToProps, null)(StockDetails);
