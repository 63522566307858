import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import TableCountCell from '../../../components/table-count-cell/table-count-cell.componet';
import { useMediaQuery } from 'react-responsive';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';

export const PRODUCTION_PLAN_INFO = {
  formTitle: "Production Plan",
  formTitleForNew: "New Production Plan",
  path: "/productionplans",
  listPage: "/production-plans"
}

export const PRODUCTION_PLAN_FORM_TEMPLATE = {
  quickComplete: false,
  quickPay: false,
  approval: false,
  convertToOrder: false,
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "productionPlan",
      fieldItems: [
        {
          label: "Production Plan Code",
          type: "text",
          name: "productionPlanCode",
          initialFocus: false,
        },
        {
          label: "Start Date",
          type: "date",
          name: "startDate",
          initialFocus: false,
          validationProps: {
            required: "Start date is required"
          }
        },
        {
          label: "End Date",
          type: "date",
          name: "endDate",
          initialFocus: false,
          validationProps: {
            required: "End date is required"
          }
        },
        {
          label: "Remark",
          type: "text",
          name: "description",
          initialFocus: false,
          required: false,
        }
      ]
    },
    tableForm: {
      sectionType: "Targets",
      sectionTypeId: "tableForm",
      expandable: true,
      isExpand: true,
      showTotal: false,
      isCurrency: true,
      fieldName: "items",
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              noAvailability={true}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
              createNew={{text:"Add New Product", modalName: "item"}}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Target"}
                required={true}
              />
            )
          },
          accessor: "target",
          width: 85,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Target is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"target"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
              headerName={"Target"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    }
  },
  watchFields: [],
}
