export const getAuthToken = (authTokenFromState) => {
    const loggedInUser =JSON.parse(localStorage.getItem("user"));
    let storedAuthToken = null;

    if(loggedInUser){
         storedAuthToken = loggedInUser.authToken;
    }

    const token = authTokenFromState ? authTokenFromState : storedAuthToken;
    
    return {
        Authorization: "Bearer " + token
    }
} 