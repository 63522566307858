import React from "react";

import "./document-header.styles.scss";

import TitleHeader from "../title-header/title-header.componenet";
import DetailEdit from "../detail-edit/detail-edit.componenet";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import DetailRefresh from "../detail-refresh/detail-refresh.component";
import DetailPrint from "../detail-print/detail-print.componenet";
import DocumentRefresh from "../document-refresh/document-refresh.component";
import DocumentPrint from "../document-print/document-print.componenet";
import DocumentSelect from "../docuemnt-select/document-select.componenet";


const DocumentHeader = ({
    title,
    hasMutliDocumnet,
    endpointForSelect,
    autTokenForSelect,
    contentRef,
    isDefaultValueFromApi
}) => {
    return (
        <div className="detail-header-print container">
            <TitleHeader>{title}</TitleHeader>
            <div className="detail-header-print__tools">
                <div className="detail-header-print__tools__actions">
                    {
                        hasMutliDocumnet &&
                        <DocumentSelect
                            endpoint={endpointForSelect}
                            authToken={autTokenForSelect}
                            isDefaultValueFromApi={isDefaultValueFromApi}
                        />

                    }
                    <DocumentRefresh
                        DropDownButton={DropDownButton}
                    />
                    <DocumentPrint
                        DropDownButton={DropDownButton}
                        contentRef={contentRef}
                    />
                </div>
            </div>
        </div>
    )
}

export default DocumentHeader;