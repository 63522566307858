import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const OPERATING_EXPENSE_INFO = {
  formTitle: "Operating Expense",
  formTitleForNew: "New Operating Expense",
  path: "/operatingexpenses",
  listPage: "/operating-expenses",
}

export const OPERATING_EXPENSE_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "operatingExpense",
      fieldItems: [
        {
          label: "Expense Number",
          type: "text",
          name: "expenseNumber",
          initialFocus: false,
          disabled: true,
          defaultValue: `OE-`
        },
        {
          label: "Expense Type",
          type: "select",
          name: "ExpenseType",
          initialFocus: false,
          muliselect: false,
          url: "/expensetypes",
        },
        {
          label: "Expense Date",
          type: "date",
          name: "expenseDate",
          initialFocus: false,
          validationProps: {
            required: "Expense date is required"
          }
        },
        {
          label: "Remark",
          type: "text",
          name: "remark",
          initialFocus: false,
          required: false,
        },
      ]
    },
    tableForm: {
      sectionType: "Expense Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      showTotal: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Paymen Method"}
                required={true}
              />
            )
          },
          accessor: "paymentMethod",
          width: 110,
          Cell: ({ row }) => {
            const endpointUrl = "/paymentmethods";
            const validationProps = {
              required: "Payment method is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"paymentMethod"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={false}
              defaultValue={{ value: 1, label: "Cash" }}
              headerName={"Method"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Payment Description"}
                required={false}
              />
            )
          },
          accessor: "paymentDescription",
          width: 190,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"paymentDescription"}
              rowNumber={row.id}
              validationProps={false}
              align="left"
              headerName={"paymentDescription"}
              disabled={row.isDisable}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Amount (ETB)"}
                required={true}
              />
            )
          },
          accessor: "amount",
          width: 100,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Amount is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"amount"}
              rowNumber={row.id}
              valueType={"number"}
              align="right"
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Amount (ETB)"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History details",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true,
      addButton: true,
      position: "LAST",
      breakPoint: 1,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: []
}
