import './form-full-error.styles.scss';
import IconError from '../../assets/icons/error.svg';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectFormValidationErrors, selectErrorMessage,  } from '../../redux/form/form.reselect';

const FormFullError = ({ 
    formValidationError,
    formErrors,
    formResponseError,
    errorMessage
 }) => (
    <div className="form-full-error">
        <div className="form-full-error__header">
            <img src={IconError} className="form-full-error__header__icon" alt="error" />
            { 
                formValidationError ? 
                    "Review the following fields" :
                    formResponseError && "An error has occurred"
            }
        </div>
        <div className="form-full-error__body">
            {
                console.log("global error")
            }
            {
                formValidationError ?
                    <ul className="form-full-error__body__container">
                        {
                            Object.keys(formErrors).map(fieldError => (
                                formErrors[fieldError].map((fieldGroupError,index) => (
                                    Object.keys(fieldGroupError).map((fieldItemError) => (
                                        <li 
                                        key= {fieldItemError} 
                                        className="form-full-error__body__item">
                                            {formErrors[fieldError][index][fieldItemError].message}
                                    </li>
                                    ))
                                ))     
                            )) 
                        }
                    </ul> :
                    formResponseError ? 
                        errorMessage :
                        null
            }
        </div>
    </div>
)

const mapStateToProps = createStructuredSelector({
    formErrors: selectFormValidationErrors,
    errorMessage: selectErrorMessage
})

export default connect(mapStateToProps)(FormFullError);
