import React from 'react';

const withModalFormTemplate = WrappedComponent => {
    const modal = ({ formTemplate, showModal, setShowModal, size, isLoading = false, endpointUrl }) => {
        return formTemplate ?
            <WrappedComponent
                showModal={showModal}
                setShowModal={setShowModal}
                isLoading={isLoading}
                endpointUrl={endpointUrl}
                size={size}
            /> :
            null
    }

    return modal;
};

export default withModalFormTemplate;