import {createSelector} from 'reselect'

const alertSelector = (state) => state.alert; 

export const selectAlertIsOn = createSelector(
    [alertSelector],
    alert => alert.isOn
);

export const selectAlertType = createSelector(
    [alertSelector],
    alert => alert.alertType
);

export const selectAlertUUID = createSelector(
    [alertSelector],
    alert => alert.alertUUID
);

export const selectAlertEndpoint = createSelector(
    [alertSelector],
    alert => alert.alertEndpoint
);