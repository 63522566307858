import ErrorBox from "../../components/error-box/error-box.component";
import "./access-denied.styles.scss";

const ERROR = {
    name: "Access Denied",
    message: "Sorry, you don't have access to this resource, ask your administrator to adjust your access rights."
}

const AccessDenied = () => {
    return (
        <div className="access-denied">
            <div className="access-denied__box">
                <ErrorBox
                    error={ERROR}
                    icon={true}
                />
            </div>
        </div>
    )
}

export default AccessDenied;