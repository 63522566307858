export const LOCAL_FILTER_FIELDS = [
    {
        label: "SQ Number",
        type: "text",
        name: "salesQuoteNumber",
    },
    {
        label: "Customer",
        type: "text",
        name: "customerName",
    }
]