import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const SALES_REPORT_BY_SALES_TARGET_INFO = {
  reportTitle: "Sales Targets",
  path: "/salesreports",
  subPath: "/salestargets",
  listPage: "/sales-reports",
  pagePath:"sales-targets",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasLocationFilter: false,
  hasTerritoryFilter: true,
  hasSearch: true
}

export const SALES_REPORT_BY_SALES_TARGET_COLUMN_TEMPLATE = [
  {
    Header: "Target Code",
    accessor: "targetCode",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    }
  },
  {
    Header: "Territory",
    accessor: "territory",
    Cell: ({ value }) => {
      return displayCellValue(value);
   },
  },
  {
    Header: "Sales Rep",
    accessor: "salesRep",
    Cell: ({ value }) => {
      return displayCellValue(value);
   },
  },
 {
    Header: "Target",
    accessor: "target",
    Cell: ({ value }) => {

      return displayCellValue(value);
    },
    Footer: "target"
  },
  {
    Header: "Actual",
    accessor: "actual",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
          return displayCellValue(value);
      }

      value.label = value && value.label && isNaN(value.label) ?
          value.label :
          !isMobileView ? formatNumberToTwoDecimal(value.label) : `${formatNumberToTwoDecimal (value.label)} Quantity`

      return (
          <div className={isMobileView && isRightAligned ?
              'list-table__body__row__cell--right-aligned-mobile-cell' : ''}>
              <TableCellStatus
                  value={value}
                  noDot={true}
              />
          </div>
      );
    },
    Footer: "actual"
  },
  {
    Header: "Difference",
    accessor: "difference",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Difference: ${displayCellValue(value)}`;
      }

      return displayCellValue(value);
    },
    Footer: "difference"
  },
  {
    Header: "Weekly Target",
    accessor: "weeklyTarget",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    },
    Footer: "weeklyTarget"
  },
  {
    Header: "Weekly Remaining Target",
    accessor: "weeklyRemainingTarget",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    },
    Footer: "weeklyRemainingTarget"
  },
  {
    Header: "Daily Target",
    accessor: "dailyTarget",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    },
    Footer: "dailyTarget"
  },
  {
    Header: "Daily Remaining Target",
    accessor: "dailyRemainingTarget",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return null;
      }

      return displayCellValue(value);
    },
    Footer: "dailyRemainingTarget"
  },
 

]
