const ADD_CUSTOMER_GROUP_FORM_TEMPLATE ={
    modalTitle: "New Customer Group",
    path: "/customer-groups",
    listPage: "/list-customer-groups",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "basic",
        expandable: false,
        position: "LAST",
        breakPoint: 0,
        fieldName: "customerGroup",
        fieldItems: [
          {
            label: "Customer Group Name",
            type: "text",
            name: "customerGroup",
            initialFocus: true,
            validationProps: {
              required: "Customer group is required"
            }
          },
          {
            label: "description",
            type: "textarea",
            name: "description",
            required: false,
            initialFocus: false
          }
        ]
      }
    }
}

export default ADD_CUSTOMER_GROUP_FORM_TEMPLATE;