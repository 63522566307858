import {createSelector} from 'reselect'

const detailSelector = (state) => state.detail; 

export const selectUUID = createSelector(
    [detailSelector],
    detail => detail.uuid
);

export const selectDetailData = createSelector(
    [detailSelector],
    detail => detail.detailData
);

export const selectDetailPagePath = createSelector(
    [detailSelector],
    detail => detail.detailPagePath
);

export const selectDetailPrintTitle = createSelector(
    [detailSelector],
    detail => detail.detailPrintTitle
);

export const selectDetailPrintEndpoint = createSelector(
    [detailSelector],
    detail => detail.detailPrintEndpoint
);