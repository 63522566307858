import { deleteActionTypes } from "./delete.types"

const INITIAL_STATE = {
    isLoading: false,
    isDeleted: false,
    isFail: false,
    errorMessage: null,
    deletedItems: null
}
const deleteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case deleteActionTypes.DELETE_ITEMS_START:
            return {
                ...state,
                isLoading: true
            }
        case deleteActionTypes.DELETE_ITEMS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDeleted: true
            }
        case deleteActionTypes.DELETE_ITEMS_FAILURE:
            return {
                ...state,
                isFail: true,
                isLoading: false,
                errorMessage: action.payload
            }
        case deleteActionTypes.SET_DELETED_ITEMS:
            return {
                ...state,
                deletedItems: action.payload
            }
        case deleteActionTypes.REMOVE_DELETED_ITEMS:
            return {
                ...state,
                deletedItems: null
            }            
        default:
            return state;
    }
}

export default deleteReducer;