import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SALES_REPORT_BY_QUOTE_ITEM_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/salesbyquoteitem",
  listPage: "/sales-reports",
  pagePath:"items",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
  hasLocationFilter: false,
  hasSearch: true
}

export const SALES_REPORT_BY_QUOTE_ITEM_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Qty Projected",
    accessor: "qtyProjected",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      const newValue = { value: 3, label: isMobileView ? `${value} Sold` :  `${value}`}

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={newValue}
            noDot={true}
          />
        </div>
      )
    }
  },
  {
    Header: "Available",
    accessor: "available",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Available: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }
  },
  {
    Header: "Difference ",
    accessor: "difference",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Reserve: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }
  },
  {
    Header: "Total Projected Sales (ETB)",
    accessor: "totalProjectedSales",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Sales: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalSales'
  },
 
]
