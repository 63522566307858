export const checkForNonDisplayField = (key, forPrint) => {
    if (key === "sectionId" ||
        key === "sectionTitle" ||
        key === "subSection" ||
        key === "hasContent" ||
        key === "isInTable" ||
        key === "uuid" || 
        forPrint && key === "createdAt"  ||
        forPrint && key ===  "updatedAt"
    ) {
        return null;
    }

    return true;
}