import {utils, writeFile} from 'xlsx';
import lodash from 'lodash';

const exportDataToExcel = (dataExport) => {
    const data = dataExport.data;

    const timestamp = new Date().getTime();
    const header = [];
    header.push(Object.keys(data[0]).map(label => (
        lodash.startCase(label)
    )))

    const footer = dataExport.total &&  [header[0].map((label, index) => {
        const key = label.replace(/\s+/g, '').replace(/^\w/, c => c.toLowerCase());
        return  index === 0 ?  "Grand Total" : dataExport.total[key] !== undefined ?  dataExport.total[key] : "";
    })];
    
    const workBook = utils.book_new();
    const workSheet = utils.json_to_sheet(data, { origin: 'A2', skipHeader: true });
    utils.sheet_add_aoa(workSheet, header)
    dataExport.total && utils.sheet_add_aoa(workSheet, footer, { origin: `A${data.length + 3}` });
    utils.book_append_sheet(workBook, workSheet, "Sheet1");

    writeFile(workBook, timestamp+".xlsx");
}

export default exportDataToExcel;