import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const UNIT_OF_MEASURE_INFO = {
  formTitle: "Unit of Measure",
  formTitleForNew: "New Unit of Measure",
  path: "/unitofmeasures",
  listPage: "/unit-of-measures"
}

export const UNIT_OF_MEASURE_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "unitOfMeasure",
      fieldItems: [
        {
          label: "Unit of Measure Code",
          type: "text",
          name: "unitOfMeasureCode",
          initialFocus: false,
          validationProps: {
            required: "Unit of measure code is required"
          }
        },
        {
          label: "Unit of Measure Name",
          type: "text",
          name: "unitOfMeasureName",
          initialFocus: false
        }
      ]
    }
  },
  watchFields: []
}
